import { keepPreviousData, queryOptions, useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { keys as positionKeys } from 'entities/position';
import { keys as userKeys } from 'entities/user';
import { keys as userOverviewKeys } from 'entities/userOverview';

import { CommonError } from 'shared/api/types';
import { handleRequestError } from 'shared/lib/axios';
import { queryClient } from 'shared/lib/react-query';
import { pathKeys } from 'shared/lib/react-router';

import { addNotification } from 'shared/model/notification';

import {
  assignmentItemArchive,
  assignmentItemCreate,
  assignmentItemGet,
  assignmentItemUpdate,
  assignmentListGetByPosition,
  assignmentUserInvolvementReportGet,
} from './requests';
import {
  AssignmentCreateRequest,
  AssignmentDTO,
  AssignmentList,
  AssignmentListRequest,
  AssignmentUpdateRequest,
  AssignmentUserInvolvementReportRequest,
  UserInvolvementReportDTO,
} from './types';

const keys = {
  root: () => ['assignment'] as const,
  list: () => [...keys.root(), 'list'] as const,
  listWithFilters: (filters: AssignmentListRequest) => [...keys.root(), 'list', filters] as const,
  item: (id: string) => [...keys.root(), 'item', id] as const,
  create: () => [...keys.root(), 'create'] as const,
  update: (id: string) => [...keys.root(), 'update', id] as const,
  archive: (id: string) => [...keys.root(), 'archive', id] as const,
  restore: (id: string) => [...keys.root(), 'restore', id] as const,
  report: () => [...keys.root(), 'report'] as const,
  reportByUserId: (id: string) => [...keys.report(), 'reportByUserId', id] as const,
  reportWithDates: ({ userId, ...dates }: AssignmentUserInvolvementReportRequest) =>
    [...keys.reportByUserId(userId), 'reportWithDates', dates] as const,
};

export const assignmentsService = {
  queryKey: (filters: AssignmentListRequest) => keys.listWithFilters(filters),

  getCache: (filters: AssignmentListRequest) =>
    queryClient.getQueryData<AssignmentList>(assignmentsService.queryKey(filters)),

  queryOptions: (filters: AssignmentListRequest, enabled = true) => {
    const assignmentsKey = assignmentsService.queryKey(filters);
    return queryOptions({
      queryKey: assignmentsKey,
      queryFn: async ({ signal }) => {
        const data = await assignmentListGetByPosition(filters, { signal });
        return data;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => assignmentsService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(assignmentsKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
      enabled,
    });
  },
};

export const assignmentService = {
  queryKey: (id: string) => keys.item(id),

  getCache: (id: string) => queryClient.getQueryData<AssignmentDTO>(assignmentService.queryKey(id)),

  setCache: (assignment: AssignmentDTO) => {
    queryClient.setQueryData(assignmentService.queryKey(assignment.id), assignment);
  },

  removeCache: (id: string) => queryClient.removeQueries({ queryKey: assignmentService.queryKey(id) }),

  queryOptions: (id: string, enabled = true) => {
    const assignmentKey = assignmentService.queryKey(id);
    return queryOptions({
      queryKey: assignmentKey,
      queryFn: async ({ signal }) => {
        const assignment = await assignmentItemGet(id, { signal });
        return assignment;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => assignmentService.getCache(id)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(assignmentKey)?.dataUpdatedAt,
      enabled,
    });
  },

  prefetchQuery: async (id: string) => queryClient.prefetchQuery(assignmentService.queryOptions(id)),
};

export const assignmentUserInvolvementReportService = {
  queryKey: (dates: AssignmentUserInvolvementReportRequest) => keys.reportWithDates(dates),

  getCache: (dates: AssignmentUserInvolvementReportRequest) =>
    queryClient.getQueryData<UserInvolvementReportDTO>(assignmentUserInvolvementReportService.queryKey(dates)),

  queryOptions: (data: AssignmentUserInvolvementReportRequest, enabled = true) => {
    const reportKey = assignmentUserInvolvementReportService.queryKey(data);
    return queryOptions({
      queryKey: reportKey,
      queryFn: async ({ signal }) => {
        const report = await assignmentUserInvolvementReportGet(data, { signal });
        return report;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => assignmentUserInvolvementReportService.getCache(data)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(reportKey)?.dataUpdatedAt,
      enabled,
    });
  },
};

export const useCreateAssignmentMutation = (
  accountId: string,
  projectId: string,
): UseMutationResult<AssignmentDTO, CommonError, AssignmentCreateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.create(),
    mutationFn: assignmentItemCreate,
    onSuccess: async (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: keys.reportByUserId(data.userId) });
      qClient.invalidateQueries({ queryKey: userOverviewKeys.list() });
      qClient.invalidateQueries({ queryKey: positionKeys.list() });

      assignmentService.setCache(data);
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Assignment:PositionPage.notification.sucessfullyCreated.description',
      });
      navigate(pathKeys.position.root({ accountId, projectId }));
    },
    onError: (error) => {
      let errorMessage = '';
      let type: 'short' | 'long' = 'long';

      switch (error.response?.data.code) {
        case 'PositionAlreadyAssignedException':
          errorMessage = 'Assignment:AssignmentCreateUpdateFormItems.item.dates.notification.dateIntersection.text';
          break;
        case 'NonZeroRateValueException':
          errorMessage = 'Assignment:AssignmentCreateUpdateFormItems.notification.nonZeroRate.text';
          break;
        case 'ItemNotFoundException':
          type = 'long';
          errorMessage = 'Assignment:AssignmentCreatePage.notification.notFound.description';
          break;
        default:
          handleRequestError(error);
          return;
      }
      addNotification({
        type,
        method: 'error',
        description: errorMessage,
      });
    },
  });
};

export const useUpdateAssignmentMutation = (
  accountId: string,
  projectId: string,
  assignmentId: string,
): UseMutationResult<AssignmentDTO, CommonError, AssignmentUpdateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.update(assignmentId),
    mutationFn: assignmentItemUpdate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: keys.reportByUserId(data.userId) });
      qClient.invalidateQueries({ queryKey: userOverviewKeys.list() });
      qClient.invalidateQueries({ queryKey: positionKeys.list() });
      qClient.invalidateQueries({ queryKey: userKeys.availableUsers() });

      addNotification({
        type: 'short',
        method: 'success',
        description: 'Assignment:PositionPage.notification.sucessfullyUpdated.description',
      });
      assignmentService.setCache(data);
      navigate(pathKeys.position.root({ accountId, projectId }));
    },
    onError: (error) => {
      let errorMessage = '';

      switch (error.response?.data.code) {
        case 'PositionAlreadyAssignedException':
          errorMessage = 'Assignment:AssignmentCreateUpdateFormItems.item.dates.notification.dateIntersection.text';
          break;
        case 'NonZeroRateValueException':
          errorMessage = 'Assignment:AssignmentCreateUpdateFormItems.notification.nonZeroRate.text';
          break;
        default:
          handleRequestError(error);
          return;
      }

      addNotification({
        type: 'long',
        method: 'error',
        description: errorMessage,
      });
    },
  });
};

export const useArchiveAssignmentMutation = (
  accountId: string,
  projectId: string,
  assignmentId: string,
  userId: string,
): UseMutationResult<void, CommonError, string> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.archive(assignmentId),
    mutationFn: assignmentItemArchive,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: keys.reportByUserId(userId) });
      qClient.invalidateQueries({ queryKey: userOverviewKeys.list() });
      qClient.invalidateQueries({ queryKey: positionKeys.list() });
      qClient.invalidateQueries({ queryKey: userKeys.availableUsers() });

      assignmentService.removeCache(assignmentId);
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Assignment:PositionPage.notification.sucessfullyArchived.description',
      });
      navigate(pathKeys.position.root({ accountId, projectId }));
    },
    onError: (error) => {
      handleRequestError(error);
    },
  });
};
