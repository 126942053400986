import { ReactElement } from 'react';

import { DemandWidget, demandWidgetPermissions } from 'widgets/DemandWidget';
import { UserOverviewWidget, usersWidgetPermissions } from 'widgets/UserOverviewWidget';

import { WidgetManager } from 'features/WidgetManager';

import styles from './DashboardPage.module.scss';

function DashboardPage(): ReactElement {
  const items = [
    {
      key: 'user-overview',
      content: <UserOverviewWidget />,
      requiredPermissions: usersWidgetPermissions,
    },
    {
      key: 'demand',
      content: <DemandWidget />,
      requiredPermissions: demandWidgetPermissions,
    },
  ];
  return (
    <div className={styles.rootWrapper}>
      <WidgetManager items={items} />
    </div>
  );
}

export default DashboardPage;
