import React, { useState } from 'react';
import { Drawer } from 'antd';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { isHasAccess } from 'features/validateAccess';
import { ReactComponent as MenuBurger } from 'shared/assets/icons/menu-burger.svg';
import { navItems } from '../../config/navItems';

import styles from './NavbarMenu.module.scss';

const NavbarMenu: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [hasVisible, setHasVisible] = useState<boolean>(false);

  const showDrawer = (): void => {
    setHasVisible(true);
  };

  const onClose = (): void => {
    setHasVisible(false);
  };

  return (
    <React.Fragment>
      <MenuBurger onClick={showDrawer} className={styles.menu} />
      <Drawer
        styles={{
          body: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '20px',
            background: '#203a60',
          },
        }}
        title="MENU"
        placement="right"
        onClose={onClose}
        open={hasVisible}
      >
        {navItems.map((item) => {
          return isHasAccess(item.requiredPermissions) ? (
            <Link
              to={item.path}
              onClick={onClose}
              key={item.id}
              className={cn(styles.menuItem, {
                [styles.navItemActive]: pathname === item.path,
              })}
            >
              {t(item.captionID)}
            </Link>
          ) : null;
        })}
      </Drawer>
    </React.Fragment>
  );
};

export default NavbarMenu;
