import { StateCreator, createStore } from 'zustand';
import { DevtoolsOptions, devtools } from 'zustand/middleware';

import { UserOverviewFiltration } from '../api/types';
import { DEFAULT_USER_OVERVIEW_SORTING } from '../const';

type Actions = {
  setFilters: (filters: Omit<UserOverviewFiltration, 'orders'>) => void;
  setOrder: (order: UserOverviewFiltration['orders']) => void;
  setSearchQuery: (searchQuery: string) => void;
  resetFilters: () => void;
  resetAll: () => void;
};

type State = {
  filters: UserOverviewFiltration;
  searchQuery: string;
};

type UserOverviewState = Actions & State;

const FilterInitialState: UserOverviewFiltration = {
  showOnlyNonBillable: false,
  availableUsers: false,
  filters: [],
  orders: DEFAULT_USER_OVERVIEW_SORTING,
};

const createSlice: StateCreator<UserOverviewState, [['zustand/devtools', never]], [], UserOverviewState> = (
  set,
  get,
) => ({
  filters: FilterInitialState,
  searchQuery: '',
  setFilters: (filters) => set({ filters: { ...filters, orders: get().filters.orders } }, false, 'setFilters'),
  setOrder: (order) => set({ filters: { ...get().filters, orders: order } }, false, 'setOrder'),
  setSearchQuery: (searchQuery) => set({ searchQuery }, false, 'setSearchQuery'),
  resetFilters: () => set({ filters: { ...FilterInitialState, orders: get().filters.orders } }, false, 'resetFilters'),
  resetAll: () => set({ filters: FilterInitialState, searchQuery: '' }, false, 'resetAll'),
});

const devtoolsOptions: DevtoolsOptions = { name: 'userOverviewStore' };

export const userOverviewStore = createStore<UserOverviewState>()(devtools(createSlice, devtoolsOptions));
