import { ReactElement, useEffect, useRef, useState } from 'react';
import { Button, Form, Input } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { LogInWithGoogle } from 'features/LogInWithGoogle';
import { useLoginUserMutation } from 'entities/session';

import { ReactComponent as Logo } from 'shared/assets/icons/login-logo.svg';
import { useFormErrorsTranslate } from 'shared/lib/i18n';
import customAntFormStyles from 'shared/styles/ant-custom-components/form.module.scss';
import { CustomAntInputPassword } from 'shared/ui/CustomAntInputPassword';

import styles from './LogInForm.module.scss';

const DEFAULT_LOGIN_MIN_LENGTH = 3; // TODO: get from API
const DEFAULT_LOGIN_MAX_LENGTH = 100; // TODO: get from API
const DEFAULT_PASSWORD_MIN_LENGTH = 1; // TODO: get from API
const DEFAULT_PASSWORD_MAX_LENGTH = 100; // TODO: get from API
const CONTAIN_ALPHANUMERIC_MIXED_SPEC_RE = /^[\x2B\x2D\x2E\x5F\x30-\x39\x41-\x5A\x61-\x7A]+$/;

interface ILoginForm {
  login: string;
  password: string;
}

function LogInForm(): ReactElement {
  const [form] = Form.useForm<ILoginForm>();
  const timerInterval: { current: NodeJS.Timeout | null } = useRef(null);
  const { t } = useTranslation();

  const [timer, setTimer] = useState<number>(0);
  const [isSubmitIsDisabled, setIsSubmitIsDisabled] = useState(false);

  const {
    isPending: logInIsLoading,
    isError: isIncorrectCredentials,
    mutate: logInMutation,
    error,
    reset,
  } = useLoginUserMutation();

  const unBlockedTime = error?.response?.data.payload?.unblockAt ?? '';

  const normalizedTime = (time: number): string => {
    return dayjs(time).format('mm:ss');
  };

  const handleClearInterval = (): void => {
    clearInterval(timerInterval.current as NodeJS.Timeout);
  };

  const blockTime = (): void => {
    const serverTime = dayjs.utc();
    const blockDate = dayjs(unBlockedTime);
    const difference = blockDate.diff(serverTime) ?? 0;
    setTimer(difference);
    timerInterval.current = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime - 1000 < 1000) {
          handleClearInterval();
          setIsSubmitIsDisabled(false);
          reset();
          return 0;
        }
        return prevTime ? prevTime - 1000 : difference - 1000;
      });
    }, 1000);
  };

  const onFormChange = (changedField: any): void => {
    reset();

    if (changedField[0].name[0] === 'login') {
      setIsSubmitIsDisabled(false);
      setTimer(0);
    }
  };

  document.addEventListener('visibilitychange', function () {
    if (document.hidden) {
      handleClearInterval();
    } else {
      handleClearInterval();
      blockTime();
    }
  });

  useFormErrorsTranslate(form);

  useEffect(() => {
    if (unBlockedTime) {
      blockTime();
      setIsSubmitIsDisabled(true);
    }
    return () => {
      handleClearInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unBlockedTime]);

  const renderForm = (): ReactElement => {
    return (
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        initialValues={{ remember: true }}
        onFinish={logInMutation}
        autoComplete="off"
        layout="vertical"
        requiredMark={false}
        size="large"
        onFieldsChange={onFormChange}
        form={form}
        className={customAntFormStyles.customAntForm}
        data-testid="login-form"
      >
        <Form.Item
          label={t('Auth:LogInForm.form.login.item.label')}
          name="login"
          rules={[
            { required: true, message: t('Auth:LogInForm.form.login.item.validationMessage.required') },
            {
              pattern: CONTAIN_ALPHANUMERIC_MIXED_SPEC_RE,
              message: t('Auth:LogInForm.form.login.item.validationMessage.incorrectSymbols'),
            },
            {
              max: DEFAULT_LOGIN_MAX_LENGTH,
              message: t('Auth:LogInForm.form.login.item.validationMessage.loginMaxLength'),
            },
            {
              min: DEFAULT_LOGIN_MIN_LENGTH,
              message: t('Auth:LogInForm.form.login.item.validationMessage.loginMinLength'),
            },
          ]}
          shouldUpdate={(prevValues, currentValues) => prevValues !== currentValues}
          data-testid="form-login-item"
        >
          <Input
            className={customAntFormStyles.customAntInput}
            placeholder={t('Auth:LogInForm.form.login.input.placeholder')}
            disabled={logInIsLoading}
            data-testid="login-input"
          />
        </Form.Item>

        <Form.Item
          label={t('Auth:LogInForm.form.password.item.label')}
          name="password"
          rules={[
            { required: true, message: t('Auth:LogInForm.form.password.item.validationMessage.required') },
            {
              min: DEFAULT_PASSWORD_MIN_LENGTH,
              message: t('Auth:LogInForm.form.login.item.validationMessage.passMinLength'),
            },
            {
              max: DEFAULT_PASSWORD_MAX_LENGTH,
              message: t('Auth:LogInForm.form.login.item.validationMessage.passMaxLength'),
            },
          ]}
          className={`${customAntFormStyles.customAntFormItem} ${customAntFormStyles.withPaddingOnError}`}
          data-testid="form-password-item"
        >
          <CustomAntInputPassword
            placeholder={t('Auth:LogInForm.form.password.input.placeholder')}
            disabled={logInIsLoading}
            data-testid="password-input"
          />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }}>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className={classNames(styles.submitButton, styles.customAntSubmitButton, styles.withoutTextOnLoading)}
            disabled={isSubmitIsDisabled}
            loading={logInIsLoading}
            data-testid="submit-button"
          >
            {t('Auth:LogInForm.form.submit.button.label')}
          </Button>
          {timer ? (
            <p className={styles.blockTimer} data-testid="block-timer">
              {t('Auth:LogInForm.form.login.item.validationMessage.nextAttempts')} {normalizedTime(timer)}{' '}
              {t('Auth:LogInForm.form.login.item.validationMessage.nextAttempts.minutes')}
            </p>
          ) : null}
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.formHeader}>
        <h1 className={styles.formHeading}>
          <Logo />
        </h1>
      </div>
      <div className={styles.formBody}>{renderForm()}</div>
      <div className={styles.formFooter}>
        <div className={styles.formFooterElementHolder}>
          <LogInWithGoogle isDisabled={logInIsLoading} />
        </div>
        <div className={styles.formFooterElementHolder}>
          <Link
            className={classNames(styles.forgotPasswordLink, {
              // [styles.forgotPasswordLinkDisabled]: logInIsLoading, // TODO: apply when implementation will be ready
              [styles.forgotPasswordLinkDisabled]: true,
            })}
            to="/"
            data-testid="forgot-password-link"
          >
            {t('Auth:LogInForm.forgotPassword.label')}
          </Link>
        </div>
      </div>
      <div
        className={classNames(styles.formRootMessage, {
          [styles.isVisible]: !unBlockedTime && isIncorrectCredentials,
        })}
        data-testid="form-error-message"
      >
        {t('Auth:LogInForm.message.incorrectCredentials')}
      </div>
    </div>
  );
}

export default LogInForm;
