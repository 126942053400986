import { FilterDTO } from 'entities/userOverview';
import { UserOverviewFiltersForm } from '../UserOverviewFilter';

export const mapFiltersToFilterDTO = (value: UserOverviewFiltersForm['filters']): FilterDTO[] => {
  const filter: FilterDTO[] = [];
  if (!value) return filter;

  Object.keys(value).forEach((key): void => {
    const castedKey = key as keyof typeof value;
    const castedValue = value[castedKey] as Required<UserOverviewFiltersForm>['filters'][typeof castedKey] | undefined;
    let values = '';

    if (!castedValue || castedValue.length === 0) return;
    castedValue.forEach((item: string): void => {
      values = `${values},${item}`;
    });

    filter.push({ fieldName: key, value: values, negate: false, operation: 'in', relation: 'AND' });
  });
  return filter;
};
