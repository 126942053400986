import { useEffect, useState } from 'react';
import { useMutationState } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DEFAULT_LOGOUT_TIMEOUT } from '../const';
import { updateLogoutTime } from './updateLogoutTime';

export const useRequestListener = (): { enableReuqestListener: (enable: boolean) => void } => {
  const [enabled, setEnabled] = useState(false);
  const requests = useMutationState();

  useEffect(() => {
    if (!enabled) return;
    updateLogoutTime(dayjs().add(DEFAULT_LOGOUT_TIMEOUT, 'ms'));
  }, [enabled, requests.length]);

  return { enableReuqestListener: setEnabled };
};
