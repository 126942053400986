import { ReactNode, ReactElement, useEffect, useState } from 'react';
import { ConfigProvider, ThemeConfig } from 'antd';

import enUS from 'antd/lib/locale/en_US';
import ruRU from 'antd/lib/locale/ru_RU';
import { DEFAULT_UI_TEXT_DIRECTION } from 'shared/config';
import { i18n, ISOLanguageCodes } from 'shared/lib/i18n';

import { Button } from './styles/button';
import { Table } from './styles/table';
import { Tabs } from './styles/tabs';

import styles from './UIConfigProvider.module.scss';

interface IUIConfigProviderProps {
  children: ReactNode;
}

function UIConfigProvider({ children }: IUIConfigProviderProps): ReactElement {
  const [currentLocale, setCurrentLocale] = useState<ISOLanguageCodes>(ISOLanguageCodes.ENGLISH);

  useEffect(() => {
    i18n.on('languageChanged', (lng: ISOLanguageCodes) => setCurrentLocale(lng));

    return () => {
      i18n.off('languageChanged', (lng: ISOLanguageCodes) => setCurrentLocale(lng));
    };
  }, []);

  const languagesMap = {
    [ISOLanguageCodes.ENGLISH]: enUS,
    [ISOLanguageCodes.RUSSIAN]: ruRU,
  };

  const theme: ThemeConfig = {
    token: {
      colorPrimary: styles.clrPalettePrimary,
      colorInfo: styles.clrPaletteInfo,
      colorSuccess: styles.clrPaletteSuccess,
      colorError: styles.clrPaletteError,
      colorWarning: styles.clrPaletteWarning,

      fontSizeHeading1: Number(styles.h1FontSize),
      fontSizeHeading2: Number(styles.h2FontSize),
      fontSizeHeading3: Number(styles.h3FontSize),
      fontSizeHeading4: Number(styles.h4FontSize),
      fontSizeHeading5: Number(styles.h5FontSize),
      fontFamily: styles.fontFamilyBase,

      fontSizeSM: Number(styles.fontSizeSmall),
      fontSize: Number(styles.fontSizeRegular),
      fontSizeLG: Number(styles.fontSizeMedium),
      fontSizeXL: Number(styles.fontSizeLarge),
    },
    components: {
      Button,
      Tabs,
      Table,
      Notification: {
        zIndexPopup: 1010,
      },
      Modal: {
        padding: 32,
      },
    },
  };

  return (
    <ConfigProvider theme={theme} locale={languagesMap[currentLocale]} direction={DEFAULT_UI_TEXT_DIRECTION}>
      {children}
    </ConfigProvider>
  );
}

export default UIConfigProvider;
