import { DirectionType } from 'antd/es/config-provider';
import { ISOLanguageCodes } from 'shared/lib/i18n';

export const DEFAULT_LANGUAGE: ISOLanguageCodes = ISOLanguageCodes.ENGLISH;

export const DEFAULT_UI_TEXT_DIRECTION: DirectionType = 'ltr';

export const SHORT_NOTIFICATION_DURATION = 4.5;
export const LONG_NOTIFICATION_DURATION = 8;
export const ENDLESS_NOTIFICATION_DURATION = 0;

export const DEFAULT_DATE_FORMAT_FOR_REQUESTS = 'YYYY-MM-DD';
export const DEFAULT_DATE_FORMAT_UI = 'DD.MM.YYYY';

export const CURRENCY_LIST = ['$'] as const;
