import { FC, Fragment, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { UserName } from 'features/UserCard';
import { assignmentsService } from 'entities/assignment';
import { demandsService } from 'entities/demand';
import { PositionList } from 'entities/position';

import { ReactComponent as RightArrow } from 'shared/assets/icons/dynamicColor/arrow-right.svg';
import { ReactComponent as PencilIcon } from 'shared/assets/icons/pencil.svg';
import { pathKeys } from 'shared/lib/react-router';
import { LinkButton } from 'shared/ui/LinkButton';

import AssignmentList from 'components/Assignment/AssignmentList/AssimegmentList';
import { DEFAULT_SORTING, PAGE_SIZE } from 'components/Demand/Demand.consts';
import DemandList from 'components/Demand/DemandList/DemandList';

import styles from './PositionListItem.module.scss';

export interface ICollapsibleFieldProps {
  accountId: string;
  position: PositionList['content'][0];
  showEditButton?: boolean;
  showCreateAssignmentButton: boolean;
  showCreateDemandButton: boolean;
  showDemands: boolean;
  showAssignments: boolean;
}

const PositionListItem: FC<ICollapsibleFieldProps> = (props) => {
  const {
    accountId,
    position,
    showEditButton,
    showCreateAssignmentButton,
    showCreateDemandButton,
    showAssignments,
    showDemands,
  } = props;
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const { isLoading: isLoadingAssignments } = useQuery(
    assignmentsService.queryOptions(
      { positionId: position?.id, archived: false, size: 100, page: 0 },
      showAssignments && isOpen,
    ),
  );

  const { isLoading: isLoadingDemands } = useQuery(
    demandsService.queryOptions(
      { positionId: position.id, size: PAGE_SIZE, page: 0, sort: DEFAULT_SORTING },
      showDemands && isOpen,
    ),
  );

  const isDataLoaded = !isLoadingAssignments && !isLoadingDemands;

  return (
    <Fragment>
      <div className={styles.accordionHeading}>
        {(showAssignments || showDemands || showCreateAssignmentButton || showCreateDemandButton) && (
          <Button
            type="text"
            className={styles.headingArrow}
            onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
            loading={!isDataLoaded && isOpen}
            icon={<RightArrow className={isOpen ? styles.openArrow : styles.closeArrow} />}
          />
        )}
        <div className={styles.rowContainer} data-testid="list-row">
          <p className={styles.positionName} data-testid="list-item-name">
            {position.name}
          </p>
          <div className={styles.currentAssignee}>
            {t('Position:PositionAndAssignmentsList.item.currentAssignee.text')}
            {position.firstName && position.lastName && position.userId ? (
              <UserName name={`${position.firstName} ${position.lastName}`} id={position.userId} />
            ) : (
              <span>{t('Position:PositionsAndAssignmentsList.item.noName')}</span>
            )}
          </div>
          <div className={styles.countText} data-testid="list-item-count">
            {t('Position:PositionsAndAssignmentsList.assignmentsCount.name', { count: position.assignmentsCount })}
          </div>
          {showEditButton && (
            <Link to={pathKeys.position.update({ accountId, projectId: position.projectId, positionId: position.id })}>
              <button type="button" className={styles.pencilButton} data-testid="list-item-edit-button">
                <PencilIcon className={styles.pencilIcon} />
              </button>
            </Link>
          )}
        </div>
      </div>
      <div className={classNames(styles.panel, isDataLoaded && isOpen ? styles.panelOpen : styles.panelClose)}>
        <div className={styles.innerListHeading}>
          {showAssignments ? <div>{t('Assignment:PositionsAndAssignmentsList.assignmentsList.heading')}</div> : <div />}
          <div className={styles.buttonHolder}>
            {showCreateDemandButton && (
              <LinkButton
                linkProps={{
                  to: pathKeys.demand.create({ accountId, projectId: position.projectId, positionId: position.id }),
                }}
              >
                {t('Demand:PositionsAndAssignmentsList.demandAddButton.label')}
              </LinkButton>
            )}
            {showCreateAssignmentButton && (
              <LinkButton
                buttonProps={{ type: 'primary' }}
                linkProps={{
                  to: pathKeys.assignment.create({ accountId, projectId: position.projectId, positionId: position.id }),
                }}
              >
                {t('Assignment:PositionsAndAssignmentsList.assignmentTable.addButton.label')}
              </LinkButton>
            )}
          </div>
        </div>
        <div className={styles.innerListWrapper}>
          <div className={styles.assignmentList}>
            {isOpen && showAssignments && (
              <AssignmentList positionId={position.id} accountId={accountId} projectId={position.projectId} />
            )}
          </div>
          {isOpen && showDemands && (
            <Fragment>
              <div className={styles.innerListSubHeading}>
                {t('Demand:PositionsAndAssignmentsList.demandList.heading')}
              </div>
              <div>
                <DemandList positionId={position.id} accountId={accountId} projectId={position.projectId} />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default PositionListItem;
