import React from 'react';
import { useParams } from 'react-router-dom';

import ProjectUpdateForm from 'components/Project/ProjectUpdateForm/ProjectUpdateForm';

import styles from './ProjectUpdatePage.module.scss';

const ProjectUpdatePage: React.FC = () => {
  const { accountId = '', projectId = '' } = useParams();

  return (
    <div className={styles.wrapper}>
      <ProjectUpdateForm accountId={accountId} projectId={projectId} data-testid="project-update-page" />
    </div>
  );
};

export default ProjectUpdatePage;
