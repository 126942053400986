import { ReactElement } from 'react';
import { Link, To } from 'react-router-dom';

import { ReactComponent as KajunIcon } from 'shared/assets/icons/logo.svg';

import styles from './Logo.module.scss';

type LogoProps = {
  href: To;
};

function Logo({ href }: LogoProps): ReactElement {
  return (
    <Link to={href}>
      <div aria-hidden data-testid="logo" className={styles.logo}>
        <KajunIcon width={100} />
      </div>
    </Link>
  );
}

export default Logo;
