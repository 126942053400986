import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';

import { IFoldingTableProps } from './FoldingTable.types';
import FoldingTableColumn from './ui/Column/Column';
import { FoldingTableContextProvider } from './ui/Context/Context';
import FoldingTableSidebar from './ui/Sidebar/Sidebar';
import VirtualList from './ui/VirtualList/VirtualList';

import styles from './FoldingTable.module.scss';

export const COLUMN_WIDTH = 180;

function FoldingTable<T, U>(props: IFoldingTableProps<T, U>): React.ReactElement {
  const {
    columns,
    sidebar,
    initialPinnedColumnKeys: initialPinnedColumns,
    columnsStatuses = {},
    maxPinnedColumnsCount,
    onChangePin,
    renderGroupWidget,
    renderWidget,
    renderHeadingMenu,
  } = props;

  const [listSize, setListSize] = useState(0);

  const [pinnedColumnsIds, setPinnedColumnsIds] = useState<string[]>([]);
  const [openedSidebarGroups, setOpenedSidebarGroups] = useState<Set<string>>(new Set());

  const currentSidebarGroupsKeys = useMemo(() => sidebar.groups.map((group) => group.id), [sidebar.groups]);

  useEffect(() => {
    if (currentSidebarGroupsKeys.length > 0) {
      setOpenedSidebarGroups(new Set([currentSidebarGroupsKeys[0]]));
    }
  }, [currentSidebarGroupsKeys]);

  const unpinnedColumns = useMemo(
    () => columns.filter((column) => !pinnedColumnsIds.includes(column.id)),
    [columns, pinnedColumnsIds],
  );
  const pinnedColumns = useMemo(
    () => columns.filter((column) => pinnedColumnsIds.includes(column.id)),
    [columns, pinnedColumnsIds],
  );

  useEffect(() => {
    if (initialPinnedColumns) {
      setPinnedColumnsIds(initialPinnedColumns);
    }
  }, [initialPinnedColumns]);

  const onClickSidebarGroup = useCallback(
    ({ key }: { key: string }): void => {
      const isOpen = openedSidebarGroups.has(key);

      const openedSidebarGroupsNew = new Set(openedSidebarGroups);
      if (isOpen) {
        openedSidebarGroupsNew.delete(key);
      } else {
        openedSidebarGroupsNew.add(key);
      }

      setOpenedSidebarGroups(openedSidebarGroupsNew);
    },
    [openedSidebarGroups],
  );

  const onColumnPinChange = useCallback(
    (id: string): void => {
      let pinnedColumnsNew: string[] = [...pinnedColumnsIds];
      if (!pinnedColumnsIds.includes(id)) {
        pinnedColumnsNew.push(id);
      } else {
        pinnedColumnsNew = pinnedColumnsNew.filter((key) => key !== id);
      }

      setPinnedColumnsIds(pinnedColumnsNew);
      if (onChangePin) {
        onChangePin(id, pinnedColumnsNew);
      }
    },
    [onChangePin, pinnedColumnsIds],
  );

  return (
    <FoldingTableContextProvider value={{ opened: openedSidebarGroups, columnsStatuses }}>
      <div className={styles.rootWrapper}>
        <aside className={styles.sidebarHolder}>
          <FoldingTableSidebar sidebar={sidebar} onClickSidebarGroup={onClickSidebarGroup} />
        </aside>
        <ResizeObserver
          onResize={({ width }) => {
            setListSize(width - pinnedColumnsIds.length * COLUMN_WIDTH);
          }}
        >
          <div className={styles.columnsHolder}>
            <div key="shadow-container" className={classNames(styles.tableContainer, styles.shadow)}>
              {pinnedColumns.map((column) => {
                return (
                  <FoldingTableColumn
                    key={column.id}
                    sidebarGroupsKeys={currentSidebarGroupsKeys}
                    column={column}
                    onColumnPinChange={onColumnPinChange}
                    isPinned
                    isPinnedMaxColumnCount={pinnedColumnsIds.length >= maxPinnedColumnsCount}
                    renderGroupWidget={renderGroupWidget}
                    renderWidget={renderWidget}
                    renderHeadingMenu={renderHeadingMenu}
                  />
                );
              })}
            </div>
            <VirtualList
              unpinnedColumns={unpinnedColumns}
              sidebarGroupsKeys={currentSidebarGroupsKeys}
              isPinnedMaxColumnCount={pinnedColumnsIds.length >= maxPinnedColumnsCount}
              onColumnPinChange={onColumnPinChange}
              renderGroupWidget={renderGroupWidget}
              renderWidget={renderWidget}
              renderHeadingMenu={renderHeadingMenu}
              width={listSize}
            />
          </div>
        </ResizeObserver>
      </div>
    </FoldingTableContextProvider>
  );
}

export default React.memo(FoldingTable) as typeof FoldingTable;
