import React, { ChangeEvent, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Checkbox, Input, Tooltip } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { UserSearchDTO } from 'entities/user';

import { useDebounceValue } from 'shared/lib/debounce';
import { EmptyGroup } from 'shared/ui/EmptyGroup';
import { RcPagination } from 'shared/ui/RcPagination';

import { ITEMS_PER_PAGE } from '../../consts';
import { useCheckboxState } from '../../lib/useCheckboxState';
import { useUsersToRender } from '../../lib/useUsersToRender';
import UserItem from '../UserItem/UserItem';

import styles from './TransferContent.module.scss';

const { Search } = Input;

export type TransferContentProps = {
  title: ReactNode;
  users: UserSearchDTO[];
  setSearchParams: ({ page, query, offset }: { page: number; query: string; offset: number }) => void;
  countOfFoundUsers: number;
  usersMovedToGroup?: UserSearchDTO[];
  usersMovedFromGroup?: UserSearchDTO[];
  selectedUsers: UserSearchDTO[];
  onChangeSelectedUsers: (users: UserSearchDTO[]) => void;
  loading: boolean;
  disableSelect: boolean;
};

const TransferContent: FC<TransferContentProps> = ({
  title,
  users = [],
  setSearchParams,
  countOfFoundUsers,
  usersMovedToGroup = [],
  usersMovedFromGroup = [],
  selectedUsers,
  onChangeSelectedUsers,
  loading,
  disableSelect,
}) => {
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchInput = useDebounceValue(searchInput, 500);

  const [page, setPage] = useState(1);

  const [usersToRender, offset] = useUsersToRender(page, users, usersMovedToGroup, !!debouncedSearchInput);

  const countOfMovedUsersOnCurrentPage = usersToRender.filter((user) =>
    usersMovedFromGroup.find((u) => u.id === user.id),
  ).length;

  const selectedUsersIds = useMemo(() => new Set(selectedUsers.map((user) => user.id)), [selectedUsers]);

  const [isChecked, isIndeterminate] = useCheckboxState(
    usersToRender,
    selectedUsersIds,
    countOfMovedUsersOnCurrentPage,
  );

  useEffect(() => {
    // *Change page if after move back users the page not exists
    if (countOfFoundUsers < -offset && page > 1) {
      setPage((prev) => prev - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset <= 0 && offset]);

  useEffect(() => {
    if (offset <= 0 && countOfFoundUsers > 0) {
      setSearchParams({ page: page - 1, query: debouncedSearchInput, offset: usersMovedToGroup.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset <= 0 && usersMovedToGroup.length]);

  // *onChange debounceSearchValue
  useEffect(() => {
    setSearchParams({ page: 0, query: debouncedSearchInput, offset: usersMovedToGroup.length });

    if (selectedUsersIds.size > 0) {
      onChangeSelectedUsers([]);
    }
    if (page !== 1) {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput, setSearchParams]);

  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchInput(event.currentTarget.value);
  };

  const onChangePage = (pageNum: number): void => {
    setSearchParams({ page: pageNum - 1, query: debouncedSearchInput, offset: usersMovedToGroup.length });
    setPage(pageNum);
  };

  const checkAll = (): void => {
    if (!isChecked && !isIndeterminate) {
      const idsToPush = new Set<string>();

      usersToRender.forEach((user) => {
        if (!selectedUsersIds.has(user.id) && !usersMovedFromGroup.find((u) => u.id === user.id))
          idsToPush.add(user.id);
      });

      onChangeSelectedUsers([...selectedUsers, ...usersToRender.filter((user) => idsToPush.has(user.id))]);
    } else {
      const idsToRemove = new Set<string>();
      usersToRender.forEach((user) => {
        if (selectedUsersIds.has(user.id)) idsToRemove.add(user.id);
      });

      onChangeSelectedUsers(selectedUsers.filter((user) => !idsToRemove.has(user.id)));
    }
  };

  const renderTopCheckbox = (): JSX.Element => {
    let tooltipText = t('AccessGroup:TransferContent.selectAll');
    if (isIndeterminate) {
      tooltipText = t('AccessGroup:TransferContent.deselectTheSelectedUsers');
    } else if (isChecked) {
      tooltipText = t('AccessGroup:TransferContent.deselectAll');
    }

    return (
      <Tooltip title={tooltipText} placement="topLeft" arrow={{ pointAtCenter: true }}>
        <Checkbox
          type="checkbox"
          disabled={disableSelect}
          checked={isChecked && !loading}
          indeterminate={isIndeterminate && !loading}
          onChange={checkAll}
          className={styles.allCheckbox}
        />
      </Tooltip>
    );
  };

  const onSelectUser = (userId: string): void => {
    if (selectedUsersIds.has(userId)) {
      onChangeSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
    } else {
      const user = usersToRender.find((u) => u.id === userId);
      if (user) onChangeSelectedUsers([...selectedUsers, user]);
    }
  };

  const renderMovedUsersCount = (): JSX.Element => {
    const showMovedUsersCount = usersMovedToGroup.length > 0 || usersMovedFromGroup.length > 0;

    return (
      <div className={classNames(styles.badgeWrapper, { [styles.hidden]: !showMovedUsersCount })}>
        <div className={classNames({ [`${styles.badge} ${styles.positive}`]: usersMovedToGroup.length })}>
          {usersMovedToGroup.length > 0 && (
            <span className={styles.countBadge}>
              +{t('AccessGroup:TransferContent.Transfer.user', { count: usersMovedToGroup.length })}
            </span>
          )}
        </div>
        <div className={classNames({ [`${styles.badge} ${styles.negative}`]: usersMovedFromGroup.length })}>
          {usersMovedFromGroup.length > 0 && (
            <span className={styles.countBadge}>
              -{t('AccessGroup:TransferContent.Transfer.user', { count: usersMovedFromGroup.length })}
            </span>
          )}
        </div>
      </div>
    );
  };

  const renderUsersCount = (): JSX.Element => {
    if (selectedUsers.length > 0) {
      return (
        <p className={styles.searchResultCount}>
          <span className={styles.selectedUsers}>{selectedUsers.length}</span>{' '}
          {t('AccessGroup:TransferContent.Transfer.usersOf')} {countOfFoundUsers + usersMovedToGroup.length}
        </p>
      );
    }
    return (
      <p className={styles.searchResultCount}>
        {t('AccessGroup:TransferContent.Transfer.user', { count: countOfFoundUsers + usersMovedToGroup.length })}
      </p>
    );
  };

  return (
    <section className={styles.contentWrapper}>
      <h4>{title}</h4>
      <div className={styles.content}>
        <div className={styles.topBlock}>
          {renderTopCheckbox()}
          <div className={styles.usersCountInfo}>
            {renderMovedUsersCount()}
            {renderUsersCount()}
          </div>
        </div>
        <div className={styles.searchWrapper}>
          <Search placeholder={t('AccessGroup:SearchInput.search.placeholder')} allowClear onChange={onSearch} />
        </div>
        {loading || users.length > 0 || (usersMovedToGroup.length > 0 && !debouncedSearchInput) ? (
          <React.Fragment>
            <ul className={styles.showPeoples}>
              {usersToRender.map((user, index) => {
                const keepLoaded = index < offset;
                return (
                  <li key={user.id}>
                    <UserItem
                      user={user}
                      loading={keepLoaded ? false : loading}
                      handleSelect={onSelectUser}
                      disabled={(!loading || !keepLoaded) && usersMovedFromGroup.some((u) => u.id === user.id)}
                      moved={usersMovedToGroup.some((u) => u.id === user.id)}
                      selected={!loading && selectedUsersIds.has(user.id)}
                      disableSelect={disableSelect}
                    />
                  </li>
                );
              })}
              {loading && usersToRender.length < ITEMS_PER_PAGE
                ? Array.from({ length: ITEMS_PER_PAGE - usersToRender.length }).map(() => {
                    return (
                      <li key={uuidv4()}>
                        <UserItem loading />
                      </li>
                    );
                  })
                : null}
            </ul>
            <div className={styles.pagination}>
              <div className={styles.paginationSkeleton}>
                <RcPagination
                  pageSize={ITEMS_PER_PAGE}
                  current={page}
                  setCurrentPage={onChangePage}
                  dataSize={debouncedSearchInput ? countOfFoundUsers : countOfFoundUsers + usersMovedToGroup.length}
                />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <EmptyGroup />
        )}
      </div>
    </section>
  );
};

export default React.memo(TransferContent);
