import React from 'react';
import { useParams } from 'react-router-dom';

import DemandCreateForm from 'components/Demand/DemandCreateForm/DemandCreateForm';

import styles from './DemandCreatePage.module.scss';

const DemandCreatePage: React.FC = () => {
  const { accountId = '', projectId = '', positionId = '' } = useParams();

  return (
    <div className={styles.wrapper}>
      <DemandCreateForm accountId={accountId} projectId={projectId} positionId={positionId} />
    </div>
  );
};

export default DemandCreatePage;
