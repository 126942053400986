export const pathKeys = {
  root: '/',
  login() {
    return pathKeys.root.concat('log-in/');
  },
  logout() {
    return pathKeys.root.concat('log-out/');
  },
  dashboard() {
    return pathKeys.root.concat('dashboard/');
  },
  accessGroups() {
    return pathKeys.root.concat('access-groups/');
  },
  userOverview() {
    return pathKeys.root.concat('users/');
  },
  account: {
    root() {
      return pathKeys.root.concat('accounts/');
    },
    item({ accountId }: { accountId: string }) {
      return pathKeys.account.root().concat(accountId, '/');
    },
    create() {
      return pathKeys.account.root().concat('create/');
    },
    update({ accountId }: { accountId: string }) {
      return pathKeys.account.item({ accountId }).concat('edit/');
    },
  },
  project: {
    root(ids: { accountId: string }) {
      return pathKeys.account.item(ids).concat('projects/');
    },
    item({ projectId, ...ids }: { accountId: string; projectId: string }) {
      return pathKeys.project.root(ids).concat(projectId, '/');
    },
    create(ids: { accountId: string }) {
      return pathKeys.project.root(ids).concat('create/');
    },
    update(ids: { accountId: string; projectId: string }) {
      return pathKeys.project.item(ids).concat('edit/');
    },
  },
  position: {
    root(ids: { accountId: string; projectId: string }) {
      return pathKeys.project.item(ids).concat('positions/');
    },
    item({ positionId, ...ids }: { accountId: string; projectId: string; positionId: string }) {
      return pathKeys.position.root(ids).concat(positionId, '/');
    },
    create(ids: { accountId: string; projectId: string }) {
      return pathKeys.position.root(ids).concat('create/');
    },
    update(ids: { accountId: string; projectId: string; positionId: string }) {
      return pathKeys.position.item(ids).concat('edit/');
    },
  },
  assignment: {
    root(ids: { accountId: string; projectId: string; positionId: string }) {
      return pathKeys.position.item(ids).concat('assignments/');
    },
    item({ assignmentId, ...ids }: { accountId: string; projectId: string; positionId: string; assignmentId: string }) {
      return pathKeys.assignment.root(ids).concat(assignmentId, '/');
    },
    create(ids: { accountId: string; projectId: string; positionId: string }) {
      return pathKeys.assignment.root(ids).concat('create/');
    },
    update(ids: { accountId: string; projectId: string; positionId: string; assignmentId: string }) {
      return pathKeys.assignment.item(ids).concat('edit/');
    },
  },
  demand: {
    root(ids: { accountId: string; projectId: string; positionId: string }) {
      return pathKeys.position.item(ids).concat('demands/');
    },
    item({ demandId, ...ids }: { accountId: string; projectId: string; positionId: string; demandId: string }) {
      return pathKeys.demand.root(ids).concat(demandId, '/');
    },
    create(ids: { accountId: string; projectId: string; positionId: string }) {
      return pathKeys.demand.root(ids).concat('create/');
    },
    update(ids: { accountId: string; projectId: string; positionId: string; demandId: string }) {
      return pathKeys.demand.item(ids).concat('edit/');
    },
  },
  externalWarning() {
    return pathKeys.root.concat('externalWarning/');
  },
  page404() {
    return pathKeys.root.concat('not-found/');
  },
};
