import axios from 'axios';
import { errorResponseInterceptor } from './responseInterceptor';

const baseURL = process.env.REACT_APP_DOMAIN ?? '';

export const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  maxRedirects: 0,
});

export const returnToName = 'returnTo';

export const saveToken = (token: string): void => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const deleteToken = (): void => {
  axiosInstance.defaults.headers.common.Authorization = '';
};

// TODO: Find other place.
axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => errorResponseInterceptor(error),
);
