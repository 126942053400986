import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';
import { pathKeys } from 'shared/lib/react-router';

import DashboardPage from './DashboardPage';

export const dashboardPageRoute: RouteObject = {
  path: pathKeys.dashboard(),
  element: createElement(DashboardPage),
  loader: async (args) => {
    const requiredPermissions = replacePermissions(pathPermissions.dashboard, args.params);
    if (!isHasAccess(requiredPermissions)) {
      return redirect(pathKeys.dashboard());
    }

    return args;
  },
};
