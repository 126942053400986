import { useCallback, useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { Column, Sidebar } from 'features/FoldingTable';
import { useTransferModalStore } from 'features/moveUsersBetweenGroups';
import {
  useAddUsersAGMutation,
  useRemoveUsersAGMutation,
  type AccessGroupDTO,
  type GrantType,
} from 'entities/accessGroup';
import { accessGroupUsersWithCountService } from 'entities/user';

import ColumnUsers from '../ui/ColumnUsers/ColumnUsers';

export type WidgetType = { accessGroupId: string; permissionId: string; grantType: GrantType | null; version: number };

type UseColumnsReturnType = {
  columns: Column<null, WidgetType>[];
  onCloseTransferModal: () => void;
  onSaveTransferModal: (groupId: string, usersIdsToMoveIn: string[], usersIdsToMoveOut: string[]) => void;
};

export const useColumns = (rawColumns: AccessGroupDTO[], mappedSidebar: Sidebar): UseColumnsReturnType => {
  const { mutate: addUsersMutation } = useAddUsersAGMutation();
  const { mutate: removeUsersMutation } = useRemoveUsersAGMutation();

  const { closeModal: closeTranferModal, openModal: openTranferModal } = useTransferModalStore();

  const groupsIds = useMemo(() => {
    return rawColumns.map((group) => group.id);
  }, [rawColumns]);

  const { data: accessGroupsUsersInfo, refetch: refetchAccessGroupsUsersInfo } = useSuspenseQuery(
    accessGroupUsersWithCountService.queryOptions({ arrayOfGroups: groupsIds, usersCount: 5 }),
  );

  const onClickUsers = useCallback(
    (id: string, name: string): (() => void) | undefined => {
      return () => openTranferModal(id, name);
    },
    [openTranferModal],
  );

  const onSaveTransferModal: UseColumnsReturnType['onSaveTransferModal'] = useCallback(
    (accessGroupId, usersIdsToMoveIn, usersIdsToMoveOut) => {
      if (usersIdsToMoveIn.length > 0) {
        addUsersMutation(
          { id: accessGroupId, userIds: usersIdsToMoveIn },
          {
            onSuccess: () => {
              if (!usersIdsToMoveOut.length) refetchAccessGroupsUsersInfo();
            },
          },
        );
      }

      if (usersIdsToMoveOut.length > 0) {
        removeUsersMutation(
          { id: accessGroupId, userIds: usersIdsToMoveOut },
          { onSuccess: () => refetchAccessGroupsUsersInfo() },
        );
      }

      closeTranferModal();
    },
    [addUsersMutation, closeTranferModal, refetchAccessGroupsUsersInfo, removeUsersMutation],
  );
  const columns = useMemo(() => {
    return rawColumns
      .map<Column<null, WidgetType>>((column) => {
        const columnContent = mappedSidebar.groups.map((groupOuter) => {
          return {
            id: groupOuter.id,
            name: groupOuter.name,
            widget: null,
            items: groupOuter.items.map((groupInner) => {
              const currentPermission = column.permissions.find(
                (permission) => permission.permission.id === groupInner.id,
              );

              return {
                id: groupInner.id,
                name: groupInner.name,
                widget: {
                  accessGroupId: column.id,
                  permissionId: groupInner.id,
                  grantType: currentPermission?.grantType ?? null,
                  version: currentPermission?.version ?? 0,
                },
              };
            }),
          };
        });

        const { usersCount, usersShortList } = accessGroupsUsersInfo?.[column.id] ?? {};

        return {
          id: column.id,
          name: column.name,
          content: columnContent,
          menuWidget: column.id,
          headingWidget: (
            <ColumnUsers
              onClickUsers={onClickUsers(column.id, column.name)}
              usersCount={usersCount}
              usersShortList={usersShortList}
            />
          ),
        };
      })
      .sort((currentColumn, nextColumn) => {
        return currentColumn.name.localeCompare(nextColumn.name);
      });
  }, [accessGroupsUsersInfo, mappedSidebar.groups, onClickUsers, rawColumns]);

  return {
    columns,
    onCloseTransferModal: closeTranferModal,
    onSaveTransferModal,
  };
};
