import React, { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { EmptyGroup } from 'shared/ui/EmptyGroup';

import ListItem from './ui/ListItem/ListItem';
import { IVirtualListProps } from './VirtualList.types';
import styles from './VirtualList.module.scss';

/**
 * @template T
 * @prop {T[]} data - The array of data to be rendered in the list
 * @prop {number} rowHeight - The height (in pixels) of a single row in the list
 * @prop {boolean} hasNextPage - Indicates if there are more items to be loaded
 * @prop {Function} loadNextPage - The function to call to load the next set of items
 * @prop {boolean} isNextPageLoading - Flag to indicate if the next page is being loaded
 * @prop {Function} renderItem - The function that renders each item in the list
 * @prop {ReactNode} skeletonItem - Skeleton placeholder for unloaded items
 * @prop {ReactNode} emptyPageContent - The content to render when there are no data items to display.
 * @prop {Ref} listRef - React ref object for the virtual list component
 * @prop {number} threshold - The number of items to preload before reaching the end of the current list
 * @prop {CSSDirection} direction - The direction of the text and scrolling inside the component
 */
function VirtualList<T>(props: IVirtualListProps<T>): React.ReactElement {
  const { t } = useTranslation('ReusableComponents');

  const {
    data,
    itemSize,
    hasNextPage,
    loadNextPage,
    isNextPageLoading,
    renderItem,
    skeletonItem,
    emptyPageContent,
    listRef,
    threshold = 10,
    direction = 'ltr',
    layout = 'vertical',
  } = props;

  const [listSize, setListSize] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // need to set height (for vertical layout) or width (for horizontal layout) for list
  useLayoutEffect(() => {
    const clientSize = layout === 'vertical' ? wrapperRef.current?.clientHeight : wrapperRef.current?.clientWidth;
    setListSize(clientSize || 0);
  }, [layout]);

  const isDataEmpty = data.length === 0;

  const isItemLoaded = (index: number): boolean => !hasNextPage || index < data.length;
  const getItemCount = (): number => {
    if (isDataEmpty && isNextPageLoading) {
      return Math.floor(listSize / itemSize);
    }

    return hasNextPage ? data.length + 1 : data.length;
  };
  const loadMoreItems = isNextPageLoading ? () => null : loadNextPage;

  const renderEmptyPage = (): React.ReactNode => {
    return typeof emptyPageContent === 'undefined' ? (
      <EmptyGroup title={t('VirtualList.emptyList.text')} />
    ) : (
      emptyPageContent
    );
  };

  return (
    <div className={styles.rootWrapper} ref={wrapperRef}>
      {!hasNextPage && !isNextPageLoading && isDataEmpty ? (
        renderEmptyPage()
      ) : (
        <InfiniteLoader
          ref={listRef}
          isItemLoaded={isItemLoaded}
          itemCount={getItemCount()}
          loadMoreItems={loadMoreItems}
          threshold={threshold}
        >
          {({ onItemsRendered, ref }) => (
            <List
              itemData={data}
              itemSize={itemSize}
              itemCount={getItemCount()}
              height={layout === 'vertical' ? listSize : '100%'}
              width={layout !== 'vertical' ? listSize : 'auto'}
              onItemsRendered={onItemsRendered}
              ref={ref}
              direction={direction}
              layout={layout}
            >
              {({ index, style, data: itemData }) =>
                ListItem({ index, style, data: itemData, isItemLoaded, renderItem, skeletonItem })
              }
            </List>
          )}
        </InfiniteLoader>
      )}
    </div>
  );
}

export default VirtualList;
