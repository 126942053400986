import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

// TODO: still bad practice according to FSD
// eslint-disable-next-line boundaries/element-types
import { authStore } from 'entities/session';

import { axiosInstance, saveToken } from './config';
import { ExtendAxiosRequestConfig } from './types';

// Define the structure of a retry queue item
interface RetryQueueItem {
  resolve: (value: AxiosResponse) => void;
  reject: (error: AxiosResponse) => void;
  config: AxiosRequestConfig;
}

type AuthResponse = {
  accessToken: string;
  accessTokenExpiredAt: string;
  refreshToken: string;
  refreshTokenExpiredAt: string;
  tokenType: string;
};

// Create a list to hold the request queue
const refreshAndRetryQueue: RetryQueueItem[] = [];

// Flag to prevent multiple token refresh requests
let isRefreshing = false;

const ignoredRefreshTokenUrls = ['/api/auth/refresh-access-token', '/login', '/login/google'];

const refreshAccessToken = (refreshToken: string): Promise<AuthResponse> => {
  return axiosInstance.post('/api/auth/refresh-access-token', { refreshToken }, { headers: { Authorization: '' } });
};

export const errorResponseInterceptor = async <T = Error | AxiosError | null>(error: T): Promise<AxiosResponse> => {
  if (error !== null && axios.isAxiosError(error)) {
    const {
      response,
      config,
    }: { response?: AxiosResponse; request?: XMLHttpRequest; config?: ExtendAxiosRequestConfig } = error;

    if (response && response.status !== 500) {
      const { status } = response;

      const shouldRefreshTokens =
        status === 401 && config && !config._retry && !ignoredRefreshTokenUrls.includes(config?.url ?? '');

      if (shouldRefreshTokens) {
        config._retry = true;

        if (!isRefreshing) {
          isRefreshing = true;
          try {
            const oldRefreshToken = authStore.getState().refreshToken ?? '';

            const tokens = await refreshAccessToken(oldRefreshToken);
            const { accessToken, refreshToken, refreshTokenExpiredAt } = tokens;

            authStore.getState().updateTokens({ token: accessToken, refreshToken, refreshTokenExpiredAt });
            saveToken(accessToken);
            refreshAndRetryQueue.forEach(({ config: requestConfig, resolve, reject }) => {
              const newConfig = {
                ...requestConfig,
                headers: { ...requestConfig.headers, 'Content-Type': 'application/json' },
              };
              axiosInstance
                .request(newConfig)
                .then((res) => resolve(res))
                .catch((err) => reject(err));
            });

            config.headers = axiosInstance.defaults.headers.common;

            if (config.method !== 'get') {
              config.headers['Content-Type'] = 'application/json';
            }

            return axiosInstance(config);
          } catch {
            refreshAndRetryQueue.length = 0;
            return Promise.reject(error);
          } finally {
            isRefreshing = false;
          }
        }

        config.headers = axiosInstance.defaults.headers.common;

        // Add the original request to the queue
        return new Promise((resolve, reject) => {
          refreshAndRetryQueue.push({ config, resolve, reject });
        });
      }

      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
};
