import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';

import { projectService } from 'entities/project';

import { pathKeys } from 'shared/lib/react-router';

import ProjectUpdatePage from './ProjectUpdatePage';

export const projectUpdatePageRoute: RouteObject = {
  path: ':projectId/edit/',
  element: createElement(ProjectUpdatePage),
  loader: async (args) => {
    const requiredPermissions = replacePermissions(pathPermissions.project.update, args.params);
    if (!isHasAccess(requiredPermissions)) {
      return redirect(pathKeys.dashboard());
    }

    if (!args.params.accountId || !args.params.projectId) {
      return redirect(pathKeys.page404());
    }

    Promise.resolve(projectService.prefetchQuery(args.params.projectId));

    return args;
  },
};
