import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';
import { demandParentInfoService, demandService } from 'entities/demand';
import { positionService } from 'entities/position';

import { pathKeys } from 'shared/lib/react-router';

import DemandUpdatePage from './DemandUpdatePage';

export const demandUpdatePageRoute: RouteObject = {
  path: ':positionId/demands/:demandId/edit/',
  element: createElement(DemandUpdatePage),
  loader: async (args) => {
    const requiredPermissions = replacePermissions(pathPermissions.demand.update, args.params);
    if (!isHasAccess(requiredPermissions)) {
      return redirect(pathKeys.dashboard());
    }

    if (!args.params.positionId || !args.params.demandId) {
      return redirect(pathKeys.page404());
    }

    Promise.all([
      positionService.prefetchQuery(args.params.positionId),
      demandService.prefetchQuery(args.params.demandId),
      demandParentInfoService.prefetchQuery(args.params.positionId),
    ]);

    return args;
  },
};
