import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const demandWidgetPermissions: RequiredPermissions = [
  [{ type: 'global', global: AvailableGlobalPermissions.DEMAND, value: AvailablePermissionsValue.READ }],
];

export const getDemandWidgetItemLinkPermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.READ,
    },
  ],
];
