import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { isHasAccess } from 'features/validateAccess';
import { DemandStatus, DemandWidgetList } from 'entities/demand';

import { DEFAULT_DATE_FORMAT_UI } from 'shared/config';
import { convertDayJsToString, convertToDayJs } from 'shared/lib/dayjs';
import { pathKeys } from 'shared/lib/react-router';
import { Avatar } from 'shared/ui/Avatar';
import { ProtectedLink } from 'shared/ui/ProtectedLink';

import { getDemandWidgetItemLinkPermissions } from '../../DemandWidget.permissions';

import styles from './DemandWidgetItem.module.scss';

type DemandItemPropsType = {
  demandData: DemandWidgetList['content'][number];
};

const DemandWidgetItem: React.FC<DemandItemPropsType> = (props) => {
  const { demandData } = props;
  const { t } = useTranslation();

  const recruiterFullName =
    demandData.demandRecruiterLastName &&
    demandData.demandRecruiterFirstName &&
    `${demandData.demandRecruiterLastName} ${demandData.demandRecruiterFirstName}`;

  const demandDesirableDate = convertDayJsToString(
    convertToDayJs(demandData.demandDesirableDate),
    DEFAULT_DATE_FORMAT_UI,
  );

  // Todo find best way for implement colors
  const statusColorHandle = (status: DemandStatus): string => {
    switch (status) {
      case 'ACTIVE':
        return '#F5222D';
      case 'ON_HOLD':
        return '#BFBFBF';
      case 'STAFFED':
        return '#1F3C5E';
      default:
        return '#DFF9D2';
    }
  };

  const showLink = isHasAccess(getDemandWidgetItemLinkPermissions(demandData.accountId, demandData.projectId));

  return (
    <ProtectedLink
      hasAccess={showLink}
      className={styles.wrapper}
      to={pathKeys.position.root({ accountId: demandData.accountId, projectId: demandData.projectId })}
    >
      <div className={styles.demandItems}>
        <div className={styles.demandLogoStatus}>
          <span
            className={styles.demandStatus}
            style={{ backgroundColor: statusColorHandle(demandData.demandStatus) }}
          />
          <Avatar picture={demandData.accountLogo} text={demandData.accountName} size={24} className={styles.avatar} />
          <span className={styles.demandWidgetText}>{demandData.positionName}</span>
        </div>
        <div className={styles.projectName}>
          <span className={styles.demandWidgetText}>{demandData.projectName}</span>
        </div>
        <div className={styles.recruiter}>
          {t('Demand:DemandWidgetList.recruiter')}
          {recruiterFullName && (
            <Tooltip placement="top" title={recruiterFullName}>
              <span className={styles.demandWidgetText}>
                <Avatar picture={demandData.demandRecruiterPictureUrl} text={recruiterFullName} size={24} />
              </span>
            </Tooltip>
          )}
        </div>
        <div className={styles.demandDesirableDate}>
          <span className={styles.demandWidgetText}>{demandDesirableDate}</span>
        </div>
      </div>
    </ProtectedLink>
  );
};
export default DemandWidgetItem;
