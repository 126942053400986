import { useMemo } from 'react';

import { UserSearchDTO } from 'entities/user';

import { ITEMS_PER_PAGE } from '../consts';

export const useUsersToRender = (
  page: number,
  users: UserSearchDTO[],
  usersMovedToGroup: UserSearchDTO[],
  ignoreMoved: boolean,
): [UserSearchDTO[], number] => {
  const offset = usersMovedToGroup.length - (page - 1) * ITEMS_PER_PAGE;

  const usersToRender = useMemo(() => {
    const movedUsersToRender =
      offset > 0 && !ignoreMoved
        ? usersMovedToGroup.slice(
            (page - 1) * ITEMS_PER_PAGE,
            offset < ITEMS_PER_PAGE ? (page - 1) * ITEMS_PER_PAGE + offset : page * ITEMS_PER_PAGE,
          )
        : [];

    return movedUsersToRender.length > 12
      ? movedUsersToRender
      : [...movedUsersToRender, ...users.slice(0, ITEMS_PER_PAGE - movedUsersToRender.length)];
  }, [ignoreMoved, offset, page, users, usersMovedToGroup]);

  return [usersToRender, offset];
};
