import React, { FC, ReactNode } from 'react';
import { Tooltip } from 'antd';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as ArchiveIcon } from 'shared/assets/icons/dynamicColor/archive_permission.svg';
import { ReactComponent as PencilIcon } from 'shared/assets/icons/pencil.svg';
import { ProtectedLink } from 'shared/ui/ProtectedLink';

import styles from './RowItem.module.scss';

export interface IFieldProps {
  name: string;
  count: number | string;
  countName: string;
  openLinkHref: string;
  editLinkHref: string;
  logo?: ReactNode;
  isArchived?: boolean;
  hideEditButton?: boolean;
  disableLink?: boolean;
  iconTooltip?: string;
}

const RowItem: FC<IFieldProps> = ({
  name,
  count,
  countName,
  openLinkHref,
  editLinkHref,
  logo,
  isArchived,
  disableLink,
  hideEditButton,
  iconTooltip,
}) => {
  const countText = [countName, count].join(' ');

  return (
    <li className={cn(styles.rowContainer)} data-testid="list-row">
      <div className={styles.logo}>{logo}</div>
      {isArchived && (
        <Tooltip overlay={iconTooltip}>
          <ArchiveIcon className={styles.archiveIcon} />
        </Tooltip>
      )}
      <div className={styles.rowContent}>
        <ProtectedLink
          hasAccess={!disableLink}
          to={openLinkHref}
          className={cn(styles.link, { [styles.disabled]: disableLink, [styles.archived]: isArchived })}
        >
          {name}
        </ProtectedLink>
        <div className={styles.rightBlock}>
          <div data-testid="list-item-count" className={styles.countText}>
            {countText}
          </div>
        </div>
      </div>
      {!isArchived && !hideEditButton ? (
        <Link to={editLinkHref}>
          <button type="button" className={styles.pencilButton} data-testid="list-item-edit-button">
            <PencilIcon className={styles.pencilIcon} />
          </button>
        </Link>
      ) : (
        <div style={{ width: 36 }} />
      )}
    </li>
  );
};

export default RowItem;
