import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserTable } from 'features/UserTable';

import tabStyles from 'shared/styles/ant-custom-components/tabs.module.scss';

import Heading from './ui/heading/Heading';
import UserSearchInput from './ui/UserSearchInput/UserSearchInput';

import styles from './UserOverview.module.scss';

const UserOverview: React.FC = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      key: 'user-overview',
      label: t('UserOverview:UserOverview.tabs.tableView.title'),
      children: (
        <div className={styles.tableWrapper}>
          <UserTable />
        </div>
      ),
    },
  ];

  return (
    <div className={styles.wrapperContent}>
      <Heading />
      <Tabs
        defaultActiveKey="user-overview"
        className={tabStyles.customTabs}
        items={tabs}
        tabBarExtraContent={{ right: <UserSearchInput /> }}
      />
    </div>
  );
};

export default React.memo(UserOverview);
