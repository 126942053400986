import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { LinkButton } from 'shared/ui/LinkButton';

import styles from './ExternalWarning.module.scss';

function ExternalWarning(): JSX.Element {
  const { t } = useTranslation('ReusableComponents');
  const { hash } = useLocation();
  const [searchParams] = useSearchParams();
  const baseLink = searchParams.get('link') ?? '';

  const cvLink = `${baseLink}${hash}`;

  return (
    <div className={styles.container}>
      <h1>{t('ExternalWarning.title')}</h1>
      <p>{t('ExternalWarning.text')}</p>
      <LinkButton linkProps={{ to: cvLink }} buttonProps={{ type: 'primary' }}>
        {t('ExternalWarning.confirmBtn.label')}
      </LinkButton>
    </div>
  );
}

export default ExternalWarning;
