import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const ACCESS_GROUPS_EDIT: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCESS_GROUP,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const ACCESS_GROUPS_ARCHIVE: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCESS_GROUP,
      value: AvailablePermissionsValue.ARCHIVE,
    },
  ],
];

export const ACCESS_GROUPS_CREATE: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCESS_GROUP,
      value: AvailablePermissionsValue.CREATE,
    },
  ],
];
