import React, { ReactElement } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import styles from './PageWithHeaderLayout.module.scss';

const { Content } = Layout;

type LayoutProps = {
  header: ReactElement;
};

function PageWithHeaderLayout({ header }: LayoutProps): ReactElement {
  return (
    <Layout className={styles.antLayout}>
      {header}
      <Content className={styles.mainContent}>
        <Outlet />
      </Content>
    </Layout>
  );
}

export default PageWithHeaderLayout;
