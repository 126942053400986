import { useEffect, useMemo, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';

import { accessGroupsService, type AccessGroupDTO } from 'entities/accessGroup';
import { MappedUserDTO } from 'entities/user';

export type LocalGroupType = Pick<MappedUserDTO, 'accessGroups'>['accessGroups'];

type UseMapperReturnType = {
  selectedData: {
    users: Omit<MappedUserDTO, 'accessGroups'>[];
    accessGroups: LocalGroupType;
  };
  currentUsers: MappedUserDTO[];
  accessGroups: AccessGroupDTO[];
  onMoveUsers: () => void;
  onRemoveUser: (id: string) => void;
  onRemoveGroup: (userId: string, groupId: string) => void;
  onSelectUser: (user?: Omit<MappedUserDTO, 'accessGroups'>) => void;
  onDeselectUser: (value: string) => void;
  onSelectGroup: (value: string) => void;
  onDeselectGroup: (value: string) => void;
  onAfterClose: () => void;
  isChanged: boolean;
};

export const useMapper = (open: boolean, initialData: MappedUserDTO[]): UseMapperReturnType => {
  const [selectedData, setSelectedData] = useState<{
    users: Omit<MappedUserDTO, 'accessGroups'>[];
    accessGroups: LocalGroupType;
  }>({
    users: [],
    accessGroups: [],
  });

  const [currentUsers, setCurrentUsers] = useState<MappedUserDTO[]>([]);

  const { data: accessGroups } = useSuspenseQuery(accessGroupsService.queryOptions());
  const sortedAccessGroups = useMemo(() => {
    return [...accessGroups].sort((a, b) => a.name.localeCompare(b.name));
  }, [accessGroups]);

  useEffect(() => {
    setCurrentUsers(initialData);
  }, [initialData, open]);

  const onMoveUsers = (): void => {
    const selectedUsers = selectedData.users.map((user) => {
      return { ...user, accessGroups: selectedData.accessGroups };
    });

    const oldUsers = currentUsers.filter((user) => !selectedUsers.some((u) => user.id === u.id));
    const rewrittenUsers = currentUsers
      .filter((user) => selectedUsers.some((selected) => selected.id === user.id))
      .map((user) => ({
        ...user,
        accessGroups: [
          ...user.accessGroups,
          ...selectedData.accessGroups.filter((group) => !user.accessGroups.some((ug) => ug.id === group.id)),
        ],
      }));
    const newUsers = selectedUsers.filter((user) => !currentUsers.some((u) => user.id === u.id));

    setCurrentUsers([...newUsers, ...rewrittenUsers, ...oldUsers]);
    setSelectedData({ users: [], accessGroups: [] });
  };

  const onRemoveUser = (id: string): void => {
    setCurrentUsers(currentUsers.filter((user) => user.id !== id));
  };

  const onRemoveGroup = (userId: string, groupId: string): void => {
    setCurrentUsers(
      currentUsers.map((user) => {
        if (user.id === userId) {
          return { ...user, accessGroups: user.accessGroups.filter((group) => group.id !== groupId) };
        }
        return user;
      }),
    );
  };

  const onSelectUser = (user?: Omit<MappedUserDTO, 'accessGroups'>): void => {
    if (user) {
      setSelectedData({
        ...selectedData,
        users: [...selectedData.users, user],
      });
    }
  };

  const onDeselectUser = (value: string): void => {
    setSelectedData({
      ...selectedData,
      users: selectedData.users.filter((user) => user.id !== value),
    });
  };

  const onSelectGroup = (value: string): void => {
    const newGroup = accessGroups.find((group) => group.id === value);
    if (!newGroup) {
      return;
    }

    setSelectedData({
      ...selectedData,
      accessGroups: [...selectedData.accessGroups, newGroup],
    });
  };

  const onDeselectGroup = (value: string): void => {
    setSelectedData({
      ...selectedData,
      accessGroups: selectedData.accessGroups.filter((group) => group.id !== value),
    });
  };

  const onAfterClose = (): void => {
    setSelectedData({
      users: [],
      accessGroups: [],
    });
  };

  const isChanged = JSON.stringify(currentUsers) !== JSON.stringify(initialData);

  return {
    selectedData,
    currentUsers,
    accessGroups: sortedAccessGroups,
    onMoveUsers,
    onRemoveUser,
    onRemoveGroup,
    onSelectUser,
    onDeselectUser,
    onSelectGroup,
    onDeselectGroup,
    onAfterClose,
    isChanged,
  };
};
