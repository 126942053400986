import { DEFAULT_PATHS_MAP } from '../const';
import { hasToken } from '../model/store';

export const getDefaultRedirectPath = (): string => {
  const isUserAuthorized: boolean = hasToken();

  let redirectPath: string;

  if (isUserAuthorized) {
    redirectPath = DEFAULT_PATHS_MAP.authorized?.defaultRedirectPath;
  } else {
    redirectPath = DEFAULT_PATHS_MAP.unauthorized?.defaultRedirectPath;
  }

  return redirectPath;
};
