import React, { ReactElement } from 'react';
import { Input, InputProps } from 'antd';

import { ReactComponent as EyeClosedIcon } from 'shared/assets/icons/dynamicColor/eye-closed.svg';
import { ReactComponent as EyeOpenedIcon } from 'shared/assets/icons/dynamicColor/eye-opened.svg';
import customAntFormStyles from 'shared/styles/ant-custom-components/form.module.scss';

import styles from './CustomAntInputPassword.module.scss';

function CustomAntInputPassword(props: InputProps): ReactElement {
  const { className: externalClassName } = props;

  const iconRender = (visible: boolean): ReactElement => {
    if (visible) {
      return <EyeOpenedIcon stroke={styles.clrPalettePrimary25} />;
    }
    return <EyeClosedIcon stroke={styles.clrPalettePrimary25} />;
  };

  return (
    <Input.Password
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={
        externalClassName
          ? `${customAntFormStyles.customAntPasswordInput} ${externalClassName}`
          : customAntFormStyles.customAntPasswordInput
      }
      iconRender={iconRender}
    />
  );
}

export default CustomAntInputPassword;
