import { Maybe } from 'shared/types';

const { localStorage }: { localStorage: Storage } = window;

class LocalStorageService {
  static setItem<T>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static getItem<T>(key: string): Maybe<T> {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }
    return JSON.parse(item);
  }

  static clear(key: string): void {
    if (key) {
      return localStorage.removeItem(key);
    }
    return localStorage.clear();
  }
}

export default LocalStorageService;
