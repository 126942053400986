import React from 'react';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { useStore } from 'zustand';
import { userOverviewStore } from 'entities/userOverview';
import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg';

import { useDebouncedFunction } from 'shared/lib/debounce';

import styles from './UserSearchInput.module.scss';

// TODO: maybe need to move this to the shared ui and pass callaback as a prop
const UserSearchInput: React.FC = () => {
  const { t } = useTranslation();

  const { setSearchQuery } = useStore(userOverviewStore);

  const debouncedSearchHandler = useDebouncedFunction(setSearchQuery, 500);

  return (
    <Input
      onChange={(event) => debouncedSearchHandler(event.currentTarget.value)}
      prefix={<SearchIcon className={styles.searchIcon} />}
      className={styles.searchInput}
      placeholder={t('UserOverview:UserTable.UserSearchInput.placeholder')}
      style={{ borderRadius: '6px' }}
    />
  );
};

export default UserSearchInput;
