import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const getLocalMappingPermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.MAP,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.MAP,
    },
    {
      type: 'local',
      global: AvailableGlobalPermissions.PROJECT,
      local: projectId,
      value: AvailablePermissionsValue.MAP,
    },
  ],
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCESS_GROUP,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getEditAllProjectPermissions = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.UPDATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const getEditProjectLocalPermission = (projectId: string): RequiredPermissions => [
  [
    {
      type: 'local',
      global: AvailableGlobalPermissions.PROJECT,
      local: projectId,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const getGlobalReadPositionPermission = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      value: AvailablePermissionsValue.READ,
      global: AvailableGlobalPermissions.POSITION,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getLocalReadPositionPermission = (projectId: string): RequiredPermissions => [
  [
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getAccountNameViewPermission = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCOUNT,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'local',
      global: AvailableGlobalPermissions.ACCOUNT,
      local: accountId,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getProjectArchivePermission = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.ARCHIVE,
    },
    {
      type: 'local',
      global: AvailableGlobalPermissions.PROJECT,
      local: projectId,
      value: AvailablePermissionsValue.ARCHIVE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.ARCHIVE,
    },
  ],
];
