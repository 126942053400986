import { useMemo } from 'react';
import { AvailableGlobalPermissions, AvailablePermissionsValue } from 'features/validateAccess';

const PERMISSION_SPLIT_REGEX = new RegExp(`:(${Object.values(AvailablePermissionsValue).join('|')})$`);

type MappedPermissions = {
  global: string;
  local?: string;
  subGlobal?: string;
  values: Record<string, boolean>;
};

type ReturnValue = {
  permissions: Record<string, MappedPermissions>;
  accountIds: string[];
  projectIds: string[];
};

export const useMapPermissions = (userPermissions: string[] = []): ReturnValue => {
  return useMemo(() => {
    const mappedResult = userPermissions.reduce(
      (acc, item) => {
        const mappedItem = item.split(PERMISSION_SPLIT_REGEX, 2);

        const itemDetails = mappedItem[0].split(':');

        const { permissions: permissionsNew, accountIds, projectIds } = acc;

        if (itemDetails[0] === AvailableGlobalPermissions.ACCOUNT && itemDetails.length > 1) {
          accountIds.add(itemDetails[1]);
        }
        if (itemDetails[0] === AvailableGlobalPermissions.PROJECT && itemDetails.length > 1) {
          projectIds.add(itemDetails[1]);
        }

        permissionsNew[mappedItem[0]] = permissionsNew[mappedItem[0]]
          ? {
              ...permissionsNew[mappedItem[0]],
              values: { ...permissionsNew[mappedItem[0]].values, [mappedItem[1]]: true },
            }
          : {
              global: itemDetails[0],
              local: itemDetails[1],
              subGlobal: itemDetails[2],
              values: { [mappedItem[1]]: true },
            };

        return acc;
      },
      {
        permissions: {} as Record<string, MappedPermissions>,
        accountIds: new Set<string>(),
        projectIds: new Set<string>(),
      },
    );

    return {
      permissions: mappedResult.permissions,
      accountIds: Array.from(mappedResult.accountIds),
      projectIds: Array.from(mappedResult.projectIds),
    };
  }, [userPermissions]);
};
