import { ReactElement, useEffect } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { authStore } from 'entities/session';

import { deleteToken } from 'shared/lib/axios';
import { queryClient } from 'shared/lib/react-query';
import { pathKeys } from 'shared/lib/react-router';
import { ErrorBoundary } from 'shared/ui/ErrorBoundary';

import { initApp } from '../lib/initApp';

import { RouterProvider } from './Router';
import { UIConfigProvider } from './UIConfigProvider';

// eslint-disable-next-line no-restricted-imports
import 'shared/lib/i18n/i18n';
import 'shared/styles/styles.scss';

dayjs.extend(utc, { parseToLocal: true });

function App(): ReactElement {
  useEffect(() => {
    initApp();
  }, []);

  // TODO: find other place.
  const onRestoreApp = (): void => {
    authStore.getState().reset();
    deleteToken();
    window.open(`${pathKeys.login()}`, '_self');
  };

  return (
    <ErrorBoundary onRestore={onRestoreApp}>
      <QueryClientProvider client={queryClient}>
        <UIConfigProvider>
          <RouterProvider />
          <ReactQueryDevtools />
        </UIConfigProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
