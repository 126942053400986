import React, { useContext } from 'react';
import { Divider, Tooltip } from 'antd';
import classNames from 'classnames';

import { ReactComponent as PinButton } from 'shared/assets/icons/dynamicColor/pin-button.svg';

import { Column, ColumnStatus, IFoldingTableProps } from '../../FoldingTable.types';
import { FoldingTableContext } from '../Context/Context';

import styles from './Column.module.scss';

export interface IFoldingTableColumnProps<T, U> {
  column: Column<T, U>;
  renderGroupWidget: IFoldingTableProps<T>['renderGroupWidget'];
  renderWidget: IFoldingTableProps<T, U>['renderWidget'];
  onColumnPinChange: (id: string) => void;
  sidebarGroupsKeys: string[];
  isPinned: boolean;
  isPinnedMaxColumnCount: boolean;
  renderHeadingMenu: IFoldingTableProps<T, U>['renderHeadingMenu'];
}

function FoldingTableColumn<T, U>(props: IFoldingTableColumnProps<T, U>): React.ReactElement {
  const {
    column,
    onColumnPinChange,
    sidebarGroupsKeys,
    isPinned,
    isPinnedMaxColumnCount,
    renderGroupWidget,
    renderWidget,
    renderHeadingMenu,
  } = props;

  const { opened: openedGroupsIds, columnsStatuses } = useContext(FoldingTableContext);

  const columnStatus = columnsStatuses[column.id];

  return (
    <article
      className={classNames(styles.rootWrapper, {
        [styles.columnBorderTop]: columnStatus === ColumnStatus.EDIT,
        [styles.columnErrorBorderTop]: columnStatus === ColumnStatus.ERROR,
      })}
    >
      <header className={styles.columnHeader}>
        <div className={styles.topHeaderContentLeft}>
          <button
            className={classNames(styles.pinButton, {
              [styles.disabled]: isPinnedMaxColumnCount && !isPinned,
            })}
            disabled={isPinnedMaxColumnCount && !isPinned}
            type="button"
            onClick={() => onColumnPinChange(column.id)}
          >
            {isPinned ? <PinButton className={styles.pinned} /> : <PinButton className={styles.unpinned} />}
          </button>
          <Tooltip placement="top" title={column.name}>
            <div className={styles.groupsTitle}>{column.name}</div>
          </Tooltip>
          <div className={styles.menuOption}>{renderHeadingMenu(column.id)}</div>
        </div>
        <div>{column.headingWidget}</div>
      </header>
      <Divider className={styles.divider} />
      <div>
        {sidebarGroupsKeys.map((id) => {
          const columnContent = column.content.find((group) => group.id === id);

          return (
            <div key={id} className={styles.contentItemWrapper}>
              <div className={styles.groupWidgetWrapper}>
                {columnContent?.widget && renderGroupWidget ? (
                  renderGroupWidget(columnContent.id, columnContent.widget)
                ) : (
                  <div className={styles.emptyRow} data-testid="select-settings" />
                )}
              </div>
              <div className={styles.groupItemsWrapper}>
                {columnContent?.items.map((item) => (
                  <div
                    key={item.id}
                    className={styles.groupItemWidgetWrapper}
                    style={{ display: openedGroupsIds.has(id) ? 'flex' : 'none' }}
                  >
                    {renderWidget(item.id, item.widget, columnStatus !== ColumnStatus.EDIT)}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </article>
  );
}

export default FoldingTableColumn;
