import React, { ReactElement } from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import classNames from 'classnames';

import { LanguageService } from 'features/changeLanguage';
import { useGoogleLoginUserMutation } from 'entities/session';

import styles from './LogInWithGoogle.module.scss';

interface ILogInWithGoogleProps {
  isDisabled: boolean;
  isLoading: boolean;
}

function LogInWithGoogle({ isDisabled, isLoading }: ILogInWithGoogleProps): ReactElement {
  const locale = LanguageService.getCurrentLanguage();
  const { mutate: logInWithGoogleMutation } = useGoogleLoginUserMutation();

  const handleIdToken = (credentialResponse: CredentialResponse): void => {
    if (credentialResponse.credential) {
      logInWithGoogleMutation(credentialResponse.credential);
    }
  };

  return (
    <div
      id="google-button"
      className={classNames(
        styles.logInWithGoogle,
        { [styles.logInWithGoogleDisabled]: isDisabled },
        { [styles.logInWithGoogleLoading]: isLoading },
      )}
      data-testid="google-logIn"
    >
      <GoogleLogin onSuccess={handleIdToken} size="medium" locale={locale} />
    </div>
  );
}

export default LogInWithGoogle;
