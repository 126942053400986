import React from 'react';
import { useParams } from 'react-router-dom';

import AssignmentUpdateForm from 'components/Assignment/AssignmentUpdateForm/AssignmentUpdateForm';

import styles from './AssignmentUpdatePage.module.scss';

const AssignmentUpdatePage: React.FC = () => {
  const { accountId = '', projectId = '', positionId = '', assignmentId = '' } = useParams();

  return (
    <div className={styles.wrapper}>
      <AssignmentUpdateForm
        accountId={accountId}
        projectId={projectId}
        positionId={positionId}
        assignmentId={assignmentId}
      />
    </div>
  );
};

export default AssignmentUpdatePage;
