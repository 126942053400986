import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AccessGroupsManagementTable } from 'widgets/AccessGroupsManagementTable';

import { BackButton } from 'shared/ui/BackButton';

import styles from './AccessGroupsManagementPage.module.scss';

function AccessGroupsManagementPage(): ReactElement {
  const { t } = useTranslation('AccessGroup');

  return (
    <div className={styles.rootWrapper}>
      <header className={styles.topContentWrapper}>
        <div className={styles.leftContentHolder}>
          <div className={styles.titleBlock}>
            <BackButton />
            <h3 className={styles.pageHeading}>{t('Header.title')}</h3>
          </div>
        </div>
        <div className={styles.rightContentHolder}>
          <div />
        </div>
      </header>
      <div className={styles.mainContentHolder}>
        <AccessGroupsManagementTable />
      </div>
    </div>
  );
}

export default AccessGroupsManagementPage;
