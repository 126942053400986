import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './PanelHeader.module.scss';

type HeaderProps = {
  count: number;
  headerKey: string;
};

export const PanelHeader = ({ count, headerKey }: HeaderProps): React.ReactElement => {
  const { t } = useTranslation('UserOverview');
  return (
    <div className={classNames(styles.panelHeader, { [styles.selected]: count > 0 })}>
      <span>{t(`UserTable.header.column.${headerKey}.title`)}</span>
      {count > 0 && <div>{count}</div>}
    </div>
  );
};
