import { ReactElement, useCallback, useEffect } from 'react';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useStore } from 'zustand';

import { notificationStore } from 'shared/model/notification';

import { mapNotification } from './lib/mapNotification';

// TODO: move to hook
export const NotificationProvider = (): ReactElement => {
  const { t } = useTranslation();
  const { notification: antdNotification } = App.useApp();

  const location = useLocation();

  const { notifications, closeNotifications } = useStore(notificationStore);

  const onLocationChange = useCallback(() => {
    Object.keys(notifications.short).forEach((key) => {
      antdNotification.destroy(key);
    });
    Object.keys(notifications.long).forEach((key) => {
      antdNotification.destroy(key);
    });
    closeNotifications('short');
    closeNotifications('long');
  }, [antdNotification, closeNotifications, notifications.long, notifications.short]);

  useEffect(() => {
    if (Object.keys(notifications.short).length || Object.keys(notifications.long).length) {
      onLocationChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    Object.keys(notifications).forEach((type) => {
      const castedKey = type as keyof typeof notifications;
      const notificationsList = notifications[castedKey];

      Object.keys(notificationsList).forEach((key) => {
        const { method, ...notification } = notificationsList[key];
        const notificationConfig = mapNotification(antdNotification, notification, t);
        antdNotification[method](notificationConfig);
      });
    });
  }, [antdNotification, notifications, t]);

  return <div />;
};
