import { useCallback } from 'react';
import { PositionDTO } from 'entities/position';
import { convertToDayJs } from 'shared/lib/dayjs';
import { PositionFormType } from '../PositionUpdateForm';

export const useInitData = (position: PositionDTO): PositionFormType => {
  const initData = useCallback(() => {
    const { name, startDate, endDate, description } = position;
    return {
      name,
      description,
      startDate: convertToDayJs(startDate),
      endDate: endDate ? convertToDayJs(endDate) : undefined,
      rates: position.rates.map((rate) => {
        return {
          value: rate.value.toString(),
          currency: rate.currency,
          startDate: convertToDayJs(rate.startDate),
          endDate: rate.endDate ? convertToDayJs(rate.endDate) : undefined,
        };
      }),
    };
  }, [position]);

  return initData();
};
