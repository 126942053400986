import { ReactElement, ReactNode } from 'react';

import { isHasAccess, RequiredPermissions } from 'features/validateAccess';

import { Placeholder } from './ui/placeholder';
import styles from './WidgetManager.module.scss';

type WidgetManagerProps = {
  items: {
    key: string;
    content: ReactNode;
    requiredPermissions?: RequiredPermissions;
  }[];
};

function WidgetManager({ items }: WidgetManagerProps): ReactElement {
  // ? Temp solution
  const emptyCount = 4 - items.length;

  return (
    <div className={styles.rootWrapper}>
      {items.map((item) => {
        const show = item.requiredPermissions ? isHasAccess(item.requiredPermissions) : true;
        return (
          <div className={styles.widgetHolder} key={item.key}>
            {show ? item.content : <Placeholder />}
          </div>
        );
      })}
      {Array.from({ length: emptyCount }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.widgetHolder} key={index}>
          <Placeholder />
        </div>
      ))}
    </div>
  );
}

export default WidgetManager;
