import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './NotFoundPage.module.scss';

function NotFoundPage(): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={styles.heading}>
      <div>404</div>
      <p>{t('UI:NotFoundPage.heading')}</p>
    </div>
  );
}

export default NotFoundPage;
