import React, { ChangeEvent, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Input, Menu, MenuProps, Skeleton } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { recruitersService, Recruiter } from 'entities/demand';

import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg';
import { useDebounceValue } from 'shared/lib/debounce';
import { Avatar } from 'shared/ui/Avatar';
import { EmptyGroup } from 'shared/ui/EmptyGroup';
import { ModalDialog } from 'shared/ui/ModalDialog';

import styles from './SelectUserForDemandModal.module.scss';

const PAGE_SIZE = 10;

const SelectUserForDemandModal: React.FC<{
  open: boolean;
  setOpen: (status: boolean) => void;
  onOkButtonClick: (recruiter: Recruiter) => void;
}> = ({ open, setOpen, onOkButtonClick }) => {
  const { t } = useTranslation();

  const [sortedRecruiters, setSortedRecruiters] = useState<Recruiter[]>([]);

  const [selectedUserId, setSelectedUserId] = useState('');
  const [searchInput, setSearchInput] = useState('');

  const { data: recruiters, isLoading: userListIsLoading } = useQuery(recruitersService.queryOptions());

  const debouncedSearchInput = useDebounceValue(searchInput, 250);

  useEffect(() => {
    if (!open) {
      setSearchInput('');
      return;
    }
    setSelectedUserId('');
  }, [open]);

  useEffect(() => {
    if (!debouncedSearchInput) {
      setSortedRecruiters(recruiters);
      return;
    }
    const filteredRecruiters = recruiters.filter(({ firstName, lastName }) => {
      return (
        firstName.toLowerCase().includes(debouncedSearchInput.toLowerCase()) ||
        lastName.toLowerCase().includes(debouncedSearchInput.toLowerCase())
      );
    });
    setSortedRecruiters(filteredRecruiters);
  }, [debouncedSearchInput, recruiters]);

  const onUserSelect: MenuProps['onClick'] = (e) => {
    const userId = e.key;
    setSelectedUserId(userId);
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;

    setSearchInput(value);
  };

  const onOkButtonClickWrapper = (): void => {
    if (selectedUserId) {
      const user = sortedRecruiters?.find(({ id }) => id === selectedUserId);
      if (!user) {
        return;
      }
      const { firstName, lastName, picture } = user;
      onOkButtonClick({ id: selectedUserId, firstName, lastName, picture });
    }
    setOpen(false);
  };

  const onCancelButtonClick = (): void => {
    setOpen(false);
  };

  const renderUserTable = (): ItemType[] => {
    return sortedRecruiters.map((user) => {
      return {
        icon: <Avatar text={`${user.firstName} ${user.lastName}`} picture={user.picture} />,
        label: `${user.firstName} ${user.lastName}`,
        key: user.id,
      };
    });
  };

  const renderResult = (): ReactNode =>
    sortedRecruiters && sortedRecruiters.length !== 0 ? (
      <Menu onClick={onUserSelect} theme="light" items={renderUserTable()} className={styles.menu} />
    ) : (
      <EmptyGroup />
    );

  const renderSkeleton = (): ReactNode => (
    <div className={classNames(styles.listSkeleton, 'ant-menu')} data-testid="select-user-for-demand-skeleton">
      {Array.from({ length: PAGE_SIZE }, (_, id) => (
        <Skeleton
          key={id}
          className={classNames(styles.skeletonItem, 'ant-menu-item')}
          loading
          avatar={{ size: 32 }}
          active
          paragraph={{ rows: 0, style: { margin: 0 } }}
        />
      ))}
    </div>
  );

  const renderBody = (): ReactElement => {
    return (
      <div className={styles.rootWrapper} data-testid="select-user-for-demand">
        <div className={styles.header}>
          <Input value={searchInput} onChange={onSearch} suffix={<SearchIcon />} />
        </div>
        <div className={styles.tableWrapper}>
          <div className={styles.table}>{userListIsLoading ? renderSkeleton() : renderResult()}</div>
        </div>
      </div>
    );
  };

  return (
    <ModalDialog
      open={open}
      body={renderBody()}
      header={t('Demand:SelectUserForDemandModal.heading')}
      closeFn={onCancelButtonClick}
      actionFn={onOkButtonClickWrapper}
    />
  );
};

export default React.memo(SelectUserForDemandModal);
