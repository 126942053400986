import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { accountCreatePageRoute } from 'pages/AccountCreatePage';
import { accountUpdatePageRoute } from 'pages/AccountUpdatePage';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';

import { accountsService } from 'entities/account';

import { pathKeys } from 'shared/lib/react-router';
import {
  DEFAULT_IS_HIDDEN_ARCHIVED_ACCOUNTS,
  DEFAULT_IS_HIDDEN_EXPIRED_ACCOUNTS,
  PAGE_SIZE,
} from 'components/Account/Account.const';

import AccountsPage from './AccountsPage';

export const accountsPageRoute: RouteObject = {
  path: pathKeys.account.root(),
  children: [
    {
      index: true,
      element: createElement(AccountsPage),
      loader: async (args) => {
        const requiredPermissions = replacePermissions(pathPermissions.account.root, args.params);
        if (!isHasAccess(requiredPermissions)) {
          return redirect(pathKeys.dashboard());
        }

        Promise.resolve(
          accountsService.prefetchQuery({
            page: 0,
            hideNonActive: DEFAULT_IS_HIDDEN_EXPIRED_ACCOUNTS,
            showArchived: DEFAULT_IS_HIDDEN_ARCHIVED_ACCOUNTS,
            size: PAGE_SIZE,
          }),
        );

        return args;
      },
    },
    accountUpdatePageRoute,
    accountCreatePageRoute,
  ],
};
