import React from 'react';

import { ReactComponent as ArrowLeft } from 'shared/assets/icons/dynamicColor/arrow-left.svg';
import { ReactComponent as ArrowRight } from 'shared/assets/icons/dynamicColor/arrow-right.svg';

import styles from './MoveButton.module.scss';

export type MoveButtonProps = {
  isActive: boolean;
  onClick: () => void;
  direction: 'left' | 'right';
};

const MoveButton: React.FC<MoveButtonProps> = ({ isActive, onClick, direction }) => {
  return (
    <button type="button" onClick={onClick} disabled={!isActive} className={styles.moveButton}>
      {direction === 'left' ? <ArrowLeft /> : <ArrowRight />}
    </button>
  );
};
export default MoveButton;
