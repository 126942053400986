import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { isHasAccess } from 'features/validateAccess';
import { ModalDialog } from 'shared/ui/ModalDialog';

import { getUsersIds } from '../../lib/getUsersIds';
import { useTransferModalStore } from '../../model/store';
import { CAN_SELECT_USERS } from '../../permissions';

import Transfer from '../Transfer/Transfer';

import styles from './TransferModal.module.scss';

type TransferModalProps = {
  onSave: (groupId: string, usersIdsToMoveIn: string[], usersIdsToMoveOut: string[]) => void;
  onClose: () => void;
};
const TransferModal: FC<TransferModalProps> = ({ onClose, onSave }) => {
  const { t } = useTranslation();

  const { isOpen, groupId, resetMovedUsers, usersMovedFromGroup, usersMovedToGroup } = useTransferModalStore();

  const disableUserSelection = !isHasAccess(CAN_SELECT_USERS);

  const saveChanged = (): void => {
    if (groupId) onSave(groupId, getUsersIds(usersMovedToGroup), getUsersIds(usersMovedFromGroup));
    setTimeout(() => resetMovedUsers(), 700);
  };

  const onCloseWrapper = (): void => {
    onClose();
    resetMovedUsers();
  };

  return (
    <ModalDialog
      includeDiscardChangesOverlay={usersMovedToGroup.length > 0 || usersMovedFromGroup.length > 0}
      open={isOpen}
      closeFn={onCloseWrapper}
      actionFn={saveChanged}
      body={<Transfer disableUserSelection={disableUserSelection} />}
      okButtonProps={{
        type: 'primary',
        size: 'large',
        className: classNames(styles.saveButton, {
          [styles.hideButton]: disableUserSelection,
        }),
      }}
      cancelButtonProps={{
        type: 'default',
        size: 'large',
      }}
      okText={t('AccessGroup:Transfer.saveButton.label')}
      cancelText={t('AccessGroup:Transfer.closeButton.label')}
      width={1120}
    />
  );
};

export default React.memo(TransferModal);
