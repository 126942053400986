import React, { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Skeleton, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { useTranslation } from 'react-i18next';

import { AvailableGlobalPermissions } from 'features/validateAccess';

import { accountsNamesService } from 'entities/account';
import { userPermissionsService } from 'entities/permission';
import { projectsNamesService } from 'entities/project';

import { ReactComponent as ArchiveIcon } from 'shared/assets/icons/dynamicColor/archive_permission.svg';
import { ReactComponent as CreateIcon } from 'shared/assets/icons/dynamicColor/create_permission.svg';
import { ReactComponent as DeleteIcon } from 'shared/assets/icons/dynamicColor/delete_permission.svg';
import { ReactComponent as MappingIcon } from 'shared/assets/icons/dynamicColor/mapping_permission.svg';
import { ReactComponent as ReadIcon } from 'shared/assets/icons/dynamicColor/read_permission.svg';
import { ReactComponent as UpdateIcon } from 'shared/assets/icons/dynamicColor/update_permission.svg';
import { ReactComponent as SuccessCheckmarkIcon } from 'shared/assets/icons/successCheckmark.svg';

import { getLocalName } from '../../lib/getLocalName';
import { useMapPermissions } from '../../lib/useMapPermissions';
import SkeletonTable, { SkeletonTableColumnsType } from '../TableSkeleton/TableSkeleton';

import styles from './permissionsTab.module.scss';

type ITabProps = {
  userId: string;
};

export type PermissionTableDataType = {
  key: string;
  permission: {
    global: string;
    subGlobal?: string;
    local?: string;
  };
  read: React.ReactNode;
  update: React.ReactNode;
  create: React.ReactNode;
  delete: React.ReactNode;
  archive: React.ReactNode;
  map: React.ReactNode;
};

const ROW_HEIGHT = 57;

export const PermissionsTab: React.FC<ITabProps> = ({ userId }) => {
  const { t } = useTranslation('UserOverview');

  const { data: userPermissions, isPending: isPermissionLoading } = useQuery(
    userPermissionsService.queryOptions(userId),
  );

  const { permissions: mappedPermissions, accountIds, projectIds } = useMapPermissions(userPermissions);

  const { data: accountNames, isLoading: isAccountNamesLoading } = useQuery(
    accountsNamesService.queryOptions(accountIds),
  );
  const { data: projectNames, isLoading: isProjectNamesLoading } = useQuery(
    projectsNamesService.queryOptions(projectIds),
  );

  const columns: ColumnsType<PermissionTableDataType> = [
    {
      title: '',
      dataIndex: 'permission',
      key: 'permission',
      render: (value: PermissionTableDataType['permission']) => {
        if (value.global === AvailableGlobalPermissions.USER && value.local === userId) {
          return t('UserCard.permissions.local.ownData');
        }

        let permission = '';

        if (value.global && value.local && value.subGlobal) {
          permission = t('UserCard.permissions.local.name.withSubGlobal', {
            global: value.global,
            local: getLocalName(value.global, value.local, accountNames, projectNames),
            subGlobal: value.subGlobal,
          });
        } else if (value.global && value.local) {
          permission = t('UserCard.permissions.local.name.default', {
            global: value.global,
            local: getLocalName(value.global, value.local, accountNames, projectNames),
          });
        } else {
          permission = t('UserCard.permissions.global.name', {
            global: value.global,
          });
        }

        if (
          value.local &&
          (value.global === AvailableGlobalPermissions.ACCOUNT || value.global === AvailableGlobalPermissions.PROJECT)
        ) {
          return (
            <Skeleton
              className={styles.skeletonTableItem}
              paragraph={false}
              active
              loading={isAccountNamesLoading || isProjectNamesLoading}
            >
              {permission}
            </Skeleton>
          );
        }

        return permission;
      },
    },
    {
      title: (
        <Tooltip overlay={t('UserCard.permissions.values.READ')}>
          <ReadIcon className={styles.primaryIcon} />
        </Tooltip>
      ),
      width: ROW_HEIGHT,
      align: 'center',
      dataIndex: 'read',
      key: 'read',
    },
    {
      title: (
        <Tooltip overlay={t('UserCard.permissions.values.UPDATE')}>
          <UpdateIcon className={styles.primaryIcon} />
        </Tooltip>
      ),
      width: ROW_HEIGHT,
      align: 'center',
      dataIndex: 'update',
      key: 'update',
    },
    {
      title: (
        <Tooltip overlay={t('UserCard.permissions.values.CREATE')}>
          <CreateIcon className={styles.primaryIcon} />
        </Tooltip>
      ),
      width: ROW_HEIGHT,
      align: 'center',
      dataIndex: 'create',
      key: 'create',
    },
    {
      title: (
        <Tooltip overlay={t('UserCard.permissions.values.ARCHIVE')}>
          <ArchiveIcon className={styles.primaryIcon} />
        </Tooltip>
      ),
      width: ROW_HEIGHT,
      align: 'center',
      dataIndex: 'archive',
      key: 'archive',
    },
    {
      title: (
        <Tooltip overlay={t('UserCard.permissions.values.DELETE')}>
          <DeleteIcon className={styles.primaryIcon} />
        </Tooltip>
      ),
      width: ROW_HEIGHT,
      align: 'center',
      dataIndex: 'delete',
      key: 'delete',
    },
    {
      title: (
        <Tooltip overlay={t('UserCard.permissions.values.MAPPING')}>
          <MappingIcon className={styles.primaryIcon} />
        </Tooltip>
      ),
      width: ROW_HEIGHT,
      align: 'center',
      dataIndex: 'map',
      key: 'map',
    },
  ];

  const dataSource: PermissionTableDataType[] = useMemo(
    () =>
      Object.keys(mappedPermissions).map((key) => {
        const mappedItem = mappedPermissions[key];

        return {
          key,
          permission: {
            global: mappedItem.global,
            subGlobal: mappedItem.subGlobal,
            local: mappedItem.local,
          },
          read: mappedItem.values.READ ? <SuccessCheckmarkIcon className={styles.checkMarkIcon} /> : null,
          update: mappedItem.values.UPDATE ? <SuccessCheckmarkIcon className={styles.checkMarkIcon} /> : null,
          create: mappedItem.values.CREATE ? <SuccessCheckmarkIcon className={styles.checkMarkIcon} /> : null,
          archive: mappedItem.values.ARCHIVE ? <SuccessCheckmarkIcon className={styles.checkMarkIcon} /> : null,
          delete: mappedItem.values.DELETE ? <SuccessCheckmarkIcon className={styles.checkMarkIcon} /> : null,
          map: mappedItem.values.MAPPING ? <SuccessCheckmarkIcon className={styles.checkMarkIcon} /> : null,
        };
      }),
    [mappedPermissions],
  );

  return (
    <div className={styles.tabWrapper}>
      <SkeletonTable loading={isPermissionLoading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          // * row size - border
          scroll={{ y: `calc(100% - ${ROW_HEIGHT - 2}px)` }}
          className={styles.permissionTable}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          bordered
        />
      </SkeletonTable>
    </div>
  );
};
