import { axiosInstance } from 'shared/lib/axios';
import { AuthFormLoginRequest, AuthResponse, AuthUserDTO } from './types';

export const logIn = (credentials: AuthFormLoginRequest): Promise<AuthResponse> => {
  const { login, password } = credentials;

  return axiosInstance.post('/login', { username: login, password });
};

export const logInWithGoogle = (tokenId: string): Promise<AuthResponse> => {
  return axiosInstance.get(`/login/google`, { headers: { 'X-GOOGLE-ID-TOKEN': tokenId } });
};

export const authenticatedUserGet = (): Promise<AuthUserDTO> => {
  return axiosInstance.get(`/api/auth/this`);
};

export const logOut = (): Promise<void> => {
  return axiosInstance.post('/logout');
};
