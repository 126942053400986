import React from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as BackButtonArrow } from 'shared/assets/icons/dynamicColor/back-button-arrow.svg';

import styles from './BackButton.module.scss';

type BackButtonProps = { onClick?: () => void; isDisabled?: boolean };

const BackButton: React.FC<BackButtonProps> = (props) => {
  const navigate = useNavigate();

  const defaultBackButtonOnClick = (): void => {
    navigate(-1);
  };

  const { onClick = defaultBackButtonOnClick, isDisabled = false } = props;

  return (
    <Button
      shape="circle"
      icon={<BackButtonArrow className={styles.backButtonArrow} />}
      disabled={isDisabled}
      className={styles.backButton}
      onClick={onClick}
      data-testid="back-button"
    />
  );
};

export default BackButton;
