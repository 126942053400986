import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalDialog } from 'shared/ui/ModalDialog';

import styles from './ArchiveEntityModal.module.scss';

const ArchiveEntityModalTypes = ['normal', 'failed'] as const;

interface IArchiveEntityModalProps {
  type: typeof ArchiveEntityModalTypes[number];
  open: boolean;
  isLoading: boolean;
  normalText: string;
  failedText?: ReactNode;
  archiveEntity: () => void;
  closeModal: () => void;
}

const ArchiveEntityModal: React.FC<IArchiveEntityModalProps> = ({
  type,
  open,
  isLoading,
  normalText,
  failedText,
  archiveEntity,
  closeModal,
}) => {
  const { t } = useTranslation();

  const isNormal = type === 'normal';

  const renderModalBody = (): ReactNode => {
    return <div className={styles.bodyWrapper}>{isNormal ? normalText : failedText}</div>;
  };

  return (
    <ModalDialog
      open={open}
      loading={isLoading}
      body={renderModalBody()}
      closeFn={closeModal}
      okButtonProps={{
        children: isNormal
          ? t('ReusableComponents:ArchiveEntityModal.normal.archiveButton.label')
          : t('ReusableComponents:ArchiveEntityModal.withAssignmentIn.okButton.label'),
        onClick: isNormal ? archiveEntity : closeModal,
      }}
      cancelButtonProps={{
        children: t('ReusableComponents:ArchiveEntityModal.normal.cancelButton.label'),
        style: isNormal ? undefined : { display: 'none' },
      }}
    />
  );
};

export default ArchiveEntityModal;
