import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const CAN_SELECT_USERS: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCESS_GROUP,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];
