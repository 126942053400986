import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ModalDialog } from 'shared/ui/ModalDialog';

import { useUserCardStore } from '../../model/store';
import { CardContent } from '../CardContent/CardContent';

import styles from './UserCard.module.scss';

const UserCard: React.FC = () => {
  const location = useLocation();

  const { userId, isOpen, closeCard } = useUserCardStore();

  const closeFn = (): void => {
    closeCard();
  };

  useEffect(() => {
    if (isOpen) closeCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <ModalDialog
      data-testid="user-card"
      width={796}
      className={styles.modalView}
      open={isOpen}
      body={userId && <CardContent userId={userId} />}
      closeFn={closeFn}
      footer={null}
    />
  );
};

export default UserCard;
