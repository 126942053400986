/* eslint-disable react/require-default-props */
import React, { ReactElement, ReactNode, useCallback } from 'react';
import { Modal, ModalProps } from 'antd';

import { DiscardChangesOverlay } from './ui/DiscardChangesOverlay/DiscardChangesOverlay';

interface IModalDialogProps extends Omit<ModalProps, 'children' | 'onCancel' | 'onOk' | 'confirmLoading' | 'title'> {
  body: ReactNode;
  closeFn: () => void;
  actionFn?: () => void;
  loading?: boolean;
  header?: ReactNode;

  includeDiscardChangesOverlay?: boolean;
}

// ToDo: add style to buttons

/**
 * @param visible State of modal.
 * @param closeFn Provide a function to call on cancel events (default Cancel button, 'X' button, Esc key).
 * @param actionFn Provide a function to call on default action button click.
 * @param loading Loading state of default action button.
 * @param footer The modal's footer. Set to null to remove footer.
 * @param closable 'X' button on top-right of modal. Default true.
 * @param keyboard Esc key will close modal. Default true.
 * @param maskClosable Click on content outside of modal will close modal. Default false.
 * @param width Pass modal dialog width in px
 *
 * @example <caption>with default footer</caption>
 * const [visible, setVisible] = useState(false);
 *
 * ...
 * <ModalDialog
 *  visible={visible}
 *  body={body}
 *  closeFn={() => setVisible(false)}
 *  actionFn={() => setVisible(false)}
 * />
 * <button onClick={() => setVisible(true)}>Show Modal</button>
 *
 * @example <caption>with custom footer</caption>
 * const [visible, setVisible] = useState(false);
 *
 * const myFooter = (): ReactNode[] => {
 *  return [
 *      <Button onClick={() => setVisible(false)}>Cancel</Button>
 *      <Button onClick={() => someAction()}>Action 1</Button>
 *      <Button onClick={() => someAction2()}>Action 2</Button>
 *    ];
 * }
 *
 * ...
 * <ModalDialog
 *  visible={visible}
 *  body={body}
 *  footer={myFooter}
 *  closeFn={() => setVisible(false)} // for 'x' button and ESC
 */
const ModalDialog = (props: IModalDialogProps): ReactElement => {
  const { body, closeFn, actionFn, loading, header, includeDiscardChangesOverlay, ...otherModalProps } = props;

  const [overlayVisible, setOverlayVisible] = React.useState(false);

  const onCloseWrapper = useCallback((): void => {
    if (includeDiscardChangesOverlay) {
      setOverlayVisible(true);
    } else {
      closeFn();
    }
  }, [closeFn, includeDiscardChangesOverlay]);

  const onCancelDiscard = useCallback((): void => {
    setOverlayVisible(false);
  }, []);

  const onConfirmDiscard = useCallback((): void => {
    setOverlayVisible(false);
    closeFn();
  }, [closeFn]);

  return (
    <Modal
      centered
      destroyOnClose
      title={header}
      confirmLoading={loading}
      onOk={actionFn}
      onCancel={onCloseWrapper}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherModalProps}
    >
      {body}
      <DiscardChangesOverlay onConfirm={onConfirmDiscard} onCancel={onCancelDiscard} visible={overlayVisible} />
    </Modal>
  );
};

export default ModalDialog;
