import React from 'react';
import { useParams } from 'react-router-dom';

import AssignmentCreateForm from 'components/Assignment/AssignmentCreateForm/AssignmentCreateForm';

import styles from './AssignmentCreatePage.module.scss';

const AssignmentCreatePage: React.FC = () => {
  const { accountId = '', projectId = '', positionId = '' } = useParams();

  return (
    <div className={styles.wrapper}>
      <AssignmentCreateForm accountId={accountId} projectId={projectId} positionId={positionId} />
    </div>
  );
};

export default AssignmentCreatePage;
