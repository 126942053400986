import { AxiosRequestConfig } from 'axios';

import { axiosInstance } from 'shared/lib/axios';
import {
  AssignmentDTO,
  AssignmentCreateRequest,
  AssignmentList,
  AssignmentListRequest,
  AssignmentUpdateRequest,
  AssignmentUserInvolvementReportRequest,
  UserInvolvementReportDTO,
} from './types';

export const assignmentListGetByPosition = (
  payload: AssignmentListRequest,
  config?: AxiosRequestConfig,
): Promise<AssignmentList> => {
  const { positionId, archived, size, page } = payload;
  return axiosInstance.get(`/api/assignment/byPosition/${positionId}`, {
    ...config,
    params: { archived, size, page, sort: 'startDate,DESC' },
  });
};

export const assignmentItemGet = (id: string, config?: AxiosRequestConfig): Promise<AssignmentDTO> => {
  return axiosInstance.get(`/api/assignment/${id}`, config);
};

export const assignmentItemCreate = (payload: AssignmentCreateRequest): Promise<AssignmentDTO> => {
  return axiosInstance.post('/api/assignment', payload);
};

export const assignmentItemUpdate = (payload: AssignmentUpdateRequest): Promise<AssignmentDTO> => {
  return axiosInstance.put('/api/assignment', payload);
};

export const assignmentUserInvolvementReportGet = (
  payload: AssignmentUserInvolvementReportRequest,
  config?: AxiosRequestConfig,
): Promise<UserInvolvementReportDTO> => {
  const { userId, startDate, endDate } = payload;
  return axiosInstance.get(`/api/assignment/report/involvement`, { ...config, params: { userId, startDate, endDate } });
};

export const assignmentItemArchive = (id: string): Promise<void> => {
  return axiosInstance.put(`/api/archivation/archive/assignment/${id}`);
};

export const assignmentItemRestore = (id: string): Promise<void> => {
  return axiosInstance.put(`/api/archivation/unarchive/assignment/${id}`);
};
