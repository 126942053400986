import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { DemandStatus, RecruiterDTO } from 'entities/demand';

import { ReactComponent as PencilIcon } from 'shared/assets/icons/pencil.svg';

import styles from './DemandItem.module.scss';

export interface IDemandItemProps {
  status: DemandStatus;
  expiresIn: number;
  recruiter?: RecruiterDTO;
  editLinkHref: string;
  hideEditButton?: boolean;
}

const DemandItem: React.FC<IDemandItemProps> = (props) => {
  const { status, expiresIn, recruiter, editLinkHref, hideEditButton } = props;

  const { t } = useTranslation();

  const getStatusText = (): string => {
    switch (status) {
      case 'ACTIVE':
        return t('Demand:DemandItem.status.active');
      case 'CANCELLED':
        return t('Demand:DemandItem.status.cancelled');
      case 'ON_HOLD':
        return t('Demand:DemandItem.status.onHold');
      case 'STAFFED':
        return t('Demand:DemandItem.status.staffed');
      default:
        return '';
    }
  };

  const getExpiresText = (): string => {
    if (expiresIn < 0) {
      return t('Demand:DemandItem.desirableDate.additionalText.failed');
    }

    return t('Demand:DemandItem.desirableDate.additionalText', { count: expiresIn });
  };

  const getRecruiterText = (): string => {
    const name = recruiter
      ? `${recruiter.firstName} ${recruiter.lastName}`
      : t('Demand:DemandItem.recruiter.placeholder.text');

    return t('Demand:DemandItem.recruiter.additionalText', { name });
  };

  return (
    <div className={styles.rowContainer} data-testid="demand-list-item">
      <div data-testid="status">{getStatusText()}</div>
      {status === 'ACTIVE' ? <div data-testid="desirable-date">{getExpiresText()}</div> : null}
      {status === 'ACTIVE' ? <div data-testid="recruiter">{getRecruiterText()}</div> : null}
      {!hideEditButton ? (
        <Link to={editLinkHref}>
          <button type="button" className={styles.pencilButton} data-testid="edit-button">
            <PencilIcon className={styles.pencilIcon} />
          </button>
        </Link>
      ) : (
        <div style={{ width: 36 }} />
      )}
    </div>
  );
};

export default React.memo(DemandItem);
