import { AxiosRequestConfig } from 'axios';

import { axiosInstance } from 'shared/lib/axios';
import {
  DemandCreateRequest,
  DemandDTO,
  DemandList,
  DemandListByPositionRequest,
  DemandParentInfo,
  DemandUpdateRequest,
  DemandWidgetList,
  DemandWidgetRequest,
  RecruiterDTO,
} from './types';

export const demandListGetByPosition = (
  params: DemandListByPositionRequest,
  config?: AxiosRequestConfig,
): Promise<DemandList> => {
  const { showArchived, ...restParams } = params;
  return axiosInstance.get('/api/demand', { ...config, params: { show_archived: showArchived, ...restParams } });
};

export const demandItemGet = (id: string, config?: AxiosRequestConfig): Promise<DemandDTO> => {
  return axiosInstance.get(`/api/demand/${id}`, config);
};

export const demandItemCreate = (payload: DemandCreateRequest): Promise<DemandDTO> => {
  return axiosInstance.post('/api/demand', payload);
};

export const demandItemUpdate = (payload: DemandUpdateRequest): Promise<DemandDTO> => {
  return axiosInstance.put('/api/demand', payload);
};

export const demandParentInfoGet = (positionId: string, config?: AxiosRequestConfig): Promise<DemandParentInfo> => {
  return axiosInstance.get(`/api/demand/${positionId}/parentalInfo`, config);
};

export const demandWidgetGet = (
  params: DemandWidgetRequest,
  config?: AxiosRequestConfig,
): Promise<DemandWidgetList> => {
  return axiosInstance.get(`/api/demand/widget`, { ...config, params });
};

export const assignableRecruitersGet = (config?: AxiosRequestConfig): Promise<RecruiterDTO[]> => {
  return axiosInstance.get(`/api/demand/assignableRecruiters`, config);
};

export const demandItemArchive = (id: string): Promise<void> => {
  return axiosInstance.put(`/api/archivation/archive/demand/${id}`);
};
