import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { FormProps, useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { isHasAccess } from 'features/validateAccess';
import { accountService } from 'entities/account';
import { useCreateProjectMutation } from 'entities/project';

import { pathKeys } from 'shared/lib/react-router';

import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';
import ProjectCreateUpdateFormItems from 'components/Project/ProjectCreateUpdateFormItems/ProjectCreateUpdateFormItems';

import { getAccountNameViewPermission } from '../Project.permissions';

type FormType = {
  name: string;
  description: string;
};

const ProjectCreateForm: React.FC<{ accountId: string }> = ({ accountId }) => {
  const [form] = useForm<FormType>();
  const { t } = useTranslation('Project');

  const navigate = useNavigate();

  const [preventNavigation, setPreventNavigation] = useState(false);

  const { mutate: createProject, isPending: isCreating, isError: hasErrors, error, reset } = useCreateProjectMutation();

  const canSeeAccountName = isHasAccess(getAccountNameViewPermission(accountId));

  const { data: account, isLoading: accountIsLoading } = useQuery(
    accountService.queryOptions(accountId, canSeeAccountName),
  );

  useEffect(() => {
    if (hasErrors && error?.response?.data.code === 'ItemAlreadyExistsException') {
      form.setFields([
        { name: 'name', errors: [t('ProjectCreateUpdateFormItems.name.item.validationMessage.nonUniqueName')] },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasErrors]);

  const onFormChange: FormProps<FormType>['onValuesChange'] = (_, values): void => {
    reset();
    setPreventNavigation(!!values.name || !!values.description);
  };

  const onFormSubmit = (values: FormType): void => {
    setPreventNavigation(false);

    const { name, description } = values;
    createProject({ accountId, name: name.trim(), description });
  };

  return (
    <CreateUpdateFormHolder
      title={t('CreateProjectForm.heading')}
      subtitle={account?.name}
      onFormSubmit={onFormSubmit}
      onCancel={() => navigate(pathKeys.project.root({ accountId }))}
      isLoading={isCreating}
      customFormControl={[form]}
      isLoadingData={accountIsLoading}
      onFormChange={onFormChange}
      preventNavigation={preventNavigation}
    >
      <ProjectCreateUpdateFormItems isLoading={isCreating} />
    </CreateUpdateFormHolder>
  );
};

export default ProjectCreateForm;
