import { authStore } from 'entities/session';

import { Maybe } from 'shared/types';

const settingNames = {
  shouldShowConfirmModalOnChangeAssignment: 'shouldShowConfirmModalOnChangeAssignment',
  pinnedAccessGroups: 'pinnedAccessGroups',
  showArchivedProjects: 'showArchivedProjects',
  showArchivedAccounts: 'showArchivedAccounts',
  includeExpiredPositions: 'includeExpiredPositions',
  hideNonActiveAccounts: 'hideNonActiveAccounts',
  hideNonActiveProjects: 'hideNonActiveProjects',
} as const;

type SettingName = typeof settingNames[keyof typeof settingNames];
type StoreAt = 'localStorage' | 'sessionStorage';

const LOCAL_SETTINGS_KEY = 'local-settings';

const getLocalSettings = <T = Record<string, unknown>>(from: StoreAt): Record<string, T> => {
  let storedValue: Maybe<string>;
  switch (from) {
    case 'localStorage':
      storedValue = localStorage.getItem(LOCAL_SETTINGS_KEY);
      break;
    case 'sessionStorage':
    default:
      storedValue = sessionStorage.getItem(LOCAL_SETTINGS_KEY);
      break;
  }

  return storedValue ? JSON.parse(storedValue) : {};
};

const getCurrentUserLocalSettings = (from: StoreAt): Record<string, unknown> => {
  const settings = getLocalSettings(from);
  const currentUser = authStore.getState().authUser?.id;

  if (!currentUser) {
    return {};
  }

  return settings[currentUser];
};

const updateUserLocalSettings = <T>(setting: T, storeAt: StoreAt): void => {
  const settings = getLocalSettings(storeAt);
  const currentUser = authStore.getState().authUser?.id;

  if (!currentUser) {
    return;
  }

  const newSettings = { ...settings, [currentUser]: setting };
  switch (storeAt) {
    case 'localStorage': {
      localStorage.setItem(LOCAL_SETTINGS_KEY, JSON.stringify(newSettings));
      break;
    }
    case 'sessionStorage':
    default: {
      sessionStorage.setItem(LOCAL_SETTINGS_KEY, JSON.stringify(newSettings));
      break;
    }
  }
};

const SettingService = {
  saveRemoteSetting() {
    // TODO: implement
  },
  getRemoteSetting() {
    // TODO: implement
  },

  saveLocalSetting<T>(name: SettingName, value: T, storeAt: StoreAt = 'localStorage'): void {
    const userSettings = getCurrentUserLocalSettings(storeAt);

    const newUserSettings = { ...userSettings, [name]: value };
    updateUserLocalSettings(newUserSettings, storeAt);
  },
  getLocalSetting<T>(name: SettingName, from: StoreAt = 'localStorage') {
    const userSettings = getCurrentUserLocalSettings(from);
    return userSettings?.[name] !== undefined ? (userSettings[name] as T) : null;
  },
  removeLocalSetting(name: SettingName, from: StoreAt = 'localStorage') {
    const userSettings = getCurrentUserLocalSettings(from);
    delete userSettings[name];

    updateUserLocalSettings(userSettings, from);
  },
  clearAllLocalSettings(from: StoreAt = 'localStorage') {
    updateUserLocalSettings({}, from);
  },
};

export default SettingService;
