/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, Fragment } from 'react';
import { useMutationState } from '@tanstack/react-query';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { keys } from 'entities/accessGroup';
import { ReactComponent as AddIcon } from 'shared/assets/icons/dynamicColor/add-icon.svg';

import styles from './PopUpButton.module.scss';

type PopUpButtonProps = {
  editMode?: boolean;
  onCreate?: () => void;
  onSave?: () => void;
  onCancelSave: () => void;
};
const PopUpButton: FC<PopUpButtonProps> = ({ editMode, onCreate, onSave, onCancelSave }) => {
  const { t } = useTranslation();

  const updateMutationStatus = useMutationState({
    filters: { mutationKey: keys.updatePermissions() },
    select: (mutation) => mutation.state.status,
  });
  const isMutationLoading = updateMutationStatus[updateMutationStatus.length - 1] === 'pending';

  return (
    <Fragment>
      {editMode
        ? onSave && (
            <div className={styles.buttonsBlock}>
              <Button size="large" className={styles.cancelButton} onClick={onCancelSave} data-testid="cancel-button">
                {t('AccessGroup:PopUpButton.editMode.cancelButton.text')}
              </Button>
              <Button
                type="primary"
                size="large"
                className={styles.saveButton}
                onClick={onSave}
                data-testid="save-button"
                loading={isMutationLoading}
              >
                {t('AccessGroup:PopUpButton.editMode.confirmButton.text')}
              </Button>
            </div>
          )
        : onCreate && (
            <Button
              type="primary"
              size="large"
              icon={<AddIcon width={20} height={20} className={styles.createIcon} />}
              className={styles.createButton}
              onClick={onCreate}
              data-testid="create-button"
            />
          )}
    </Fragment>
  );
};

export default PopUpButton;
