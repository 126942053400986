import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { hasToken } from 'entities/session';

import { pathKeys } from 'shared/lib/react-router';

import LoginPage from './LogInPage';

export const loginPageRoute: RouteObject = {
  path: pathKeys.login(),
  element: createElement(LoginPage),
  loader: async (args) => {
    const isUserAuthorized: boolean = hasToken();
    if (isUserAuthorized) {
      return redirect(pathKeys.dashboard());
    }

    return args;
  },
};
