import { ReactElement } from 'react';
import { isAxiosError } from 'axios';
import { Navigate, useRouteError } from 'react-router-dom';

import { hasToken } from 'entities/session';
import { pathKeys } from 'shared/lib/react-router';
import { Maybe } from 'shared/types';

// TODO: improve
export function BubbleError(): Maybe<ReactElement> {
  const error = useRouteError();

  if (isAxiosError(error)) {
    const status = error?.response?.status;
    const isAuthorized = hasToken();

    if (status === 401 && isAuthorized) {
      return <Navigate to={pathKeys.logout()} />;
    }

    if (status === 401) {
      return <Navigate to={pathKeys.login()} />;
    }

    if (status === 403) {
      return <Navigate to={pathKeys.dashboard()} />;
    }

    if (status === 404) {
      return <Navigate to={pathKeys.page404()} />;
    }
  }
  if (error) throw error;
  return null;
}
