import { queryOptions as tsqQueryOptions } from '@tanstack/react-query';

import { queryClient } from 'shared/lib/react-query';

import { getUserPermissions, permissionListGet } from './requests';
import { PermissionDTO } from './types';

export const keys = {
  root: () => ['permission'] as const,
  names: () => [...keys.root(), 'names'] as const,
  list: () => [...keys.root(), 'list'] as const,
  listByUser: (id: string) => [...keys.root(), 'list', 'byUser', id] as const,
};

export const permissionNamesService = {
  queryKey: () => keys.names(),

  getCache: () => queryClient.getQueryData<PermissionDTO[]>(permissionNamesService.queryKey()),

  queryOptions: () => {
    const permissionsKey = permissionNamesService.queryKey();
    return tsqQueryOptions({
      queryKey: permissionsKey,
      queryFn: async () => {
        const permission = await permissionListGet();
        return permission;
      },
      staleTime: 10 * 60 * 1000,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => permissionNamesService.getCache()!,
      initialDataUpdatedAt: () => queryClient.getQueryState(permissionsKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async () => {
    queryClient.prefetchQuery(permissionNamesService.queryOptions());
  },
};

export const userPermissionsService = {
  queryKey: (id: string) => keys.listByUser(id),

  getCache: (id: string) => queryClient.getQueryData<string[]>(userPermissionsService.queryKey(id)),

  queryOptions: (id: string) => {
    const permissionsKey = userPermissionsService.queryKey(id);
    return tsqQueryOptions({
      queryKey: permissionsKey,
      queryFn: async () => {
        const permissions = await getUserPermissions(id);
        return permissions;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => userPermissionsService.getCache(id)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(permissionsKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (id: string) => {
    queryClient.prefetchQuery(userPermissionsService.queryOptions(id));
  },
};
