/* eslint-disable react/require-default-props */
import { FC, ReactNode } from 'react';

import { RcPagination } from 'shared/ui/RcPagination';

import styles from './ListWithPagination.module.scss';

// TODO: add type
interface IListProps {
  items: ReactNode;
  totalItems: number;
  page: number;
  setPage: (arg: number) => void;
  pageSize?: number;
}

const ListWithPagination: FC<IListProps> = ({ items, totalItems, pageSize = 10, page, setPage }) => {
  return (
    <div>
      <ul className={styles.list} data-testid="data-list">
        {items}
      </ul>
      <div className={styles.pagination}>
        <RcPagination pageSize={pageSize} setCurrentPage={setPage} dataSize={totalItems} current={page} />
      </div>
    </div>
  );
};

export default ListWithPagination;
