import React from 'react';
import { Form, Input } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { useTranslation } from 'react-i18next';

import {
  DEFAULT_PROJECT_NAME_MAX_LENGTH,
  DEFAULT_PROJECT_NAME_MIN_LENGTH,
  DEFAULT_PROJECT_DESCRIPTION_MAX_LENGTH,
} from '../Project.consts';

import styles from './ProjectCreateUpdateFormItems.module.scss';

const ProjectCreateUpdateFormItems: React.FC<{
  isLoading: boolean;
}> = ({ isLoading }) => {
  const { t } = useTranslation('Project');

  const inputSpaceValidator = (_: RuleObject, value: StoreValue): Promise<void> => {
    return value.trim().length ? Promise.resolve() : Promise.reject();
  };

  return (
    <div className={styles.formItemsWrapper}>
      <Form.Item
        label={t('ProjectCreateUpdateFormItems.name.item.label')}
        rules={[
          {
            validator: inputSpaceValidator,
            message: t('ProjectCreateUpdateFormItems.name.item.validationMessage.required'),
          },
          {
            min: DEFAULT_PROJECT_NAME_MIN_LENGTH,
            max: DEFAULT_PROJECT_NAME_MAX_LENGTH,
            message: t('ProjectCreateUpdateFormItems.name.item.validationMessage.nameLength'),
          },
        ]}
        className={styles.nameInput}
        name="name"
        data-testid="form-name-item"
        normalize={(value: string) => value.trimStart()}
      >
        <Input
          disabled={isLoading}
          placeholder={t('ProjectCreateUpdateFormItems.name.item.placeholder.text')}
          data-testid="name-input"
        />
      </Form.Item>
      <Form.Item
        label={t('ProjectCreateUpdateFormItems.description.item.label')}
        rules={[
          {
            required: true,
            message: t('ProjectCreateUpdateFormItems.description.item.validationMessage.required'),
          },
          {
            max: DEFAULT_PROJECT_DESCRIPTION_MAX_LENGTH,
            message: t('ProjectCreateUpdateFormItems.description.item.validationMessage.descriptionLength'),
          },
        ]}
        name="description"
        data-testid="form-description-item"
        className={styles.descriptionInput}
      >
        <Input.TextArea
          disabled={isLoading}
          placeholder={t('ProjectCreateUpdateFormItems.description.item.placeholder.text')}
          autoSize={{ maxRows: 5, minRows: 5 }}
          data-testid="description-input"
        />
      </Form.Item>
    </div>
  );
};

export default ProjectCreateUpdateFormItems;
