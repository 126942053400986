import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { queryClient } from 'shared/lib/react-query';

import {
  authenticatedUserInfoGet,
  availableUserListGetByQuery,
  localMappedUsersGet,
  userItemGetById,
  userListGetByAccessGroupId,
  userListGetByAccessGroupIdWithCount,
  userListGetByQuery,
} from './requests';
import {
  AGWithUsersDTO,
  AGWithUsersRequest,
  AvailableUserListRequest,
  LocalMappedUsersRequest,
  MappedUserDTO,
  PageUserSearchDTO,
  PageUsersAvailabilityDTO,
  SearchDTO,
  UserDTO,
  UserListByAGIdWithCountRequest,
  UserListRequest,
} from './types';

export const keys = {
  root: 'user',
  accessGroupUsers: () => [keys.root, 'listByAccessGroupId'] as const,
  accessGroupUsersWithFilters: (filters: AGWithUsersRequest) => [...keys.accessGroupUsers(), filters],
  accessGroupUsersWithCount: () => [keys.root, 'listByAccessGroupIdWithCount'] as const,
  list: () => [keys.root, 'list'],
  listWithParams: (params: UserListRequest) => [...keys.list(), params],
  availableUsers: () => [keys.root, 'availableList'] as const,
  availableUsersByFilters: (filters: AvailableUserListRequest) => [...keys.availableUsers(), filters],
  item: (id: string) => [keys.root, 'item', id],
  authInfo: () => [keys.root, 'authInfo'],
  localMappedUsers: () => [keys.root, 'localMappedList'] as const,
  localMappedUsersByFilters: (filters: LocalMappedUsersRequest) => [...keys.localMappedUsers(), filters],
};

export const accessGroupUsersService = {
  queryKey: (filters: AGWithUsersRequest) => keys.accessGroupUsersWithFilters(filters),

  getCache: (filters: AGWithUsersRequest) =>
    queryClient.getQueryData<SearchDTO>(accessGroupUsersService.queryKey(filters)),

  queryOptions: (filters: AGWithUsersRequest, enabled = true) => {
    const accessGroupUsersWithCountKey = accessGroupUsersService.queryKey(filters);
    return queryOptions({
      queryKey: accessGroupUsersWithCountKey,
      queryFn: async ({ signal }) => {
        const users = await userListGetByAccessGroupId(filters, { signal });
        return users;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => accessGroupUsersService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(accessGroupUsersWithCountKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
      enabled,
    });
  },

  prefetchQuery: async (filters: AGWithUsersRequest) =>
    queryClient.prefetchQuery(accessGroupUsersService.queryOptions(filters)),
};

export const accessGroupUsersWithCountService = {
  queryKey: () => keys.accessGroupUsersWithCount(),

  getCache: () => queryClient.getQueryData<Record<string, AGWithUsersDTO>>(accessGroupUsersWithCountService.queryKey()),

  queryOptions: (filters: UserListByAGIdWithCountRequest) => {
    const accessGroupUsersWithCountKey = accessGroupUsersWithCountService.queryKey();
    return queryOptions({
      queryKey: accessGroupUsersWithCountKey,
      queryFn: async ({ signal }) => {
        const users = await userListGetByAccessGroupIdWithCount(filters, { signal });

        const result: Record<string, AGWithUsersDTO> = {};
        users.forEach((group) => {
          result[group.accessGroupId] = group;
        });

        return result;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => accessGroupUsersWithCountService.getCache()!,
      initialDataUpdatedAt: () => queryClient.getQueryState(accessGroupUsersWithCountKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
      enabled: filters.arrayOfGroups.length > 0,
    });
  },

  prefetchQuery: async (filters: UserListByAGIdWithCountRequest) =>
    queryClient.prefetchQuery(accessGroupUsersWithCountService.queryOptions(filters)),
};

export const usersService = {
  queryKey: (filters: UserListRequest) => keys.listWithParams(filters),

  getCache: (filters: UserListRequest) => queryClient.getQueryData<PageUserSearchDTO>(usersService.queryKey(filters)),

  queryOptions: (filters: UserListRequest) => {
    const usersKey = usersService.queryKey(filters);
    return queryOptions({
      queryKey: usersKey,
      queryFn: async ({ signal }) => {
        const users = await userListGetByQuery(filters, { signal });
        return users;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => usersService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(usersKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
    });
  },

  prefetchQuery: async (filters: UserListRequest) => queryClient.prefetchQuery(usersService.queryOptions(filters)),
};

export const availableUsersService = {
  queryKey: (filters: AvailableUserListRequest) => keys.availableUsersByFilters(filters),

  getCache: (filters: AvailableUserListRequest) =>
    queryClient.getQueryData<PageUsersAvailabilityDTO>(availableUsersService.queryKey(filters)),

  queryOptions: (filters: AvailableUserListRequest, enabled = true) => {
    const availableListKey = availableUsersService.queryKey(filters);
    return queryOptions({
      queryKey: availableListKey,
      queryFn: async ({ signal }) => {
        const users = await availableUserListGetByQuery(filters, { signal });
        return users;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => availableUsersService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(availableListKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
      enabled,
    });
  },

  prefetchQuery: async (filters: AvailableUserListRequest) =>
    queryClient.prefetchQuery(availableUsersService.queryOptions(filters)),
};

export const userService = {
  queryKey: (id: string) => keys.item(id),

  getCache: (id: string) => queryClient.getQueryData<UserDTO>(userService.queryKey(id)),

  setCache: (user: UserDTO) => queryClient.setQueryData(userService.queryKey(user.id), user),

  removeCache: (id: string) => queryClient.removeQueries({ queryKey: userService.queryKey(id) }),

  queryOptions: (id: string, enabled = true) => {
    const userKey = userService.queryKey(id);
    return queryOptions({
      queryKey: userKey,
      queryFn: async ({ signal }) => {
        const user = await userItemGetById(id, { signal });
        return user;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => userService.getCache(id)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(userKey)?.dataUpdatedAt,
      enabled,
    });
  },

  prefetchQuery: async (id: string) => queryClient.prefetchQuery(userService.queryOptions(id)),
};

export const authUserService = {
  queryKey: () => keys.authInfo(),

  getCache: () => queryClient.getQueryData<UserDTO>(authUserService.queryKey()),

  setCache: (user: UserDTO) => queryClient.setQueryData(authUserService.queryKey(), user),

  removeCache: () => queryClient.removeQueries({ queryKey: authUserService.queryKey() }),

  queryOptions: () => {
    const authUserKey = authUserService.queryKey();
    return queryOptions({
      queryKey: authUserKey,
      queryFn: async ({ signal }) => {
        const authUserInfo = await authenticatedUserInfoGet({ signal });
        return authUserInfo;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => authUserService.getCache()!,
      initialDataUpdatedAt: () => queryClient.getQueryState(authUserKey)?.dataUpdatedAt,
      refetchInterval: 10 * 60 * 1000,
    });
  },

  prefetchQuery: async () => queryClient.prefetchQuery(authUserService.queryOptions()),
};

export const localMappedUsersService = {
  queryKey: (filters: LocalMappedUsersRequest) => keys.localMappedUsersByFilters(filters),

  getCache: (filters: LocalMappedUsersRequest) =>
    queryClient.getQueryData<MappedUserDTO[]>(localMappedUsersService.queryKey(filters)),

  queryOptions: (filters: LocalMappedUsersRequest, enabled = true) => {
    const localMappedUsersKey = localMappedUsersService.queryKey(filters);
    return queryOptions({
      queryKey: localMappedUsersKey,
      queryFn: async ({ signal }) => {
        const users = await localMappedUsersGet(filters, { signal });
        return users;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => localMappedUsersService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(localMappedUsersKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
      enabled,
    });
  },

  prefetchQuery: async (filters: LocalMappedUsersRequest) =>
    queryClient.prefetchQuery(localMappedUsersService.queryOptions(filters)),
};
