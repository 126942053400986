import { pathKeys } from 'shared/lib/react-router';

export const DEFAULT_PATHS_MAP = {
  authorized: {
    defaultRedirectPath: pathKeys.dashboard(),
  },
  unauthorized: {
    defaultRedirectPath: pathKeys.login(),
  },
  default: {
    // TODO: do we still need it?
    defaultRedirectPath: pathKeys.dashboard(),
  },
};
