import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { useForm } from 'antd/lib/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ArchiveEntityModal } from 'features/archiveEntity';
import { isHasAccess } from 'features/validateAccess';
import { positionService, useArchivePositionMutation, useUpdatePositionMutation } from 'entities/position';
import { projectService } from 'entities/project';
import { DEFAULT_DATE_FORMAT_FOR_REQUESTS } from 'shared/config';
import { convertDayJsToString } from 'shared/lib/dayjs';
import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';
import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';
// eslint-disable-next-line max-len
import PositionCreateUpdateFormItems from 'components/PositionAndAssignment/PositionCreateUpdateFormItems/PositionCreateUpdateFormItems';
import RateList from 'components/RateList/RateList';

// eslint-disable-next-line max-len

import { getProjectNameViewPermission, getPositionArchivePermission } from '../Position.permissions';
import { useInitData } from './hooks/useInitData';

import styles from './PositionUpdateForm.module.scss';

export type PositionFormType = {
  name: string;
  description: string;
  startDate: Dayjs;
  endDate?: Dayjs;
  rates: {
    currency: string;
    value: string;
    startDate: Dayjs;
    endDate?: Dayjs;
  }[];
};

const PositionUpdateForm: React.FC<{ accountId: string; projectId: string }> = ({ accountId, projectId }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [form] = useForm<PositionFormType>();

  const [visibleArchiveDialog, setVisibleArchiveDialog] = useState(false);
  const [preventNavigation, setPreventNavigation] = useState(false);

  const { positionId = '' } = useParams();

  const { data: position, isPending: isPositionLoading } = useSuspenseQuery(positionService.queryOptions(positionId));

  const {
    mutate: updateMutation,
    isPending: isUpdating,
    isError: hasUpdatingErrors,
    error: updatingErrors,
  } = useUpdatePositionMutation(positionId, accountId, projectId);

  const {
    mutate: archiveMutation,
    isPending: isArchiving,
    isError: hasArchivingErrors,
    error: archivingErrors,
    reset: resetArchiveMutation,
  } = useArchivePositionMutation(positionId, accountId, projectId);

  const initData = useInitData(position);

  const canSeeProjectName = isHasAccess(getProjectNameViewPermission(accountId, projectId));
  const canArchivePosition = isHasAccess(getPositionArchivePermission(accountId, projectId));

  const { data: project } = useQuery(projectService.queryOptions(projectId, canSeeProjectName));

  const checkFormValuesModified = useCallback((): void => {
    const values = form.getFieldsValue();
    const initialData = initData;

    const newRates = values.rates;

    const isSameName = initialData.name === values.name;
    const isSameDescription = initialData.description === values.description;
    const isSameStartDate = initialData.startDate?.isSame(values.startDate, 'day');
    const isSameEndDate =
      dayjs.isDayjs(values.endDate) && dayjs.isDayjs(initialData.endDate)
        ? initialData.endDate.isSame(values.endDate, 'day')
        : values.endDate === initialData.endDate;
    const isSameDates = isSameEndDate && isSameStartDate;

    if (newRates && typeof newRates[newRates.length - 1].value === 'undefined') {
      newRates.pop();
    }

    const isSameRates =
      newRates &&
      newRates?.length === initialData.rates?.length &&
      newRates.reduce((isSame, newRate, index) => {
        const oldRate = initialData.rates[index];

        const isSameValue = oldRate.value === newRate.value;
        const isSameCurrency = oldRate.currency === newRate.currency;
        const isSameRateStartDate = oldRate.startDate.isSame(newRate.startDate, 'day');

        return isSame && isSameValue && isSameCurrency && (index === 0 || isSameRateStartDate);
      }, true);

    setPreventNavigation(!(isSameName && isSameDates && isSameRates && isSameDescription));
  }, [form, initData]);

  useEffect(() => {
    if (form.isFieldsTouched() && (hasUpdatingErrors || hasArchivingErrors)) {
      checkFormValuesModified();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdatingErrors, hasArchivingErrors]);

  const closeModal = (): void => {
    setVisibleArchiveDialog(false);
    resetArchiveMutation();
  };

  const archivePosition = (): void => {
    setPreventNavigation(false);
    archiveMutation(positionId);
  };

  const onFormSubmit = (values: PositionFormType): void => {
    const { name, description, startDate, endDate, rates } = values;
    const positionStartDate = convertDayJsToString(startDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS);
    const positionEndDate = endDate ? convertDayJsToString(endDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS) : null;
    const { id, version } = position;
    setPreventNavigation(false);

    // TODO: refactor
    const rateList = rates.map((item, index) => {
      return {
        index,
        value: Number(item.value),
        currency: item.currency,
        startDate: convertDayJsToString(item.startDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS),
        endDate: item.endDate ? convertDayJsToString(item.endDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS) : null,
      };
    });

    updateMutation({
      id,
      name: name.trim(),
      description,
      startDate: positionStartDate,
      endDate: positionEndDate,
      rates: rateList,
      version,
    });
  };

  return (
    <CreateUpdateFormHolder
      title={t('Position:PositionUpdateForm.heading')}
      onCancel={() => navigate(pathKeys.position.root({ accountId, projectId }))}
      subtitle={project?.name}
      onFormSubmit={onFormSubmit}
      isLoading={isUpdating}
      isLoadingData={isPositionLoading}
      editMode
      onClickArchiveButton={() => setVisibleArchiveDialog(true)}
      initialData={initData}
      customFormControl={[form]}
      onFormChange={checkFormValuesModified}
      preventNavigation={preventNavigation}
      hideDeleteBtn={!canArchivePosition}
    >
      <div className={styles.rootWrapper}>
        <div className={styles.formItems}>
          <PositionCreateUpdateFormItems
            isLoading={isUpdating}
            isDatesIntersected={
              hasUpdatingErrors && updatingErrors?.response?.data.code === 'DateRangeIntersectionException'
            }
          />
        </div>
        <div className={styles.rateWrapper}>
          <RateList />
        </div>
      </div>
      <ArchiveEntityModal
        open={visibleArchiveDialog}
        type={
          hasArchivingErrors && archivingErrors?.response?.data.code === 'PositionHasUnarchivedAssignmentException'
            ? 'failed'
            : 'normal'
        }
        isLoading={isArchiving}
        archiveEntity={archivePosition}
        closeModal={closeModal}
        normalText={t('Position:PositionUpdateForm.ArchiveEntityModal.normal.body.text')}
        failedText={t('Position:PositionUpdateForm.ArchiveEntityModal.withAssignmentIn.body.text')}
      />
    </CreateUpdateFormHolder>
  );
};

export default withSuspense(PositionUpdateForm, { fallback: <Loader /> });
