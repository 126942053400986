import { ReactElement } from 'react';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStore } from 'zustand';

import { PAGE_SIZE } from 'features/UserTable';

import { userOverviewService, userOverviewStore } from 'entities/userOverview';

import { withSuspense } from 'shared/lib/react';
import styles from './Heading.module.scss';

const Heading = (): ReactElement => {
  const { t } = useTranslation();

  const { filters: filtration, searchQuery } = useStore(userOverviewStore);

  const { data: usersData } = useSuspenseInfiniteQuery(
    userOverviewService.queryOptions({ filtration, size: PAGE_SIZE, searchQuery }),
  );

  const getUserCountTextTextId = (): string => {
    if (filtration.availableUsers || filtration.filters.length !== 0) {
      return 'UserOverview:UserOverview.usersCount.withFilters.text';
    }
    return 'UserOverview:UserOverview.usersCount.withoutFilters.text';
  };

  return (
    <div className={styles.usersCount}>
      {t(getUserCountTextTextId(), { totalCount: usersData?.pages?.[0].totalElements })}
    </div>
  );
};

export default withSuspense(Heading, {
  fallback: <Skeleton className={styles.usersCountSkeleton} active paragraph={{ rows: 0 }} />,
});
