import { FC } from 'react';
import { MenuProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { ISOLanguageCodes } from 'shared/lib/i18n';
import { CustomDropdown, TriggerButtonColorMode } from 'shared/ui/CustomDropdown';

import LanguageService from '../../lib/LanguageService';

import styles from './LanguageSelector.module.scss';

export interface ILanguageSelectorProps {
  triggerButtonColorMode?: TriggerButtonColorMode; // TODO: REPLACE-FROM-NEW-PALETTE
}

const LanguageSelector: FC<ILanguageSelectorProps> = ({ triggerButtonColorMode = TriggerButtonColorMode.Dark }) => {
  const { t } = useTranslation();

  const onLanguageChange: MenuProps['onClick'] = (e): void => {
    LanguageService.changeLanguage(e.key as ISOLanguageCodes);
  };

  const dropdownItems = [
    {
      key: ISOLanguageCodes.ENGLISH,
      label: t('UI:LanguageSelector.option.english'),
      onClick: onLanguageChange,
      disabled: LanguageService.isCurrentLanguageIsEnglish(),
      isSelected: LanguageService.isCurrentLanguageIsEnglish(),
    },
    {
      key: ISOLanguageCodes.RUSSIAN,
      label: t('UI:LanguageSelector.option.russian'),
      onClick: onLanguageChange,
      disabled: LanguageService.isCurrentLanguageIsRussian(),
      isSelected: LanguageService.isCurrentLanguageIsRussian(),
    },
  ];

  let triggerButtonLabel = '';

  switch (LanguageService.getCurrentLanguage()) {
    case ISOLanguageCodes.ENGLISH:
      triggerButtonLabel = t('UI:LanguageSelector.option.english');
      break;
    case ISOLanguageCodes.RUSSIAN:
      triggerButtonLabel = t('UI:LanguageSelector.option.russian');
      break;
    default:
      triggerButtonLabel = 'Error';
      break;
  }

  return (
    <div className={styles.mainWrapper}>
      <CustomDropdown
        triggerButtonLabel={triggerButtonLabel}
        triggerButtonColorMode={triggerButtonColorMode} // TODO: REPLACE-FROM-NEW-PALETTE
        items={dropdownItems}
      />
    </div>
  );
};

export default LanguageSelector;
