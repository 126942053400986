import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const getLocalMappingAccountPermissions = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCOUNT,
      value: AvailablePermissionsValue.MAP,
    },
    {
      type: 'local',
      global: AvailableGlobalPermissions.ACCOUNT,
      local: accountId,
      value: AvailablePermissionsValue.MAP,
    },
  ],
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCESS_GROUP,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const GLOBAL_EDIT_ACCOUNT_PERMISSION: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCOUNT,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const getLocalEditAccountPermission = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'local',
      local: accountId,
      global: AvailableGlobalPermissions.ACCOUNT,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const GLOBAL_PROJECT_READ_PERMISSION: RequiredPermissions = [
  [
    {
      type: 'global',
      value: AvailablePermissionsValue.READ,
      global: AvailableGlobalPermissions.PROJECT,
    },
  ],
];

export const getLocalReadProjectPermission = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getArchiveAccountPermission = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCOUNT,
      value: AvailablePermissionsValue.ARCHIVE,
    },
    {
      type: 'local',
      global: AvailableGlobalPermissions.ACCOUNT,
      local: accountId,
      value: AvailablePermissionsValue.ARCHIVE,
    },
  ],
];
