import { ReactElement } from 'react';
import { createBrowserRouter, redirect, RouterProvider as ReactRouterBase } from 'react-router-dom';

import { accessGroupsManagementPageRoute } from 'pages/AccessGroupsManagementPage';
import { accountsPageRoute } from 'pages/AccountsPage';
import { dashboardPageRoute } from 'pages/DashboardPage';
import { externalWarningRoute } from 'pages/externalWarningPage';
import { loginPageRoute } from 'pages/LogInPage';
import { logOutPageRoute } from 'pages/LogOutPage';
import { notFoundPageRoute } from 'pages/NotFoundPage';
import { positionsPageRoute } from 'pages/PositionsPage';
import { projectsPageRoute } from 'pages/ProjectsPage';
import { userOverviewPageRoute } from 'pages/UserOverviewPage';

import { Header } from 'widgets/Header';
import { hasToken, getDefaultRedirectPath } from 'entities/session';

import { CleanPageLayout, PageWithHeaderLayout } from 'shared/layouts/topLevelLayouts';
import { pathKeys } from 'shared/lib/react-router';

import { BubbleError } from '../../ui/BubbleError';
import { RootPage } from '../../ui/RootPage';

export const appRouter = createBrowserRouter([
  {
    errorElement: <BubbleError />,
    element: <RootPage />,
    children: [
      // Public routes
      {
        element: <CleanPageLayout />,
        children: [loginPageRoute, notFoundPageRoute, logOutPageRoute],
      },
      // Protected routes
      {
        element: <PageWithHeaderLayout header={<Header />} />,
        loader: async (args) => {
          if (!hasToken()) {
            return redirect(pathKeys.login());
          }

          return args;
        },
        children: [
          dashboardPageRoute,
          accessGroupsManagementPageRoute,
          userOverviewPageRoute,
          accountsPageRoute,
          projectsPageRoute,
          positionsPageRoute,
          externalWarningRoute,
        ],
      },
      {
        path: '/',
        loader: async () => redirect(getDefaultRedirectPath()),
      },
      {
        path: '*',
        loader: async () => redirect(pathKeys.page404()),
      },
    ],
  },
]);

export const RouterProvider = (): ReactElement => {
  return <ReactRouterBase router={appRouter} />;
};
