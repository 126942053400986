import React, { ReactElement } from 'react';

import { LanguageSelector } from 'features/changeLanguage';

import { getDefaultRedirectPath } from 'entities/session';
import { TriggerButtonColorMode } from 'shared/ui/CustomDropdown';
import { Logo } from 'shared/ui/Logo';

import MainNav from './ui/MainNav/MainNav';
import NavbarMenu from './ui/NavbarMenu/NavbarMenu';
import UserMenu from './ui/UserMenu/UserMenu';

import styles from './Header.module.scss';

function Header(): ReactElement {
  return (
    <header className={styles.header} data-testid="header">
      <div className={styles.logoHolder}>
        <Logo href={getDefaultRedirectPath()} />
      </div>
      <div className={styles.navHolder}>
        <MainNav />
      </div>
      <div className={styles.rightSectionHolder}>
        <div className={styles.languageSelectorHolder}>
          <LanguageSelector triggerButtonColorMode={TriggerButtonColorMode.Light} />
        </div>
        <div className={styles.userMenuHolder}>
          <UserMenu />
        </div>
      </div>
      <div className={styles.navbarMenuHolder}>
        <NavbarMenu />
      </div>
    </header>
  );
}

export default Header;
