import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLogoutMutation } from 'entities/session';

function LogOutPage(): null {
  const location = useLocation();

  const { mutate: logOutMutation } = useLogoutMutation(location.state?.returnToPath);

  useEffect(() => {
    logOutMutation();
  }, [logOutMutation]);

  return null;
}

export default LogOutPage;
