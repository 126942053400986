import React from 'react';
import { useParams } from 'react-router-dom';

import PositionUpdateForm from 'components/PositionAndAssignment/PositionUpdateForm/PositionUpdateForm';

import styles from './PositionUpdatePage.module.scss';

const PositionUpdatePage: React.FC = () => {
  const { accountId = '', projectId = '' } = useParams();

  return (
    <div className={styles.wrapper}>
      <PositionUpdateForm accountId={accountId} projectId={projectId} data-testid="position-update-form" />
    </div>
  );
};

export default PositionUpdatePage;
