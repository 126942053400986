import { OverrideToken } from 'antd/es/theme/interface';

import variables from '../UIConfigProvider.module.scss';

export const Tabs: OverrideToken['Tabs'] = {
  // colorPrimaryTextActive: 'red',
  itemActiveColor: '#111111',
  itemHoverColor: variables.clrPaletteBrand,
  itemSelectedColor: variables.clrPaletteBrand,
  inkBarColor: variables.clrPaletteBrand,
};
