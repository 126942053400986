import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const getCreatePositionPermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.CREATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.CREATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.CREATE,
    },
  ],
];

export const CAN_SEE_ALL_ACCOUNTS: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCOUNT,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getAccountNameViewPermission = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'local',
      global: AvailableGlobalPermissions.ACCOUNT,
      local: accountId,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getProjectListViewPermission = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getProjectNameViewPermission = (projectId: string): RequiredPermissions => [
  [
    {
      type: 'local',
      global: AvailableGlobalPermissions.PROJECT,
      local: projectId,
      value: AvailablePermissionsValue.READ,
    },
  ],
];
