import { useMemo } from 'react';
import { SettingService } from 'features/userSettings';
import { AccessGroupDTO } from 'entities/accessGroup';

type UsePinnedColumnsReturnType = {
  pinnedColumns: string[];
};

export const usePinnedColumns = (accessGroupListData: AccessGroupDTO[]): UsePinnedColumnsReturnType => {
  const pinnedColumns = useMemo(() => {
    const savedPinnedColumns = SettingService.getLocalSetting<string[]>('pinnedAccessGroups');
    if (savedPinnedColumns === null) {
      return [];
    }

    const updatedPinnedColumns = accessGroupListData
      .filter((obj1) => savedPinnedColumns.includes(obj1.id))
      .map((obj1) => obj1.id);

    if (savedPinnedColumns.length !== updatedPinnedColumns.length && accessGroupListData.length) {
      SettingService.saveLocalSetting('pinnedAccessGroups', updatedPinnedColumns);
    }
    return updatedPinnedColumns;
  }, [accessGroupListData]);

  return {
    pinnedColumns,
  };
};
