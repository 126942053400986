import React from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';

import { isHasAccess } from 'features/validateAccess';
import { demandsService } from 'entities/demand';

import { DEFAULT_DATE_FORMAT_FOR_REQUESTS } from 'shared/config';
import { pathKeys } from 'shared/lib/react-router';

import { DEFAULT_SORTING, PAGE_SIZE } from '../Demand.consts';
import { getDemandGlobalEditPermissions, getDemandLocalEditPermissions } from '../Demand.permissions';
import DemandItem from './components/DemandItem/DemandItem';

import styles from './DemandList.module.scss';

interface IDemandListProps {
  positionId: string;
  accountId: string;
  projectId: string;
}

const DemandList: React.FC<IDemandListProps> = ({ positionId, accountId, projectId }) => {
  const canEditAllDemands = isHasAccess(getDemandGlobalEditPermissions(accountId, projectId));

  const { data: demandListData } = useQuery(
    demandsService.queryOptions({ positionId, size: PAGE_SIZE, page: 0, sort: DEFAULT_SORTING }),
  );

  const renderDemandList = (): React.ReactNode => {
    const demandList = demandListData?.content;

    if (!demandList) return null;

    return demandList?.map((demand) => {
      const now = dayjs();
      const desirableDate = dayjs(demand.desirableDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS).endOf('day');

      const expiresIn = desirableDate.diff(now, 'day');
      const canEditDemand = canEditAllDemands || isHasAccess(getDemandLocalEditPermissions(demand.id));
      return (
        <DemandItem
          key={demand.id}
          status={demand.status}
          expiresIn={expiresIn}
          recruiter={demand.recruiter ? demand.recruiter : undefined}
          editLinkHref={pathKeys.demand.update({ accountId, projectId, positionId, demandId: demand.id })}
          hideEditButton={!canEditDemand}
        />
      );
    });
  };

  return (
    <div id={positionId} data-testid="demand-list" className={styles.rootWrapper}>
      {renderDemandList()}
    </div>
  );
};

export default React.memo(DemandList);
