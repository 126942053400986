import React from 'react';
import { Breadcrumb } from 'antd';
import { ItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';

import styles from './Breadcrumb.module.scss';

export interface IBreadcrumbItem {
  label: string;
  path?: string;
  disable?: boolean;
  hide?: boolean;
}

interface IBreadcrumbProps {
  items: IBreadcrumbItem[];
}

const BreadCrumb: React.FC<IBreadcrumbProps> = ({ items: rawItems }) => {
  const items: ItemType[] = rawItems.reduce<ItemType[]>((acc, item: IBreadcrumbItem) => {
    if (!item.hide) {
      acc.push({
        key: item.label,
        title:
          !item.disable && item.path ? (
            <Link className={styles.breadcrumbLink} to={item.path}>
              {item.label}
            </Link>
          ) : (
            <span className={styles.breadcrumbText}>{item.label}</span>
          ),
      });
    }

    return acc;
  }, []);

  return <Breadcrumb items={items} className={styles.breadcrumb} />;
};

export default BreadCrumb;
