import React, { ReactElement, useState } from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import LogInWithGoogle from './LogInWithGoogle';

function LogInWithGoogleWrapped({ isDisabled }: { isDisabled: boolean }): ReactElement {
  const [isButtonLoading, setIsButtonLoading] = useState(true);

  return (
    <GoogleOAuthProvider
      onScriptLoadSuccess={() => setIsButtonLoading(false)}
      clientId={process.env.REACT_APP_CLIENT_ID || ''}
    >
      <LogInWithGoogle isDisabled={isDisabled} isLoading={isButtonLoading} />
    </GoogleOAuthProvider>
  );
}

export default LogInWithGoogleWrapped;
