import React, { useEffect, useState } from 'react';
import { UploadFile } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import ImgCrop, { ImgCropProps } from 'antd-img-crop';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import { ReactComponent as EmptyIcon } from 'shared/assets/icons/dynamicColor/photo-off.svg';

import { Uploader, IUploaderProps } from 'shared/ui/Uploader';

import styles from './ImageUploader.module.scss';

interface IImageUploaderProps extends Pick<IUploaderProps, 'accept' | 'sizeLimit' | 'onLoading' | 'onInvalidFile'> {
  onChange?: (image: UploadFile[]) => void;
  onLoadError?: (error: DOMException) => void;
  listType?: 'picture-card' | 'picture' | 'picture-circle';
  cropImage?: 'rect' | 'round';
  imageCount?: number;
  modalTitle?: string;
  initialImages?: UploadFile[];
  disabled?: boolean;
}

const ImageUploader: React.FC<IImageUploaderProps> = (props) => {
  const {
    listType = 'picture',
    cropImage = 'round',
    imageCount = 1,
    onInvalidFile,
    onLoadError,
    onChange,
    modalTitle,
    accept,
    sizeLimit,
    onLoading,
    initialImages,
    disabled = false,
  } = props;

  const [files, setFiles] = useState<UploadFile[]>([]);

  useEffect(() => {
    setFiles(initialImages ?? []);
  }, [initialImages]);

  const _onChange: IUploaderProps['onChange'] = (image): void => {
    let newFiles: UploadFile[] | null = null;
    if (image.status === 'error' && typeof onLoadError !== 'undefined') {
      onLoadError(image.error);
      return;
    }

    if (image.status === 'removed') {
      newFiles = files.filter((file) => file.uid !== image.uid);
    }

    if (newFiles !== null) {
      setFiles(newFiles);

      if (onChange) onChange(newFiles);
    }
  };

  const beforeUpload: UploadProps['beforeUpload'] = (file) => {
    const isFormatAccepted = accept ? accept.includes(file.type) : true;
    const isSizeAccepted = sizeLimit ? file.size <= sizeLimit : true;

    if (typeof onInvalidFile !== 'undefined') {
      if (!isFormatAccepted) {
        onInvalidFile('format-error');
      }
      if (!isSizeAccepted) {
        onInvalidFile('size-limit');
      }
    }

    return isFormatAccepted && isSizeAccepted;
  };

  const onOk: ImgCropProps['onModalOk'] = (_file): void => {
    const file = _file as RcFile;
    const a = new FileReader();
    a.onload = function () {
      const uid = uuidv4();
      const newFiles = [
        ...files,
        { uid, name: file.name, url: a.result as string, status: 'success' as UploadFile['status'] },
      ];

      setFiles(newFiles);

      if (onChange) {
        onChange(newFiles);
      }
    };

    a.readAsDataURL(file as RcFile);
  };

  return (
    <ImgCrop
      modalTitle={modalTitle || ''}
      onModalOk={onOk}
      cropShape={cropImage}
      minZoom={1 / 4}
      cropperProps={{
        objectFit: 'contain',
        zoomSpeed: 0.1,
        style: {},
        restrictPosition: false,
        mediaProps: {},
      }}
      beforeCrop={beforeUpload}
    >
      <Uploader
        className={classNames({ [styles.imageUploader]: listType === 'picture-circle' })}
        listType={listType === 'picture-circle' ? 'picture-card' : listType}
        onChange={_onChange}
        onInvalidFile={onInvalidFile}
        fileList={files}
        showUploadList={{ showPreviewIcon: false }}
        maxCount={imageCount}
        accept={accept}
        sizeLimit={sizeLimit}
        onLoading={onLoading}
        disabled={disabled}
      >
        {files.length < imageCount && <EmptyIcon height="40%" width="40%" opacity={0.5} />}
      </Uploader>
    </ImgCrop>
  );
};

export default ImageUploader;
