import React, { useEffect, useState } from 'react';
import { DatePicker, DatePickerProps, Form, Input, Select, Space } from 'antd';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { SelectUserForDemandModal } from 'features/selectUserForDemand';
import { Recruiter } from 'entities/demand';

import { DEFAULT_DATE_FORMAT_UI } from 'shared/config';
import calendarStyles from 'shared/styles/ant-custom-components/calendar.module.scss';
import { Maybe } from 'shared/types';

import { CREATION_DATE_FORMAT } from 'components/Demand/Demand.consts';

import styles from './DemandCreateUpdateFormItems.module.scss';

interface IDemandCreateUpdateFormItemsProps {
  isLoading: boolean;
  creationDate: Dayjs;
  positionStartDate: Dayjs;
  positionEndDate: Maybe<Dayjs>;
  accountDescription: string;
  projectDescription: string;
  positionDescription: string;
  hideRecruiter?: boolean;
  disableRecruiterChanging?: boolean;
}

const DemandCreateUpdateFormItems: React.FC<IDemandCreateUpdateFormItemsProps> = ({
  isLoading,
  creationDate,
  positionStartDate,
  positionEndDate,
  accountDescription,
  projectDescription,
  positionDescription,
  hideRecruiter = false,
  disableRecruiterChanging = true,
}) => {
  const { t } = useTranslation();
  const formControl = Form.useFormInstance();
  const recruiter = Form.useWatch('recruiter', formControl);

  const [visible, setVisible] = useState(false);
  const [recruiterName, setRecruiterName] = useState<string>(
    t('Demand:DemandCreateUpdateFormItems.item.recruiter.input.defaultValue'),
  );

  useEffect(() => {
    if (!recruiter) return;
    const newRecruiterName =
      recruiter.firstName || recruiter.lastName
        ? `${recruiter.firstName} ${recruiter.lastName}`
        : t('Demand:DemandCreateUpdateFormItems.item.recruiter.input.defaultValue');

    setRecruiterName(newRecruiterName);
  }, [recruiter, t, formControl]);

  const disableDatesForDesirableDate = (current: Dayjs): boolean => {
    return current.isBefore(positionStartDate) || current.isAfter(positionEndDate);
  };

  const onSelectRecruiter = (_recruiter: Recruiter): void => {
    formControl.setFieldsValue({ recruiter: _recruiter });
  };

  const renderCustomCell: DatePickerProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div title="" className={calendarStyles.innerCellWrapper}>
        <div className="ant-picker-cell-inner">{current.date()}</div>
      </div>
    );
  };

  return (
    <div className={styles.rootWrapper} data-testid="demand-form-items">
      <Space direction="vertical" align="start" className={styles.formItemsWrapper}>
        <div className={styles.creationDate}>
          <div>{t('Demand:DemandCreateUpdateFormItems.creationDate.label')}</div>
          <div>{creationDate.format(CREATION_DATE_FORMAT)}</div>
        </div>
        <Form.Item
          className={styles.statusInput}
          label={t('Demand:DemandCreateUpdateFormItems.status.item.label')}
          name="status"
          data-testid="form-status-item"
        >
          <Select
            disabled={isLoading}
            data-testid="status-input"
            options={[
              {
                value: 'ACTIVE',
                label: t('Demand:DemandCreateUpdateFormItems.status.item.select.option.label.active'),
              },
              {
                value: 'ON_HOLD',
                label: t('Demand:DemandCreateUpdateFormItems.status.item.select.option.label.onHold'),
              },
              {
                value: 'CANCELLED',
                label: t('Demand:DemandCreateUpdateFormItems.status.item.select.option.label.cancelled'),
              },
              {
                value: 'STAFFED',
                label: t('Demand:DemandCreateUpdateFormItems.status.item.select.option.label.staffed'),
              },
            ]}
          />
        </Form.Item>
        <Space direction="vertical">
          <Form.Item
            label={t('Demand:DemandCreateUpdateFormItems.desirableDate')}
            rules={[
              {
                required: true,
                message: t('Demand:DemandCreateUpdateFormItems.dates.item.validationMessage.required'),
              },
            ]}
            name="desirableDate"
            data-testid="form-desirable-date-item"
          >
            <DatePicker
              placeholder={t('Demand:DemandCreateUpdateFormItems.dates.item.desirableDate.placeholder')}
              disabledDate={disableDatesForDesirableDate}
              disabled={isLoading}
              format={DEFAULT_DATE_FORMAT_UI}
              data-testid="desirable-date-input"
              cellRender={renderCustomCell}
            />
          </Form.Item>
          <Form.Item
            hidden={hideRecruiter}
            label={t('Demand:DemandCreateUpdateFormItems.item.recruiter.input.label')}
            name="recruiter"
            data-testid="recruiter-item"
            valuePropName="data-value"
          >
            <Input
              readOnly
              value={recruiterName}
              data-testid="recruiter-input"
              className={styles.nameInput}
              disabled={isLoading || disableRecruiterChanging}
              onClick={() => setVisible(true)}
            />
          </Form.Item>
        </Space>
      </Space>
      <div className={styles.parentsDescriptionBlock}>
        {accountDescription && (
          <div className={styles.description}>
            <div className={styles.descriptionLabel}>
              {t('Demand:DemandCreateUpdateFormItems.description.account.label')}
            </div>
            <div className={styles.descriptionText}>{accountDescription}</div>
          </div>
        )}
        {projectDescription && (
          <div className={styles.description}>
            <div className={styles.descriptionLabel}>
              {t('Demand:DemandCreateUpdateFormItems.description.project.label')}
            </div>
            <div className={styles.descriptionText}>{projectDescription}</div>
          </div>
        )}
        {positionDescription && (
          <div className={styles.description}>
            <div className={styles.descriptionLabel}>
              {t('Demand:DemandCreateUpdateFormItems.description.position.label')}
            </div>
            <div className={styles.descriptionText}>{positionDescription}</div>
          </div>
        )}
        {!disableRecruiterChanging && (
          <SelectUserForDemandModal open={visible} setOpen={setVisible} onOkButtonClick={onSelectRecruiter} />
        )}
      </div>
    </div>
  );
};

export default DemandCreateUpdateFormItems;
