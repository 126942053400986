import { AxiosRequestConfig } from 'axios';

import { axiosInstance } from 'shared/lib/axios';

import {
  ProjectDTO,
  ProjectCreateRequest,
  ProjectUpdateRequest,
  ProjectList,
  ProjectListRequest,
  ProjectsNamesDTO,
} from './types';

export const projectListGet = (
  { accountId, showArchived, size, page, hideNonActive }: ProjectListRequest,
  config?: AxiosRequestConfig,
): Promise<ProjectList> => {
  return axiosInstance.get(`/api/project/all/${accountId}`, {
    ...config,
    params: { show_archived: showArchived, size, page, sort: 'name', hide_non_active_projects: hideNonActive },
  });
};

export const projectItemGet = (projectId: string, config?: AxiosRequestConfig): Promise<ProjectDTO> => {
  return axiosInstance.get(`/api/project/${projectId}`, config);
};

export const projectItemCreate = ({ accountId, name, description }: ProjectCreateRequest): Promise<ProjectDTO> => {
  return axiosInstance.post('/api/project', { accountId, name, description });
};

export const projectItemUpdate = ({ id, name, description, version }: ProjectUpdateRequest): Promise<ProjectDTO> => {
  return axiosInstance.put('/api/project', { projectId: id, name, description, version });
};

export const projectItemArchive = (id: string): Promise<void> => {
  return axiosInstance.put(`/api/archivation/archive/project/${id}`);
};

export const projectItemRestore = (id: string): Promise<void> => {
  return axiosInstance.put(`/api/archivation/unarchive/project/${id}`);
};

export const projectNamesGetByIds = (ids: string[], config?: AxiosRequestConfig): Promise<ProjectsNamesDTO[]> => {
  return axiosInstance.post('/api/project/names', { ids }, config);
};
