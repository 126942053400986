import React, { ReactElement } from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

import styles from './CleanPageLayout.module.scss';

const { Content } = Layout;

function CleanPageLayout(): ReactElement {
  return (
    <Layout className={styles.antLayout}>
      <Content className={styles.antContent}>
        <Outlet />
      </Content>
    </Layout>
  );
}

export default CleanPageLayout;
