import { PersistStorage } from 'zustand/middleware';
import { AuthState } from '../model/store';

export const persistTransformer: PersistStorage<AuthState> = {
  getItem: (name) => {
    const str = localStorage.getItem(name);
    if (!str) return { state: { token: null, refreshToken: null, refreshTokenExpiredAt: null, authUser: null } };
    return {
      state: {
        ...JSON.parse(str).state,
        authUser: {
          ...JSON.parse(str).state.authUser,
          permissions: new Set(JSON.parse(str).state.authUser ? JSON.parse(str).state.authUser.permissions : []),
        },
      },
    };
  },
  setItem: (name, newValue) => {
    const str = JSON.stringify({
      state: {
        ...newValue.state,
        authUser: {
          ...newValue.state.authUser,
          permissions: Array.from(newValue.state.authUser ? newValue.state.authUser.permissions : []),
        },
      },
    });
    localStorage.setItem(name, str);
  },
  removeItem: (name) => localStorage.removeItem(name),
};
