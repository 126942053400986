import React, { ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';

import { DEFAULT_DATE_FORMAT_FOR_REQUESTS } from 'shared/config';

import styles from './Timeline.module.scss';

type TimelineCell = {
  key: string;
  children: ReactNode;
  weight: number;
};

export type TimelineItem = {
  startDate: Dayjs;
  endDate: Dayjs;
  children: ReactNode;
};

interface ITimeline {
  minDate: Dayjs;
  maxDate: Dayjs;
  timeline: TimelineItem[];
}

const Timeline: React.FC<ITimeline> = (props) => {
  const { minDate, maxDate, timeline } = props;

  const timelineTotalWeight = useMemo(() => maxDate.diff(minDate, 'days') + 1, [minDate, maxDate]);

  const renderTimeline = (): ReactNode => {
    if (!timeline.length) return null;

    const timelineCells: TimelineCell[] = [];

    // handle gap between minDate and first cell startDate
    if (!timeline[0].startDate.isSame(minDate, 'day')) {
      const difference = timeline[0].startDate.diff(minDate, 'day');

      timelineCells.push({
        key: minDate.format(DEFAULT_DATE_FORMAT_FOR_REQUESTS),
        weight: difference / timelineTotalWeight,
        children: null,
      });
    }

    timeline.forEach((item, index, originalTimeline) => {
      const difference = item.endDate.diff(item.startDate, 'day') + 1;

      timelineCells.push({
        key: item.startDate.format(DEFAULT_DATE_FORMAT_FOR_REQUESTS),
        weight: difference / timelineTotalWeight,
        children: item.children,
      });

      // handle the gap between the current and next cell
      const gapDifference =
        index !== originalTimeline.length - 1
          ? originalTimeline[index + 1].startDate.diff(item.endDate, 'day') - 1
          : null;
      if (gapDifference && gapDifference > 0) {
        const gapStartDate = item.endDate.clone().add(1, 'day');
        timelineCells.push({
          key: gapStartDate.format(DEFAULT_DATE_FORMAT_FOR_REQUESTS),
          weight: gapDifference / timelineTotalWeight,
          children: null,
        });
      }
    });

    // handle gap between last cell end date and maxDate
    if (!timeline[timeline.length - 1].endDate.isSame(maxDate, 'day')) {
      const gapStartDate = timeline[timeline.length - 1].endDate.clone().add(1, 'day');
      const difference = maxDate.diff(gapStartDate, 'day') + 1;

      timelineCells.push({
        key: gapStartDate.format(DEFAULT_DATE_FORMAT_FOR_REQUESTS),
        weight: difference / timelineTotalWeight,
        children: null,
      });
    }

    return timelineCells.map((cell) => (
      <div
        key={cell.key}
        style={{ maxWidth: `${cell.weight * 100}%` }}
        className={classNames(styles.timelineItem, !cell.children && styles.emptyTimelineItem)}
      >
        {cell.children}
      </div>
    ));
  };

  return <div className={styles.timelineWrapper}>{renderTimeline()}</div>;
};

export default Timeline;
