import React, { ReactNode, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

import { SettingService } from 'features/userSettings';
import { isHasAccess } from 'features/validateAccess';
import { projectsService } from 'entities/project';

import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';

import ListWithPagination from 'components/ListWithPagination/ListWithPagination';
import {
  DEFAULT_IS_HIDDEN_ARCHIVED_PROJECTS,
  DEFAULT_IS_HIDDEN_EXPIRED_PROJECTS,
  PAGE_SIZE,
} from 'components/Project/Project.consts';
import RowItem from 'components/RowItem/RowItem';

import {
  getEditAllProjectPermissions,
  getEditProjectLocalPermission,
  getGlobalReadPositionPermission,
  getLocalReadPositionPermission,
} from '../Project.permissions';

import styles from './ProjectsList.module.scss';

const ProjectsList: React.FC<{ accountId: string }> = ({ accountId }) => {
  const { t } = useTranslation();

  const canEditAllProject = isHasAccess(getEditAllProjectPermissions(accountId));
  const canSeeAllPositions = isHasAccess(getGlobalReadPositionPermission(accountId));

  const [page, setPage] = useState(1);
  const [hideNonActive, setHideNonActive] = useState(
    SettingService.getLocalSetting('hideNonActiveProjects') ?? DEFAULT_IS_HIDDEN_EXPIRED_PROJECTS,
  );
  const [showArchived, setShowArchived] = useState(
    SettingService.getLocalSetting('showArchivedProjects') ?? DEFAULT_IS_HIDDEN_ARCHIVED_PROJECTS,
  );

  const {
    data: { content: projectList, ...projectListAuxData },
  } = useSuspenseQuery(
    projectsService.queryOptions({ accountId, page: page - 1, size: PAGE_SIZE, showArchived, hideNonActive }),
  );

  const onChangeHiddenExpiredProjects = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('hideNonActiveProjects', event.target.checked);

    setHideNonActive(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  const onChangeHiddenArchivedProjects = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('showArchivedProjects', event.target.checked);
    setShowArchived(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  const renderListItems = (): ReactNode[] => {
    return projectList.map((project) => {
      const canEditProject = canEditAllProject || isHasAccess(getEditProjectLocalPermission(project.id));
      const canSeePositions = canSeeAllPositions || isHasAccess(getLocalReadPositionPermission(project.id));

      return (
        <RowItem
          key={project.id}
          name={project.name}
          count={project.positionsCount}
          openLinkHref={pathKeys.position.root({ accountId, projectId: project.id })}
          editLinkHref={pathKeys.project.update({ accountId, projectId: project.id })}
          countName={t('Project:ProjectsList.positionsCount.name')}
          isArchived={project.archived}
          hideEditButton={!canEditProject}
          iconTooltip={t('Project:ProjectsList.values.archived')}
          disableLink={!canSeePositions}
        />
      );
    });
  };

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.filtersWrapper}>
        <Checkbox onChange={onChangeHiddenExpiredProjects} checked={hideNonActive}>
          {t('Project:ProjectsList.filters.hideNonActive')}
        </Checkbox>
        <Checkbox onChange={onChangeHiddenArchivedProjects} checked={showArchived}>
          {t('Project:ProjectsList.filters.showArchived')}
        </Checkbox>
      </div>
      <ListWithPagination
        items={renderListItems()}
        totalItems={projectListAuxData?.totalElements ?? 0}
        pageSize={PAGE_SIZE}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default withSuspense(ProjectsList, { fallback: <Loader /> });
