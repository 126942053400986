import { AxiosRequestConfig } from 'axios';
import { axiosInstance } from 'shared/lib/axios';

import { PositionDTO, PositionList, PositionListRequest, PositionCreateRequest, PositionUpdateRequest } from './types';

export const positionListGet = (payload: PositionListRequest, config?: AxiosRequestConfig): Promise<PositionList> => {
  const { projectId, showArchived, size, page, expiredIncluded } = payload;
  return axiosInstance.get(`/api/position`, {
    ...config,
    params: { projectId, show_archived: showArchived, size, page, sort: 'name', expired_included: expiredIncluded },
  });
};

export const positionItemGet = (positionId: string, config?: AxiosRequestConfig): Promise<PositionDTO> => {
  return axiosInstance.get(`/api/position/${positionId}`, config);
};

export const positionItemCreate = (payload: PositionCreateRequest): Promise<PositionDTO> => {
  const { projectId, name, description, startDate, endDate, rates } = payload;
  return axiosInstance.post('/api/position', { projectId, name, description, startDate, endDate, rates });
};

export const positionItemUpdate = (payload: PositionUpdateRequest): Promise<PositionDTO> => {
  const { id, name, description, startDate, endDate, rates, version } = payload;
  return axiosInstance.put('/api/position', { id, name, description, startDate, endDate, rates, version });
};

export const positionItemArchive = (id: string): Promise<void> => {
  return axiosInstance.put(`/api/archivation/archive/position/${id}`);
};
