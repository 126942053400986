import { ReactElement, PropsWithChildren } from 'react';

import styles from './LeftSidebarPageLayout.module.scss';

type UserOverviewPageLayoutType = {
  sidebarWidget: ReactElement;
};

function LeftSidebarPageLayout({
  children,
  sidebarWidget,
}: PropsWithChildren<UserOverviewPageLayoutType>): ReactElement {
  return (
    <div className={styles.rootWrapper}>
      <div className={styles.sidebarHolder}>{sidebarWidget}</div>
      <div className={styles.contentHolder}>{children}</div>
    </div>
  );
}

export default LeftSidebarPageLayout;
