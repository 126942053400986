import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { isHasAccess } from 'features/validateAccess';
import { accountService } from 'entities/account';
import { projectService } from 'entities/project';

import { ListPageLayout } from 'shared/layouts/pageLevelLayouts';
import { pathKeys } from 'shared/lib/react-router';
import type { IBreadcrumbItem } from 'shared/ui/Breadcrumb';

import PositionList from 'components/PositionAndAssignment/PositionList/PositionList';

import {
  CAN_SEE_ALL_ACCOUNTS,
  getAccountNameViewPermission,
  getCreatePositionPermissions,
  getProjectListViewPermission,
  getProjectNameViewPermission,
} from './PositionsPage.permissions';

const PositionsPage: React.FC = () => {
  const { t } = useTranslation('Position');

  const { accountId = '', projectId = '' } = useParams();

  const canCreatePosition = isHasAccess(getCreatePositionPermissions(accountId, projectId));
  const canGoToAccountList = isHasAccess(CAN_SEE_ALL_ACCOUNTS);
  const canSeeAccountName = canGoToAccountList || isHasAccess(getAccountNameViewPermission(accountId));
  const canSeeProjectList = isHasAccess(getProjectListViewPermission(accountId));
  const canSeeProjectName = canSeeProjectList || isHasAccess(getProjectNameViewPermission(projectId));

  const { data: account, isLoading: isAccountLoading } = useQuery(
    accountService.queryOptions(accountId, canSeeAccountName),
  );

  const { data: project, isLoading: isProjectLoading } = useQuery(
    projectService.queryOptions(projectId, canSeeProjectName),
  );

  const createBreadcrumb = (): IBreadcrumbItem[] => {
    return [
      {
        label: t('Account:AccountsPage.heading'),
        path: pathKeys.account.root(),
        disable: !canGoToAccountList,
      },
      {
        label: account?.name ?? '',
        path: pathKeys.project.root({ accountId }),
        hide: !canSeeAccountName,
        disable: !canSeeProjectList,
      },
      {
        label: project?.name ?? '',
        hide: !canSeeProjectName,
      },
    ];
  };

  return (
    <ListPageLayout
      title={t('PositionsPage.heading')}
      breadcrumb={createBreadcrumb()}
      createButtonText={t('PositionsPage.createButton.label')}
      isBreadcrumbLoading={isAccountLoading || isProjectLoading}
      hideCreateButton={!canCreatePosition}
      createPath={() => pathKeys.position.create({ accountId, projectId })}
    >
      <PositionList projectId={projectId} accountId={accountId} />
    </ListPageLayout>
  );
};

export default PositionsPage;
