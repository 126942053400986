import { useMemo } from 'react';

import { isHasAccess } from 'features/validateAccess';

import {
  ACCESS_GROUPS_ARCHIVE,
  ACCESS_GROUPS_CREATE,
  ACCESS_GROUPS_EDIT,
} from '../AccessGroupsManagementTable.permissions';

type UseAccessReturnType = {
  editAccess: boolean;
  archiveAccess: boolean;
  createAccess: boolean;
};

export const useAccess = (): UseAccessReturnType => {
  const accesses = useMemo(() => {
    return {
      editAccess: isHasAccess(ACCESS_GROUPS_EDIT),
      archiveAccess: isHasAccess(ACCESS_GROUPS_ARCHIVE),
      createAccess: isHasAccess(ACCESS_GROUPS_CREATE),
    };
  }, []);

  return accesses;
};
