import React, { Fragment, ReactElement } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { isHasAccess } from 'features/validateAccess';

import { INavItem, navItems } from '../../config/navItems';

import styles from './MainNav.module.scss';

function MainNav(): ReactElement {
  const { t } = useTranslation();
  const location = useLocation();

  const { pathname } = location;

  const renderNavList = (): React.ReactNode => {
    return (
      <ul className={styles.navList} data-testid="navigation-block">
        {navItems.map((item: INavItem) => {
          return isHasAccess(item.requiredPermissions) ? (
            <li
              key={item.id}
              className={classNames(styles.navItem, {
                [styles.navItemActive]: item.path.startsWith(pathname),
              })}
            >
              <Link
                to={item.path}
                className={styles.navLink}
                style={{ textDecoration: 'none' }} // fix for css modules styles application issue
                data-testid="navigation-item"
              >
                {t(item.captionID)}
              </Link>
            </li>
          ) : null;
        })}
      </ul>
    );
  };

  return <Fragment>{renderNavList()}</Fragment>;
}

export default MainNav;
