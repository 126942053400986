import { useCallback } from 'react';
import { App, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import { useDuplicateAGMutation } from 'entities/accessGroup';

import NameInput from '../ui/NameInput/NameInput';

export const useDuplicateModal = (): ((id: string, name: string) => void) => {
  const { modal } = App.useApp();
  const { t } = useTranslation('AccessGroup');

  const { mutate: duplicateAGMutation } = useDuplicateAGMutation();

  const openDuplicateModal = useCallback(
    (id: string, name: string): void => {
      const instance = modal.confirm({
        title: `${t('DuplicateModal.title')} ${name}`,
        transitionName: '',
        centered: true,
        closable: true,
        okButtonProps: { htmlType: 'submit' },
        cancelText: t('RenameModal.cancelButton'),
        okText: t('DuplicateModal.confirmButton.text'),
        onOk: () => {
          return Promise.reject();
        },
        modalRender: (dom) => (
          <Form
            initialValues={{ name: `Copy of ${name}` }}
            onFinish={({ name: newName }) => {
              instance.update({
                okButtonProps: { loading: true },
              });
              duplicateAGMutation(
                { id, name: newName },
                {
                  onSettled: () => instance.destroy(),
                },
              );
            }}
          >
            {dom}
          </Form>
        ),
        content: <NameInput />,
      });
    },
    [duplicateAGMutation, modal, t],
  );

  return openDuplicateModal;
};
