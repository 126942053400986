import { useCallback, useRef, useState } from 'react';
import { App } from 'antd';
import { useTranslation } from 'react-i18next';

import { ColumnStatus, ColumnStatusMap } from 'features/FoldingTable';
import { SettingService } from 'features/userSettings';

import {
  useUpdatePermissionsAGMutation,
  type AccessGroupDTO,
  type GrantType,
  type PermissionGrantTypeRelationUpdateRequest,
} from 'entities/accessGroup';

import { Maybe } from 'shared/types';
import { useCreateModal } from './useCreateModal';
import { useDeleteModal } from './useDeleteModal';
import { useDuplicateModal } from './useDuplicateModal';
import { useRenameModal } from './useRenameModal';

type UnsavedChanges = {
  accessGroupId: string;
  permissionGrantTypeRelationList: Record<string, PermissionGrantTypeRelationUpdateRequest>;
};

type UseChangesReturnType = {
  columnsStatuses: ColumnStatusMap;
  hasUnsavedChanges: boolean;
  editAccessGroup: (id: string) => void;
  onChangePin: (id: string, pinnedIds: string[]) => void;
  onChangeGrantType: (accessGroupId: string, permissionId: string, permissionValue: GrantType, version: number) => void;
  onSavePermissionChanges: () => void;
  openCreateAccessGroupModal: () => void;
  openDuplicateModal: (id: string) => void;
  openDeleteModal: (id: string) => void;
  openRenameModal: (id: string) => void;
  openDropUnsavedChangesModal: () => void;
};

export const useChanges = (accessGroupListData: AccessGroupDTO[]): UseChangesReturnType => {
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const openDuplicateModal = useDuplicateModal();
  const openCreateModal = useCreateModal();
  const openRenameModal = useRenameModal();
  const openDeleteModal = useDeleteModal();

  const { mutate: updatePermissionsAGMutation } = useUpdatePermissionsAGMutation();

  const unsavedChanges = useRef<Maybe<UnsavedChanges>>(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [columnsStatuses, setColumnsStatus] = useState<ColumnStatusMap>({});

  const openWarningModal = useCallback(() => {
    const { destroy } = modal.warning({
      content: t('AccessGroup:UnsavedChangesModal.title'),
      transitionName: '',
      closable: true,
      centered: true,
      okText: t('AccessGroup:UnsavedChangesModal.confirmButton.text'),
      onOk: () => destroy(),
    });
  }, [modal, t]);

  const editAccessGroup = useCallback(
    (id: string): void => {
      if (Object.values(unsavedChanges.current?.permissionGrantTypeRelationList ?? []).length > 0) {
        openWarningModal();
        return;
      }

      setColumnsStatus({
        [id]: ColumnStatus.EDIT,
      });
    },
    [openWarningModal],
  );

  const openRenameModalWrapper = useCallback(
    (id: string): void => {
      if (Object.values(unsavedChanges.current?.permissionGrantTypeRelationList ?? []).length > 0) {
        openWarningModal();
        return;
      }
      const accessGroupName = accessGroupListData.find((item) => item.id === id)?.name;
      openRenameModal(id, accessGroupName ?? '');
    },
    [accessGroupListData, openRenameModal, openWarningModal],
  );

  const openDuplicateModalWrapper = useCallback(
    (id: string): void => {
      if (Object.values(unsavedChanges.current?.permissionGrantTypeRelationList ?? []).length > 0) {
        openWarningModal();
        return;
      }
      const accessGroupName = accessGroupListData.find((item) => item.id === id)?.name;
      openDuplicateModal(id, accessGroupName ?? '');
    },
    [accessGroupListData, openDuplicateModal, openWarningModal],
  );

  const openDeleteModalWrapper = useCallback(
    (id: string): void => {
      if (Object.values(unsavedChanges.current?.permissionGrantTypeRelationList ?? []).length > 0) {
        openWarningModal();
        return;
      }
      const accessGroupName = accessGroupListData.find((item) => item.id === id)?.name;

      openDeleteModal(id, accessGroupName ?? '');
    },
    [accessGroupListData, openDeleteModal, openWarningModal],
  );

  const openCreateModalWrapper = (): void => {
    if (hasUnsavedChanges) {
      openWarningModal();
      return;
    }
    openCreateModal();
  };

  const clearChanges = (): void => {
    unsavedChanges.current = null;
    setColumnsStatus({});
    setHasUnsavedChanges(false);
  };

  const openDropUnsavedChangesModal = (): void => {
    const { destroy } = modal.confirm({
      centered: true,
      closable: true,
      okText: t('AccessGroup:DropChangesModal.confirmButton.text'),
      cancelText: t('AccessGroup:DropChangesModal.cancelButton.text'),
      onCancel: () => {
        destroy();
      },
      onOk: () => {
        clearChanges();
        destroy();
      },
      content: t('AccessGroup:DropChangesModal.title'),
    });
  };

  const onSavePermissionChanges = (): void => {
    if (unsavedChanges.current) {
      updatePermissionsAGMutation(
        {
          id: unsavedChanges.current.accessGroupId,
          permissionGrantTypeRelationList: Object.values(unsavedChanges.current.permissionGrantTypeRelationList),
        },
        {
          onSuccess: () => {
            clearChanges();
          },
        },
      );
    }
  };

  const onChangeGrantType = useCallback(
    (accessGroupId: string, permissionId: string, permissionValue: GrantType, version: number): void => {
      const newChanges: UnsavedChanges = {
        accessGroupId,
        permissionGrantTypeRelationList: {
          ...unsavedChanges.current?.permissionGrantTypeRelationList,
          [permissionId]: {
            permissionId,
            grantType: permissionValue,
            version,
          },
        },
      };
      setHasUnsavedChanges(true);

      unsavedChanges.current = newChanges;
    },
    [],
  );

  const onChangePin = useCallback(
    (_: string, pinnedIds: string[]): void => SettingService.saveLocalSetting('pinnedAccessGroups', pinnedIds),
    [],
  );

  return {
    columnsStatuses,
    hasUnsavedChanges,
    editAccessGroup,
    onChangePin,
    onChangeGrantType,
    onSavePermissionChanges,
    openCreateAccessGroupModal: openCreateModalWrapper,
    openDuplicateModal: openDuplicateModalWrapper,
    openDeleteModal: openDeleteModalWrapper,
    openRenameModal: openRenameModalWrapper,
    openDropUnsavedChangesModal,
  };
};
