import React, { ReactNode, useEffect, useState } from 'react';
import { Form, Tooltip } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { ReactComponent as QuestionCircle } from 'shared/assets/icons/question-circle.svg';
import { CURRENCY_LIST } from 'shared/config';
import { Maybe } from 'shared/types';

import { PositionFormType } from 'components/PositionAndAssignment/PositionCreateForm/PositionCreateForm';

import RateItem from './RateItem/RateItem';

import styles from './RateList.module.scss';

type RateListExtensions = {
  Item: typeof RateItem;
};

const RateList: React.FC & RateListExtensions = () => {
  const { t } = useTranslation();
  const form = Form.useFormInstance<PositionFormType>();

  const [shouldValidateStartDateField, setShouldValidateStartDateField] = useState<{ index: number } | null>(null);
  const [shouldValidateEndDateField, setShouldValidateEndDateField] = useState<{ index: number } | null>(null);

  useEffect(() => {
    if (!shouldValidateStartDateField) return;

    form.validateFields([['rates', shouldValidateStartDateField.index, 'startDate']]);
    setShouldValidateStartDateField(null);
  }, [form, shouldValidateStartDateField]);

  useEffect(() => {
    if (!shouldValidateEndDateField) return;

    form.validateFields([['rates', shouldValidateEndDateField.index, 'endDate']]);
    setShouldValidateEndDateField(null);
  }, [form, shouldValidateEndDateField]);

  const renderRates = (
    fields: FormListFieldData[],
    add: (
      defaultValue?: {
        value: string;
        currency: typeof CURRENCY_LIST[number];
        endDate: Maybe<Dayjs>;
        startDate: Maybe<Dayjs>;
      },
      index?: number,
    ) => void,
    remove: (index: number) => void,
  ): ReactNode => {
    return (
      <div className={styles.ratesWrapper}>
        {fields.map((field, index) => {
          return (
            <div key={field.key} className={styles.rateItemWrapper}>
              <div className={styles.rateItem}>
                <RateList.Item
                  field={field}
                  index={index}
                  maxIndex={fields.length - 1}
                  shouldValidateStartDateField={shouldValidateStartDateField}
                  setShouldValidateStartDateField={setShouldValidateStartDateField}
                  shouldValidateEndDateField={shouldValidateEndDateField}
                  setShouldValidateEndDateField={setShouldValidateEndDateField}
                  add={add}
                  removeFn={remove}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const buildTooltipTitle = (): ReactNode => {
    return (
      <ul className={styles.tooltipHeader}>
        <li>{t('Position:RateList.Item.dates.help.tooltip.text.line1')}</li>
        <li>{t('Position:RateList.Item.dates.help.tooltip.text.line2')}</li>
        <li>{t('Position:RateList.Item.dates.help.tooltip.text.line3')}</li>
        <li>{t('Position:RateList.Item.dates.help.tooltip.text.line4')}</li>
        <li>{t('Position:RateList.Item.dates.help.tooltip.text.line5')}</li>
      </ul>
    );
  };

  return (
    <div>
      <div className={styles.rateTitle} data-testid="rate-list">
        {t('Position:RateList.heading')}
        <Tooltip title={() => buildTooltipTitle()} placement="leftBottom">
          <QuestionCircle />
        </Tooltip>
      </div>
      <Form.List name="rates">{(fields, { add, remove }) => renderRates(fields, add, remove)}</Form.List>
    </div>
  );
};

RateList.Item = RateItem;

export default RateList;
