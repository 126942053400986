import React from 'react';
import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';

import styles from './RcPagination.module.scss';

type RcPaginationType = PaginationProps & {
  // TODO add type after clarifying the data structure
  dataSize: number;
  pageSize: number;
  setCurrentPage: (arg: number) => void;
  current: number;
};

const RcPagination: React.FC<RcPaginationType> = ({ dataSize, pageSize, setCurrentPage, current, ...rest }) => {
  const handleChange = (value: number): void => {
    setCurrentPage?.(value);
  };

  return (
    <Pagination
      data-testid="list-pagination"
      current={current}
      responsive
      total={dataSize}
      onChange={handleChange}
      showSizeChanger={false}
      pageSize={pageSize}
      className={styles.pagination}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
};

export default RcPagination;
