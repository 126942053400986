import React, { useEffect, useMemo, useRef } from 'react';
import { DatePicker, DatePickerProps, Form, Input, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { DEFAULT_DATE_FORMAT_UI } from 'shared/config';
import calendarStyles from 'shared/styles/ant-custom-components/calendar.module.scss';
import { Maybe } from 'shared/types';

import {
  DEFAULT_POSITION_DESCRIPTION_MAX_LENGTH,
  DEFAULT_POSITION_NAME_MAX_LENGTH,
  DEFAULT_POSITION_NAME_MIN_LENGTH,
} from 'components/PositionAndAssignment/Position.consts';

const PositionCreateUpdateFormItems: React.FC<{ isLoading: boolean; isDatesIntersected?: boolean }> = ({
  isLoading,
  isDatesIntersected = false,
}) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance();
  const currentMonthStart = useMemo((): Dayjs => {
    return dayjs().startOf('month');
  }, []);

  const isDateRangeIntersectedChecked = useRef(true);

  const disableDatesForStartDate = (current: Dayjs): boolean => {
    const endDate: Dayjs = form.getFieldValue('endDate');
    return currentMonthStart.isAfter(current) || (endDate && endDate.isBefore(current));
  };

  const disableDatesForEndDate = (current: Dayjs): boolean => {
    const startDate: Dayjs = form.getFieldValue('startDate');
    return currentMonthStart.isAfter(current) || (startDate && startDate.isAfter(current));
  };

  useEffect(() => {
    if (isDatesIntersected) {
      isDateRangeIntersectedChecked.current = false;
      form.validateFields(['startDate', 'endDate']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDatesIntersected]);

  const isDatesIntersectedValidator = (): Promise<void> => {
    if (isDatesIntersected && !isDateRangeIntersectedChecked.current) {
      isDateRangeIntersectedChecked.current = true;
      return Promise.reject();
    }
    return Promise.resolve();
  };

  const validateNotEmptyRatesDate = (): void => {
    const rates: Array<any> = form.getFieldValue('rates');
    rates.forEach((value, index) => {
      if (value.startDate !== null) {
        form.validateFields([['rates', index, 'startDate']]);
      }
      if (value.endDate !== null) {
        form.validateFields([['rates', index, 'endDate']]);
      }
    });
  };

  const setFirstRateStartDate = (date: Maybe<Dayjs>): void => {
    const firstRate: any = form.getFieldValue(['rates', 0]);
    firstRate.startDate = date;
    form.setFieldsValue({});
    validateNotEmptyRatesDate();
  };

  const setLastRateEndDate = (date: Maybe<Dayjs>): void => {
    const rates: Array<any> | null = form.getFieldValue('rates');
    if (rates != null) {
      rates[rates.length - 1].endDate = date;
      form.setFieldsValue({});
    }
    validateNotEmptyRatesDate();
  };

  const renderCustomCell: DatePickerProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div title="" className={calendarStyles.innerCellWrapper}>
        <div className="ant-picker-cell-inner">{current.date()}</div>
      </div>
    );
  };

  return (
    <Space direction="vertical">
      <Form.Item
        label={t('Position:PositionCreateUpdateFormItems.name.item.label')}
        rules={[
          { required: true, message: t('Position:PositionCreateUpdateFormItems.name.item.validationMessage.required') },
          {
            min: DEFAULT_POSITION_NAME_MIN_LENGTH,
            max: DEFAULT_POSITION_NAME_MAX_LENGTH,
            message: t('Position:PositionCreateUpdateFormItems.name.item.validationMessage.nameLength'),
          },
        ]}
        name="name"
        data-testid="form-name-item"
        normalize={(value: string) => value.trimStart()}
      >
        <Input
          disabled={isLoading}
          placeholder={t('Position:PositionCreateUpdateFormItems.name.item.placeholder.text')}
          data-testid="name-input"
        />
      </Form.Item>
      <Space direction="horizontal">
        <Form.Item
          label={t('Position:PositionCreateUpdateFormItems.startDate')}
          rules={[
            {
              required: true,
              message: t('Position:PositionCreateUpdateFormItems.dates.item.validationMessage.required'),
            },
            {
              validator: isDatesIntersectedValidator,
              message: t('Position:PositionCreateUpdateFormItems.dates.validationError.dateIntersection'),
            },
          ]}
          name="startDate"
          data-testid="form-start-date-item"
        >
          <DatePicker
            placeholder={t('Position:PositionCreateUpdateFormItems.dates.item.startDate.placeholder')}
            disabledDate={disableDatesForStartDate}
            disabled={isLoading}
            format={DEFAULT_DATE_FORMAT_UI}
            data-testid="start-date-input"
            cellRender={renderCustomCell}
            onChange={setFirstRateStartDate}
          />
        </Form.Item>
        <Form.Item
          label={t('Position:PositionCreateUpdateFormItems.endDate')}
          rules={[
            {
              validator: isDatesIntersectedValidator,
              message: t('Position:PositionCreateUpdateFormItems.dates.validationError.dateIntersection'),
            },
          ]}
          name="endDate"
          data-testid="form-end-date-item"
        >
          <DatePicker
            placeholder={t('Position:PositionCreateUpdateFormItems.dates.item.endDate.placeholder')}
            disabledDate={disableDatesForEndDate}
            disabled={isLoading}
            format={DEFAULT_DATE_FORMAT_UI}
            data-testid="end-date-input"
            cellRender={renderCustomCell}
            onChange={setLastRateEndDate}
          />
        </Form.Item>
      </Space>
      <Form.Item
        label={t('Position:PositionCreateUpdateFormItems.description.item.label')}
        rules={[
          {
            max: DEFAULT_POSITION_DESCRIPTION_MAX_LENGTH,
            message: t('Position:PositionCreateUpdateFormItems.description.item.validationMessage.descriptionLength'),
          },
        ]}
        name="description"
        data-testid="form-description-item"
      >
        <Input.TextArea
          disabled={isLoading}
          placeholder={t('Position:PositionCreateUpdateFormItems.description.item.placeholder.text')}
          autoSize={{ maxRows: 5, minRows: 5 }}
          data-testid="description-input"
        />
      </Form.Item>
    </Space>
  );
};

export default React.memo(PositionCreateUpdateFormItems);
