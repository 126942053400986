import React, { ReactNode, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

import { SettingService } from 'features/userSettings';
import { isHasAccess } from 'features/validateAccess';

import { accountsService } from 'entities/account';

import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Avatar } from 'shared/ui/Avatar';
import { Loader } from 'shared/ui/Loader';

import {
  DEFAULT_IS_HIDDEN_ARCHIVED_ACCOUNTS,
  DEFAULT_IS_HIDDEN_EXPIRED_ACCOUNTS,
  PAGE_SIZE,
} from 'components/Account/Account.const';
import ListWithPagination from 'components/ListWithPagination/ListWithPagination';
import RowItem from 'components/RowItem/RowItem';

import {
  GLOBAL_EDIT_ACCOUNT_PERMISSION,
  GLOBAL_PROJECT_READ_PERMISSION,
  getLocalEditAccountPermission,
  getLocalReadProjectPermission,
} from '../Account.permissions';

import styles from './AccountsList.module.scss';

const AccountsList: React.FC = () => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [hideNonActive, setHideNonActive] = useState<boolean>(
    SettingService.getLocalSetting('hideNonActiveAccounts') ?? DEFAULT_IS_HIDDEN_EXPIRED_ACCOUNTS,
  );
  const [showArchived, setShowArchived] = useState<boolean>(
    SettingService.getLocalSetting('showArchivedAccounts') ?? DEFAULT_IS_HIDDEN_ARCHIVED_ACCOUNTS,
  );

  const {
    data: { content: accountList, ...accountListAux },
  } = useSuspenseQuery(
    accountsService.queryOptions({
      page: page - 1,
      size: PAGE_SIZE,
      showArchived,
      hideNonActive,
    }),
  );

  const canEditAllAccounts = isHasAccess(GLOBAL_EDIT_ACCOUNT_PERMISSION);
  const canSeeAllProjects = isHasAccess(GLOBAL_PROJECT_READ_PERMISSION);

  const onChangeHiddenExpiredAccounts = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('hideNonActiveAccounts', event.target.checked);
    setHideNonActive(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  const onChangeShowArchived = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('showArchivedAccounts', event.target.checked);
    setShowArchived(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  const renderListItems = (): ReactNode[] => {
    return accountList.map((account) => {
      const canEditAccount = canEditAllAccounts || isHasAccess(getLocalEditAccountPermission(account.id));
      const canSeeProjects = canSeeAllProjects || isHasAccess(getLocalReadProjectPermission(account.id));

      return (
        <RowItem
          key={account.id}
          name={account.name}
          count={account.projectsCount}
          openLinkHref={pathKeys.project.root({ accountId: account.id })}
          editLinkHref={pathKeys.account.update({ accountId: account.id })}
          countName={t('Account:AccountsList.projectsCount.name')}
          logo={<Avatar size={32} text={account.name} picture={account.logoUrl} />}
          isArchived={!!account.archivedAt}
          iconTooltip={t('Account:AccountsList.values.archived')}
          hideEditButton={!canEditAccount}
          disableLink={!canSeeProjects}
        />
      );
    });
  };

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.filtersWrapper}>
        <Checkbox onChange={onChangeHiddenExpiredAccounts} checked={hideNonActive}>
          {t('Account:AccountsList.filters.hideNonActive')}
        </Checkbox>
        <Checkbox onChange={onChangeShowArchived} checked={showArchived}>
          {t('Account:AccountsList.filters.showArchived')}
        </Checkbox>
      </div>
      <ListWithPagination
        items={renderListItems()}
        totalItems={accountListAux?.totalElements ?? 0}
        pageSize={PAGE_SIZE}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default withSuspense(AccountsList, {
  fallback: <Loader />,
});
