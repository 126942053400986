import React, { ReactNode, useEffect } from 'react';
import { Button, Form, FormInstance, FormProps } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useTranslation } from 'react-i18next';

import { useFormErrorsTranslate } from 'shared/lib/i18n';
import { useBlockNavigation } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';

import styles from './CreateUpdateFormHolder.module.scss';

type CreateUpdateFormHolderType = {
  children: ReactNode;
  title: string;
  subtitle?: string;
  onFormSubmit: FormProps['onFinish'];
  onClickArchiveButton?: () => void;
  onFormChange?: FormProps['onValuesChange'];
  onCancel: () => void;
  isLoading: boolean;
  customFormControl?: [FormInstance];
  isLoadingData?: boolean;
  editMode?: boolean;
  initialData?: Store;
  preventNavigation?: boolean;
  preserve?: boolean;
  hideDeleteBtn?: boolean;
};

const CreateUpdateFormHolder: React.FC<CreateUpdateFormHolderType> = ({
  children,
  title,
  subtitle,
  onFormSubmit,
  onClickArchiveButton,
  onFormChange,
  onCancel,
  isLoading,
  customFormControl,
  isLoadingData = false,
  editMode = false,
  initialData,
  preventNavigation = false,
  preserve,
  hideDeleteBtn = false,
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form] = customFormControl || Form.useForm();
  useFormErrorsTranslate(form);

  useBlockNavigation(preventNavigation);

  const okButtonText = editMode
    ? t('ReusableComponents:CreateUpdateFormHolder.saveButton.label')
    : t('ReusableComponents:CreateUpdateFormHolder.createButton.label');

  useEffect(() => {
    form.setFieldsValue(initialData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingData]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>{title}</h2>
        {subtitle ? <h3 className={styles.subtitle}>{subtitle}</h3> : null}
      </div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={onFormSubmit}
        onValuesChange={onFormChange}
        autoComplete="off"
        data-testid="create-update-form"
        preserve={preserve}
      >
        <div className={styles.children}>{children}</div>
        <div className={styles.footer}>
          <div className={styles.mainButtonHolder}>
            <Button onClick={onCancel} data-testid="cancel-button">
              {t('ReusableComponents:CreateUpdateFormHolder.cancelButton.label')}
            </Button>
            <Button loading={isLoading} htmlType="submit" type="primary" data-testid="submit-button">
              {okButtonText}
            </Button>
          </div>
          {editMode && !hideDeleteBtn && (
            <div className={styles.addButtonHolder}>
              <Button data-testid="archive-button" onClick={onClickArchiveButton}>
                {t('ReusableComponents:CreateUpdateFormHolder.archiveButton.label')}
              </Button>
            </div>
          )}
        </div>
      </Form>
      <Loader isVisible={isLoadingData} isTranslucent />
    </div>
  );
};

export default CreateUpdateFormHolder;
