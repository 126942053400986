import React from 'react';

import { UserOverview } from 'widgets/UserOverview';
import { UserOverviewFilter } from 'features/UserOverviewFilter';

import { LeftSidebarPageLayout } from 'shared/layouts/pageLevelLayouts';

const UserOverviewPage: React.FC = () => {
  return (
    <LeftSidebarPageLayout sidebarWidget={<UserOverviewFilter />} data-testid="left-sidebar-page-layout">
      <UserOverview />
    </LeftSidebarPageLayout>
  );
};

export default UserOverviewPage;
