import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Sidebar, SidebarGroup, SidebarItem } from 'features/FoldingTable';
import { PermissionDTO } from 'entities/permission';

export const useSidebar = (permissions: PermissionDTO[]): Sidebar => {
  const { t } = useTranslation();

  const mappedPermissions: Sidebar = useMemo(() => {
    const groups: SidebarGroup[] = permissions
      .filter((item, index, array) => array.findIndex((obj) => obj.permissionGroup === item.permissionGroup) === index)
      .map((item) => ({
        id: item.permissionGroup,
        name: item.permissionGroup,
        items: permissions.reduce<SidebarItem[]>((accGroups, permission) => {
          if (permission.permissionGroup === item.permissionGroup) {
            accGroups.push({
              id: permission.id,
              name: permission.label,
            });
          }

          return accGroups;
        }, []),
      }));

    return {
      groups,
      title: t('AccessGroup:TableSidebar.heading.title'),
    };
  }, [permissions, t]);

  return mappedPermissions;
};
