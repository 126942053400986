import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const getProjectCreatePermissions = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.CREATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.CREATE,
    },
  ],
];

export const getAccountNameViewPermission = (accountId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCOUNT,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'local',
      global: AvailableGlobalPermissions.ACCOUNT,
      local: accountId,
      value: AvailablePermissionsValue.READ,
    },
  ],
];
