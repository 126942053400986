import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { accessGroupUsersService, SearchDTO } from 'entities/user';
import { ITEMS_PER_PAGE } from '../../consts';
import { useTransferModalStore } from '../../model/store';
import TransferContent from '../TransferContent/TransferContent';

type GroupProps = {
  disableUserSelection: boolean;
  selectedUsers: SearchDTO['foundUsers'];
  onChangeSelectedUsers: (users: SearchDTO['foundUsers']) => void;
};

const RightGroup: React.FC<GroupProps> = ({ disableUserSelection, selectedUsers, onChangeSelectedUsers }) => {
  const { t } = useTranslation();
  const [params, setParams] = useState<{ offset: number; page: number; query: string }>({
    offset: 0,
    page: 0,
    query: '',
  });

  const { groupId, usersMovedToGroup, usersMovedFromGroup } = useTransferModalStore();

  const { data: users, isFetching } = useQuery(
    accessGroupUsersService.queryOptions(
      {
        accessGroupId: groupId ?? '',
        assigned: false,
        query: params.query,
        sort: 'first_name,ASC',
        size: ITEMS_PER_PAGE,
        offset: params.offset < params.page * ITEMS_PER_PAGE ? params.page * ITEMS_PER_PAGE - params.offset : 0,
      },
      !!groupId,
    ),
  );

  return (
    <TransferContent
      title={t('AccessGroup:Transfer.rightColumn.label')}
      users={users?.foundUsers}
      setSearchParams={setParams}
      usersMovedToGroup={usersMovedFromGroup}
      usersMovedFromGroup={usersMovedToGroup}
      selectedUsers={selectedUsers}
      countOfFoundUsers={users?.countOfFoundUsers ?? 0}
      loading={isFetching}
      onChangeSelectedUsers={onChangeSelectedUsers}
      disableSelect={disableUserSelection}
    />
  );
};

export default RightGroup;
