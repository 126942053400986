import React from 'react';
import { Checkbox, Skeleton, Tooltip } from 'antd';
import classNames from 'classnames';

import { UserSearchDTO } from 'entities/user';

import { Avatar } from 'shared/ui/Avatar';

import styles from './UserItem.module.scss';

type UserItemProps = {
  user?: UserSearchDTO;
  loading: boolean;
  handleSelect?: (userId: string) => void;
  selected?: boolean;
  moved?: boolean;
  disabled?: boolean;
  disableSelect?: boolean;
};

const UserItem: React.FC<UserItemProps> = ({
  user,
  loading,
  handleSelect,
  disabled,
  selected,
  moved,
  disableSelect,
}) => {
  const onSelectWrapper = (): void => {
    if (handleSelect && user?.id) {
      handleSelect(user.id);
    }
  };

  const userName = `${user?.firstName ?? ''} ${user?.lastName ?? ''}`;
  const role = `${user?.seniority ?? ''} ${user?.workProfile ?? ''} ${user?.specialization ?? ''}`;

  const tooltipTitle = <span className={styles.tooltipTitle}>{userName}</span>;

  return (
    <div
      className={classNames(styles.row, {
        [styles.selected]: (selected || moved) && !loading,
        [styles.disabled]: disabled && !loading,
      })}
    >
      <Skeleton loading={loading} active avatar={{ size: 'small' }} paragraph={{ rows: 0 }} title={{ width: '100%' }}>
        <span className={styles.transferItem}>
          <Checkbox onChange={onSelectWrapper} checked={selected} disabled={disabled || disableSelect} />
          <Avatar picture={user?.picture} text={userName} size={20} className={styles.avatar} />
          <Tooltip color="ghostwhite" placement="topLeft" title={tooltipTitle}>
            <span className={styles.text}>{userName}</span>
          </Tooltip>
        </span>
        <span className={classNames(styles.role, styles.text)}>{role || ' '}</span>
      </Skeleton>
    </div>
  );
};

export default React.memo(UserItem);
