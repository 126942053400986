import { useCallback, useEffect } from 'react';
import { FormInstance } from 'antd';
import i18n from 'i18next';

export const useFormErrorsTranslate = (form: FormInstance): void => {
  const handleLanguageChanged = useCallback((): void => {
    const fields = form.getFieldsError();
    const activeFields = fields.filter((field) => field.errors.length !== 0).map((field) => field.name);
    form.validateFields(activeFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [handleLanguageChanged]);
};
