import { useSuspenseQuery } from '@tanstack/react-query';
import { Button, Select } from 'antd';

import { useTranslation } from 'react-i18next';

import { localMappedUsersService, UserSelect, type MappedUserDTO } from 'entities/user';

import { withSuspense } from 'shared/lib/react';
import { EmptyGroup } from 'shared/ui/EmptyGroup';
import { Loader } from 'shared/ui/Loader';
import { ModalDialog } from 'shared/ui/ModalDialog';

import { useMapper } from './lib/useMapper';
import { UserList } from './ui/userList/userList';

import styles from './localPermissionMapper.module.scss';

type LocalPermissionMapperProps = {
  title: string;
  open: boolean;
  entityId: string;
  entityType: string;
  close: () => void;
  onSave?: (data: MappedUserDTO[]) => void;
  isSaving?: boolean;
};

const LocalPermissionMapper: React.FC<LocalPermissionMapperProps> = ({
  title,
  open,
  close,
  onSave,
  entityId,
  entityType,
  isSaving,
}) => {
  const { t } = useTranslation('ReusableComponents');

  const { data: localMappedUsers } = useSuspenseQuery(localMappedUsersService.queryOptions({ entityType, entityId }));

  const {
    selectedData,
    currentUsers,
    accessGroups,
    onMoveUsers,
    onRemoveUser,
    onRemoveGroup,
    onSelectUser,
    onDeselectUser,
    onSelectGroup,
    onDeselectGroup,
    onAfterClose,
    isChanged,
  } = useMapper(open, localMappedUsers);

  const onSaveUsers = (): void => {
    onSave?.(currentUsers);
  };

  return (
    <ModalDialog
      includeDiscardChangesOverlay={isChanged}
      afterClose={onAfterClose}
      width={800}
      destroyOnClose
      header={t('LocalPermissionMapper.title')}
      open={open}
      closeFn={close}
      okText={t('LocalPermissionMapper.okButton.label')}
      okButtonProps={{ loading: isSaving }}
      cancelText={t('LocalPermissionMapper.cancelButton.label')}
      actionFn={onSaveUsers}
      body={
        <div className={styles.content}>
          <h3>{title}</h3>
          <div className={styles.formWrapper}>
            <UserSelect
              className={styles.formSelect}
              value={selectedData.users.map((user) => user.id)}
              dropdownStyle={{ maxWidth: 600, width: 600, minWidth: 600 }}
              onDeselect={onDeselectUser}
              onSelect={onSelectUser}
            />
            <Select
              showSearch
              className={styles.formSelect}
              mode="multiple"
              virtual={false}
              placeholder={t('LocalPermissionMapper.groups.select.placeholder')}
              dropdownStyle={{ maxWidth: 600, width: 600, minWidth: 600 }}
              value={selectedData.accessGroups.map((group) => group.id)}
              notFoundContent={<EmptyGroup />}
              maxTagCount={15}
              optionFilterProp="name"
              onDeselect={onDeselectGroup}
              onSelect={onSelectGroup}
              fieldNames={{ label: 'name', value: 'id' }}
              options={accessGroups.map((group) => ({
                id: group.id,
                name: group.name,
              }))}
            />
            <Button
              type="primary"
              onClick={onMoveUsers}
              className={styles.moveButton}
              disabled={!selectedData.accessGroups.length || !selectedData.users.length}
            >
              {t('LocalPermissionMapper.addButton.label')}
            </Button>
          </div>
          <div className={styles.userListWrapper}>
            <UserList users={currentUsers} onRemoveUser={onRemoveUser} onRemoveGroup={onRemoveGroup} />
          </div>
        </div>
      }
    />
  );
};

export default withSuspense(LocalPermissionMapper, { fallback: <Loader /> });
