import dayjs from 'dayjs';
import { DEFAULT_LOGOUT_TIMEOUT, INPUT_LISTENER_TIMEOUT } from '../const';
import { updateLogoutTime } from './updateLogoutTime';

let status = false;

function eventHandler(): void {
  if (!status) {
    updateLogoutTime(dayjs().add(DEFAULT_LOGOUT_TIMEOUT, 'ms'));
    status = true;
    setTimeout(() => {
      status = false;
    }, INPUT_LISTENER_TIMEOUT);
  }
}

export const addInputListener = (): void => {
  document.addEventListener('input', eventHandler);
};

export const removeInputListener = (): void => {
  document.removeEventListener('input', eventHandler);
};
