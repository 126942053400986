import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const getEitAllPositions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.UPDATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.UPDATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const getProjectNameViewPermission = (accountId: string, projectId: string): RequiredPermissions => [
  [
    { type: 'global', global: AvailableGlobalPermissions.PROJECT, value: AvailablePermissionsValue.READ },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.PROJECT,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'local',
      global: AvailableGlobalPermissions.PROJECT,
      local: projectId,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getPositionArchivePermission = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.ARCHIVE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.ARCHIVE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.ARCHIVE,
    },
  ],
];

export const getAssignmentListPermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.READ,
    },
  ],
];
export const getDemandListPermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getAssignmentCreatePermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.CREATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.CREATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.CREATE,
    },
  ],
];

export const getDemandCreatePermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.CREATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.CREATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.CREATE,
    },
  ],
];
