import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import { DEFAULT_LOGOUT_TIMEOUT } from '../const';
import { updateLogoutTime } from './updateLogoutTime';

export const useLocationChange = (): { enbableLocationListener: (enable: boolean) => void } => {
  const [enabled, setEnabled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!enabled) return;
    const time = dayjs().add(DEFAULT_LOGOUT_TIMEOUT);
    updateLogoutTime(time);
  }, [enabled, location.pathname]);

  return { enbableLocationListener: setEnabled };
};
