import React from 'react';
import AccountUpdateForm from 'components/Account/AccountUpdateForm/AccountUpdateForm';

import styles from './AccountUpdatePage.module.scss';

const AccountUpdatePage: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <AccountUpdateForm data-testId="account-update-form" />
    </div>
  );
};

export default AccountUpdatePage;
