import { isAxiosError } from 'axios';
import { TFunction } from 'react-i18next';
import { CommonError } from 'shared/api/types';
import { i18n } from 'shared/lib/i18n';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorText = (error: CommonError | Error | unknown, t: TFunction): string => {
  let translationCode = '';
  if (isAxiosError(error)) {
    translationCode = `Exception:${error.response?.data.service}.${error.response?.data.code}`;
    if (i18n.exists(translationCode)) {
      return t(translationCode);
    }
    return error.response?.data.message ?? error.message;
  }

  return t('Exception:UNDEFINED_ERROR');
};
