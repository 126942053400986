import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LeftArrow } from 'shared/assets/icons/dynamicColor/arrow-left.svg';
import { ReactComponent as RightArrow } from 'shared/assets/icons/dynamicColor/arrow-right.svg';

import styles from './TimelinesControlPanel.module.scss';

type ControlPanelProps = {
  quantityMonth: number;
  setQuantityMonth: (value: number) => void;
  prevMonth: () => void;
  nextMonth: () => void;
  returnToCurrentDate: () => void;
};

export const ControlPanel: React.FC<ControlPanelProps> = (props) => {
  const { quantityMonth, setQuantityMonth, prevMonth, nextMonth, returnToCurrentDate } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.switchMenu}>
      <div className={styles.switchMenuBlock}>
        <Button onClick={() => setQuantityMonth(1)} className={quantityMonth === 1 ? styles.btnActive : ''}>
          {t('Assignment:UserCard.Timelines.switchMenu.item.text', { count: 1 })}
        </Button>
        <Button onClick={() => setQuantityMonth(3)} className={quantityMonth === 3 ? styles.btnActive : ''}>
          {t('Assignment:UserCard.Timelines.switchMenu.item.text', { count: 3 })}
        </Button>
        <Button onClick={() => setQuantityMonth(6)} className={quantityMonth === 6 ? styles.btnActive : ''}>
          {t('Assignment:UserCard.Timelines.switchMenu.item.text', { count: 6 })}
        </Button>
      </div>
      <div className={styles.switchMenuBlock}>
        <Button onClick={prevMonth} className={styles.arrowBtn}>
          <LeftArrow />
        </Button>
        <Button onClick={() => returnToCurrentDate()}>
          {t('Assignment:UserCard.Timelines.switchMenu.todayBtn.text')}
        </Button>
        <Button onClick={nextMonth} className={styles.arrowBtn}>
          <RightArrow />
        </Button>
      </div>
    </div>
  );
};
