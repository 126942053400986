import { StateCreator, createStore } from 'zustand';
import { DevtoolsOptions, PersistOptions, devtools, persist } from 'zustand/middleware';

import { saveToken } from 'shared/lib/axios';
import { Maybe } from 'shared/types';

import { AuthUserDTO } from '../api/types';
import { persistTransformer } from '../lib/persistTransformer';

type Token = string;

interface AuthUser extends Omit<AuthUserDTO, 'permissions'> {
  permissions: Set<string>;
}

type State = {
  token: Maybe<Token>;
  refreshToken: Maybe<Token>;
  refreshTokenExpiredAt: Maybe<string>;
  authUser: Maybe<AuthUser>;
};

type Actions = {
  updateTokens: ({
    token,
    refreshToken,
    refreshTokenExpiredAt,
  }: {
    token: Token;
    refreshToken: Token;
    refreshTokenExpiredAt: string;
  }) => void;
  updateAuthUser: (user: Maybe<AuthUserDTO>) => void;
  reset: () => void;
};

export type AuthState = State & Actions;

const createSessionSlice: StateCreator<
  AuthState,
  [['zustand/devtools', never], ['zustand/persist', unknown]],
  [],
  AuthState
> = (set) => ({
  token: null,
  refreshToken: null,
  refreshTokenExpiredAt: null,
  authUser: null,
  updateTokens: ({ token, refreshToken, refreshTokenExpiredAt }) =>
    set({ token, refreshToken, refreshTokenExpiredAt }, false, 'updateTokens'),
  updateAuthUser: (user) =>
    set({ authUser: user ? { ...user, permissions: new Set(user?.permissions) } : null }, false, 'updateAuthUser'),
  reset: () => set({ token: null, refreshToken: null, refreshTokenExpiredAt: null, authUser: null }, false, 'reset'),
});

const persistOptions: PersistOptions<AuthState> = {
  name: 'auth',
  storage: persistTransformer,
  onRehydrateStorage: () => (state?: AuthState) => {
    if (state?.token) {
      saveToken(state.token);
    }
  },
};
const devtoolsOptions: DevtoolsOptions = { name: 'AuthStore' };

export const authStore = createStore<AuthState>()(
  devtools(persist(createSessionSlice, persistOptions), devtoolsOptions),
);

export const hasToken = (): boolean => Boolean(authStore.getState().token);
