import { useRef } from 'react';

export function useDebounceFunction<T>(func: (...args: T[]) => void, delay: number): (...args: T[]) => void {
  const ref = useRef<NodeJS.Timeout | null>(null);

  return (...args) => {
    if (ref.current) clearTimeout(ref.current);
    ref.current = setTimeout(() => func(...args), delay);
  };
}
