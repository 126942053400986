import { NotificationInstance } from 'antd/es/notification/interface';
import { ArgsProps } from 'antd/lib/notification';
import { TFunction } from 'react-i18next';

import { TNotification, notificationStore } from 'shared/model/notification';

import { getNotificationDuration } from './getNotificationDuration';

export const mapNotification = (
  notificationInstance: NotificationInstance,
  notification: Omit<TNotification, 'method'>,
  t: TFunction,
): ArgsProps => {
  const {
    type,
    message: rawMessage,
    description: rawDescription,
    onClose: onCloseRaw,
    duration = getNotificationDuration(type),
    btn: btnRaw,
    ...config
  } = notification;

  const message = Array.isArray(rawMessage) ? t(...rawMessage) : t(rawMessage ?? '');
  const description = Array.isArray(rawDescription) ? t(...rawDescription) : t(rawDescription ?? '');

  const onClose = (): void => {
    onCloseRaw?.();
    notificationStore.getState().closeNotifications(type, notification.key);
  };

  const btn = typeof btnRaw === 'function' ? btnRaw(notificationInstance) : btnRaw;

  return {
    message,
    description,
    onClose,
    duration,
    btn,
    ...config,
  };
};
