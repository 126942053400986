import dayjs, { Dayjs } from 'dayjs';
import { LanguageService } from 'features/changeLanguage';

interface MonthWithPercentage {
  name: string;
  percentage: number;
  isCurrentMonth: boolean;
}

const mainCurrentDate = dayjs();

export const getMonthsArray = (startDate: Dayjs, endDate: Dayjs): MonthWithPercentage[] => {
  const totalDays = endDate.diff(startDate, 'days') + 1;

  const months: MonthWithPercentage[] = [];
  let currentDate = startDate;

  const monthFormat = new Intl.DateTimeFormat(LanguageService.getCurrentLanguage(), { month: 'short' });

  while (!currentDate.isAfter(endDate)) {
    const monthName = monthFormat.formatToParts(currentDate.toDate()).find((part) => part.type === 'month')?.value;
    if (!monthName) {
      return [];
    }
    const capitalizedMonthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

    const startOfMonth = currentDate.startOf('month');
    const endOfMonth = currentDate.endOf('month');

    const daysInMonth = endOfMonth.diff(startOfMonth, 'days') + 1;

    months.push({
      name: `${capitalizedMonthName} ${currentDate.year()}`,
      percentage: (daysInMonth / totalDays) * 100,
      isCurrentMonth: currentDate.isSame(mainCurrentDate, 'month'),
    });

    currentDate = currentDate.add(1, 'month');
  }

  return months;
};
