import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { hasToken } from 'entities/session';

import { pathKeys } from 'shared/lib/react-router';

import LogOutPage from './LogOutPage';

export const logOutPageRoute: RouteObject = {
  path: pathKeys.logout(),
  element: createElement(LogOutPage),
  loader: async (args) => {
    if (!hasToken()) {
      return redirect(pathKeys.login());
    }

    return args;
  },
};
