/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';
import { Avatar as AntdAvatar } from 'antd';
import { GroupProps } from 'antd/lib/avatar';

import styles from './Group.module.scss';

interface IAvatarGroup extends Omit<GroupProps, 'children' | 'maxCount'> {
  avatars: ReactNode[];
  totalCount?: number;
  maxVisibleCount?: number;
}

const Group: React.FC<IAvatarGroup> = (props) => {
  const { avatars, totalCount, maxVisibleCount, ...otherAvatarGroupProps } = props;
  let excessAvatarsCount = 0;

  if (maxVisibleCount) {
    if (totalCount && totalCount > maxVisibleCount) {
      excessAvatarsCount = totalCount - maxVisibleCount;
    } else if (avatars.length > maxVisibleCount) {
      excessAvatarsCount = avatars.length - maxVisibleCount;
    }

    avatars.splice(maxVisibleCount, avatars.length - maxVisibleCount);
  }

  const maxCount = excessAvatarsCount > 0 && maxVisibleCount ? maxVisibleCount + 1 : maxVisibleCount;

  return (
    <AntdAvatar.Group
      className={styles.avatarGroup}
      max={{ count: maxCount }}
      data-testid="group"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherAvatarGroupProps}
    >
      {avatars}
      {excessAvatarsCount > 0 ? (
        <AntdAvatar
          key="excess-avatars-count"
          className={styles.excessAvatarsCounter}
          data-testid="excess-avatars-counter"
        >
          +{excessAvatarsCount}
        </AntdAvatar>
      ) : null}
    </AntdAvatar.Group>
  );
};

export default React.memo(Group);
