import { useCallback, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Select, SelectProps, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { EmptyGroup } from 'shared/ui/EmptyGroup';

import { usersService } from '../../api/queries';
import { ShortUser } from '../../api/types';

interface IUserSelectProps extends Pick<SelectProps, 'onDeselect' | 'value' | 'className' | 'dropdownStyle'> {
  onSelect: (user?: ShortUser) => void;
}

export const UserSelect: React.FC<IUserSelectProps> = ({ onSelect, ...props }) => {
  const { t } = useTranslation('ReusableComponents');
  const [query, setQuery] = useState('');

  const { data: users, isFetching: isLoadingUsers } = useQuery(usersService.queryOptions({ query }));

  const onSelectWrapper = useCallback(
    async (id: string): Promise<void> => {
      await onSelect(users.content.find((user) => user.id === id));
      setQuery('');
    },
    [onSelect, users],
  );

  return (
    <Select
      showSearch
      mode="multiple"
      filterOption={false}
      maxTagCount={15}
      virtual={false}
      placeholder={t('UserSelect.placeholder')}
      onSearch={setQuery}
      searchValue={query}
      onDropdownVisibleChange={(open) => {
        if (open) {
          setQuery('');
        }
      }}
      notFoundContent={isLoadingUsers ? <Skeleton active paragraph={{ rows: 5 }} title={false} /> : <EmptyGroup />}
      onSelect={onSelectWrapper}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {isLoadingUsers
        ? null
        : users?.content?.map((user) => (
            <Select.Option key={user.id} value={user.id}>
              {user.firstName} {user.lastName}
            </Select.Option>
          ))}
    </Select>
  );
};
