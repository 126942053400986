import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'antd/lib/form/Form';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { isHasAccess } from 'features/validateAccess';
import { useCreatePositionMutation } from 'entities/position';
import { projectService } from 'entities/project';
import { DEFAULT_DATE_FORMAT_FOR_REQUESTS, CURRENCY_LIST } from 'shared/config';
import { convertDayJsToString } from 'shared/lib/dayjs';
import { pathKeys } from 'shared/lib/react-router';

import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';
// eslint-disable-next-line max-len
import PositionCreateUpdateFormItems from 'components/PositionAndAssignment/PositionCreateUpdateFormItems/PositionCreateUpdateFormItems';
import RateList from 'components/RateList/RateList';

import { getProjectNameViewPermission } from '../Position.permissions';

import styles from './PositionCreateForm.module.scss';

export type PositionFormType = {
  name: string;
  description: string;
  startDate: Dayjs;
  endDate?: Dayjs;
  rates: {
    currency: typeof CURRENCY_LIST[number];
    value: string;
    startDate: Dayjs;
    endDate?: Dayjs;
  }[];
};

const PositionCreateForm: React.FC<{ accountId: string; projectId: string }> = ({ accountId, projectId }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [form] = useForm<PositionFormType>();

  const [preventNavigation, setPreventNavigation] = useState(false);

  const {
    mutate: createMutation,
    isPending: isCreating,
    isError: hasCreatingErrors,
  } = useCreatePositionMutation(accountId, projectId);

  const canSeeProjectName = isHasAccess(getProjectNameViewPermission(accountId, projectId));

  const { data: project, isLoading: isProjectLoading } = useQuery(
    projectService.queryOptions(projectId, canSeeProjectName),
  );

  const checkFormValuesModified = useCallback((): void => {
    const values = form.getFieldsValue();

    const { rates } = values;

    const isNameChanged = !!values.name;
    const isDescriptionChanged = !!values.description;
    const isDatesChanged = Boolean(values.startDate || values.endDate);

    const isRatesChanged = rates.reduce((isChange, rate) => {
      return (
        isChange ||
        (!!rate.value && rate.value !== '0') ||
        rate.currency !== CURRENCY_LIST[0] ||
        !!rate.startDate ||
        !!rate.endDate
      );
    }, false);

    setPreventNavigation(isNameChanged || isDatesChanged || isRatesChanged || isDescriptionChanged);
  }, [form]);

  useEffect(() => {
    if (hasCreatingErrors) {
      checkFormValuesModified();
    }
  }, [checkFormValuesModified, hasCreatingErrors]);

  const onFormSubmit = (values: PositionFormType): void => {
    const { name, description, startDate, endDate, rates } = values;
    const positionStartDate = convertDayJsToString(startDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS);
    const positionEndDate = endDate ? convertDayJsToString(endDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS) : null;

    setPreventNavigation(false);

    const rateList = rates.map((item, index) => {
      return {
        index,
        value: Number(item.value),
        currency: item.currency,
        startDate: convertDayJsToString(item.startDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS),
        endDate: item.endDate ? convertDayJsToString(item.endDate, DEFAULT_DATE_FORMAT_FOR_REQUESTS) : null,
      };
    });

    createMutation({
      projectId,
      description,
      name: name.trim(),
      startDate: positionStartDate,
      endDate: positionEndDate,
      rates: rateList,
    });
  };

  return (
    <CreateUpdateFormHolder
      title={t('Position:PositionCreateForm.heading')}
      subtitle={project?.name}
      onFormSubmit={onFormSubmit}
      onCancel={() => navigate(pathKeys.position.root({ accountId, projectId }))}
      isLoading={isCreating}
      initialData={{
        rates: [
          {
            value: '0',
            currency: CURRENCY_LIST[0],
          },
        ],
      }}
      isLoadingData={isProjectLoading}
      customFormControl={[form]}
      onFormChange={checkFormValuesModified}
      preventNavigation={preventNavigation}
    >
      <div className={styles.rootWrapper}>
        <div className={styles.formItems}>
          <PositionCreateUpdateFormItems isLoading={isCreating} />
        </div>
        <div className={styles.rateWrapper}>
          <RateList />
        </div>
      </div>
    </CreateUpdateFormHolder>
  );
};

export default PositionCreateForm;
