import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const DEMAND_RECRUITER_CHANGE_PERMISSIONS: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const getDemandGlobalEditPermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.UPDATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.UPDATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const getDemandLocalEditPermissions = (demandId: string): RequiredPermissions => [
  [
    {
      type: 'local',
      global: AvailableGlobalPermissions.DEMAND,
      local: demandId,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const getDemandReadPermission = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const getDemandArchivePermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.ARCHIVE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.ARCHIVE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.DEMAND,
      value: AvailablePermissionsValue.ARCHIVE,
    },
  ],
];
