import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Form } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ArchiveEntityModal } from 'features/archiveEntity';
import { isHasAccess } from 'features/validateAccess';
import { assignmentService, useArchiveAssignmentMutation, useUpdateAssignmentMutation } from 'entities/assignment';
import { positionService } from 'entities/position';

import { DEFAULT_DATE_FORMAT_FOR_REQUESTS } from 'shared/config';
import { convertDayJsToString, convertToDayJs } from 'shared/lib/dayjs';
import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';

import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';

import { getAssignmentArchivePermissions } from '../Assignment.permissions';
import AssignmentCreateUpdateFormItems from '../AssignmentCreateUpdateFormItems/AssignmentCreateUpdateFormItems';

const AssignmentUpdateForm: React.FC<{
  accountId: string;
  projectId: string;
  positionId: string;
  assignmentId: string;
}> = ({ accountId, projectId, positionId, assignmentId }) => {
  const { t } = useTranslation();

  const [visibleArchiveDialog, setVisibleArchiveDialog] = useState(false);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [preventNavigation, setPreventNavigation] = useState(false);

  const { data: position } = useSuspenseQuery(positionService.queryOptions(positionId));

  const { data: assignment } = useSuspenseQuery(assignmentService.queryOptions(assignmentId));

  const {
    mutate: updateAssignmentMutation,
    isPending: isUpdating,
    isError: hasUpdatingErrors,
    error: updatingErrors,
  } = useUpdateAssignmentMutation(accountId, projectId, assignmentId);

  const {
    mutate: archiveAssignmentMutation,
    isError: hasArchivingErrors,
    isPending: isArchiving,
    reset: resetArchiveMutation,
  } = useArchiveAssignmentMutation(accountId, projectId, assignmentId, assignment.userId);

  const canArchiveAssignment = isHasAccess(getAssignmentArchivePermissions(accountId, projectId));

  const initData = useCallback((): Store => {
    const { userId, startDate, endDate, involvement, billedInvolvement } = assignment;

    return {
      user: userId,
      date: [convertToDayJs(startDate), convertToDayJs(endDate)],
      involvement,
      billedInvolvement,
    };
  }, [assignment]);

  const checkFormValuesModified = useCallback((): void => {
    const values = form.getFieldsValue();
    const initialData = initData();

    const isSameUserId = initialData.user === values.user;
    const isSameDates =
      Array.isArray(values.date) &&
      (initialData.date[0] as Dayjs).isSame(values.date[0], 'day') &&
      (initialData.date[1] as Dayjs).isSame(values.date[1], 'day');
    const isSameInvolvement = initialData.involvement === Number(values.involvement);
    const isSameBilledInvolvement = initialData.billedInvolvement === Number(values.billedInvolvement);

    setPreventNavigation(!(isSameUserId && isSameDates && isSameInvolvement && isSameBilledInvolvement));
  }, [form, initData]);

  useEffect(() => {
    if (form.isFieldsTouched() && (hasUpdatingErrors || hasArchivingErrors)) {
      checkFormValuesModified();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdatingErrors, hasArchivingErrors]);

  const closeModal = (): void => {
    setVisibleArchiveDialog(false);
    resetArchiveMutation();
  };

  const archiveAssignment = (): void => {
    setPreventNavigation(false);
    archiveAssignmentMutation(assignment.id);
  };
  const onFormSubmit = (values: Store): void => {
    const { user, date, involvement, billedInvolvement } = values;
    const startDate = convertDayJsToString(date[0], DEFAULT_DATE_FORMAT_FOR_REQUESTS);
    const endDate = convertDayJsToString(date[1], DEFAULT_DATE_FORMAT_FOR_REQUESTS);
    setPreventNavigation(false);

    updateAssignmentMutation({
      positionId,
      id: assignment?.id ?? '',
      userId: user,
      startDate,
      endDate,
      involvement,
      billedInvolvement,
      version: assignment?.version ?? 0,
    });
  };

  const isInvolvementsEqual = useMemo(() => assignment?.involvement === assignment?.billedInvolvement, [assignment]);
  return (
    <CreateUpdateFormHolder
      title={t('Assignment:AssignmentUpdateForm.header')}
      subtitle={position?.name}
      onFormSubmit={onFormSubmit}
      onCancel={() => navigate(pathKeys.position.root({ accountId, projectId }))}
      isLoading={isUpdating}
      customFormControl={[form]}
      onClickArchiveButton={() => setVisibleArchiveDialog(true)}
      editMode
      initialData={initData()}
      onFormChange={checkFormValuesModified}
      preventNavigation={preventNavigation}
      hideDeleteBtn={!canArchiveAssignment}
    >
      <AssignmentCreateUpdateFormItems
        isLoading={isUpdating}
        editMode
        isInvolvementsEqual={isInvolvementsEqual}
        isDatesIntersected={updatingErrors?.response?.data.code === 'PositionAlreadyAssignedException'}
        onChangeFn={checkFormValuesModified}
        position={position}
        assignment={assignment}
      />
      <ArchiveEntityModal
        open={visibleArchiveDialog}
        isLoading={isArchiving}
        type="normal"
        closeModal={closeModal}
        archiveEntity={archiveAssignment}
        normalText={t('Assignment:AssignmentUpdateForm.archiveModal.archive.body.text')}
      />
    </CreateUpdateFormHolder>
  );
};

export default withSuspense(AssignmentUpdateForm, { fallback: <Loader /> });
