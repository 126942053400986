import { axiosInstance } from 'shared/lib/axios';
import { PermissionDTO } from './types';

export const permissionListGet = (): Promise<PermissionDTO[]> => {
  return axiosInstance.get('/api/auth/permissions/all');
};

export const getUserPermissions = (userId: string): Promise<string[]> => {
  return axiosInstance.get(`/api/auth/users/permissions/${userId}`);
};
