import React from 'react';
import { useParams } from 'react-router-dom';
import DemandUpdateForm from 'components/Demand/DemandUpdateForm/DemandUpdateForm';

import styles from './DemandUpdatePage.module.scss';

const DemandUpdatePage: React.FC = () => {
  const { accountId = '', projectId = '', positionId = '', demandId = '' } = useParams();
  return (
    <div className={styles.wrapper}>
      <DemandUpdateForm accountId={accountId} projectId={projectId} positionId={positionId} demandId={demandId} />
    </div>
  );
};

export default DemandUpdatePage;
