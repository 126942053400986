import { AxiosRequestConfig } from 'axios';

import { axiosInstance } from 'shared/lib/axios';

import {
  AGWithUsersDTO,
  SearchDTO,
  AGWithUsersRequest,
  UserListByAGIdWithCountRequest,
  UserListRequest,
  PageUserSearchDTO,
  AvailableUserListRequest,
  PageUsersAvailabilityDTO,
  UserDTO,
  MappedUserDTO,
  LocalMappedUsersRequest,
} from './types';

export const userListGetByAccessGroupId = (
  payload: AGWithUsersRequest,
  config?: AxiosRequestConfig,
): Promise<SearchDTO> => {
  const { accessGroupId, ...params } = payload;
  return axiosInstance.get(`/api/user/search/getAll/${accessGroupId}`, {
    ...config,
    params,
  });
};

export const userListGetByAccessGroupIdWithCount = (
  params: UserListByAGIdWithCountRequest,
  config?: AxiosRequestConfig,
): Promise<AGWithUsersDTO[]> => {
  return axiosInstance.get(`/api/user/access_groups`, {
    ...config,
    params,
  });
};

export const userListGetByQuery = (
  params: UserListRequest,
  config?: AxiosRequestConfig,
): Promise<PageUserSearchDTO> => {
  return axiosInstance.get(`/api/user/search/getAll`, { ...config, params });
};

export const availableUserListGetByQuery = (
  payload: AvailableUserListRequest,
  config?: AxiosRequestConfig,
): Promise<PageUsersAvailabilityDTO> => {
  const { periodStart, periodEnd, ...params } = payload;
  return axiosInstance.post(
    '/api/user/search/getAll/availability',
    {
      periodStart,
      periodEnd,
    },
    {
      ...config,
      params,
    },
  );
};

export const userItemGetById = (userId: string, config?: AxiosRequestConfig): Promise<UserDTO> => {
  return axiosInstance.get(`/api/user/${userId}`, config);
};

export const authenticatedUserInfoGet = (config?: AxiosRequestConfig): Promise<UserDTO> => {
  return axiosInstance.get(`/api/user/this`, config);
};

export const localMappedUsersGet = (
  { entityType, entityId, sort = 'firstName,asc' }: LocalMappedUsersRequest,
  config?: AxiosRequestConfig,
): Promise<MappedUserDTO[]> => {
  return axiosInstance.get(`/api/user/mapped/${entityType}/${entityId}`, { ...config, params: { sort, size: 1000 } });
};
