/* eslint-disable react/jsx-props-no-spreading */
import { Link, LinkProps } from 'react-router-dom';

const ProtectedLink: React.FC<LinkProps & { hasAccess: boolean }> = ({ hasAccess, ...props }) => {
  if (!hasAccess) {
    return <span {...props} />;
  }

  return <Link {...props} />;
};

export default ProtectedLink;
