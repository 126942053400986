import { UseMutationResult, useMutation, queryOptions, keepPreviousData, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
// It's a bad. Let's think about it.
import { keys as accountKeys } from 'entities/account';

import { CommonError } from 'shared/api/types';
import { handleRequestError } from 'shared/lib/axios';
import { queryClient } from 'shared/lib/react-query';
import { pathKeys } from 'shared/lib/react-router';
import { addNotification } from 'shared/model/notification';

import { getLinkButton } from 'shared/ui/LinkButton';

import {
  projectItemArchive,
  projectItemCreate,
  projectItemGet,
  projectItemUpdate,
  projectListGet,
  projectNamesGetByIds,
} from './requests';

import {
  ProjectDTO,
  ProjectCreateRequest,
  ProjectList,
  ProjectListRequest,
  ProjectUpdateRequest,
  ProjectsNamesDTO,
} from './types';

export const keys = {
  root: () => ['project'] as const,
  list: () => [...keys.root(), 'list'] as const,
  listWithFilters: (filters: ProjectListRequest) => [...keys.root(), 'list', filters] as const,
  item: (id: string) => [...keys.root(), 'item', id] as const,
  create: () => [...keys.root(), 'create'] as const,
  update: (id: string) => [...keys.root(), 'update', id] as const,
  archive: (id: string) => [...keys.root(), 'archive', id] as const,
  names: () => [...keys.root(), 'names'] as const,
  namesByIds: (ids: string[]) => [...keys.root(), 'names', ids] as const,
};

export const projectsService = {
  queryKey: (filters: ProjectListRequest) => keys.listWithFilters(filters),

  getCache: (filters: ProjectListRequest) => queryClient.getQueryData<ProjectList>(projectsService.queryKey(filters)),

  queryOptions: (filters: ProjectListRequest) => {
    const projectsKey = projectsService.queryKey(filters);
    return queryOptions({
      queryKey: projectsKey,
      queryFn: async ({ signal }) => {
        const projects = await projectListGet(filters, { signal });
        return projects;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => projectsService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(projectsKey)?.dataUpdatedAt,
      placeholderData: keepPreviousData,
    });
  },

  prefetchQuery: async (filters: ProjectListRequest) =>
    queryClient.prefetchQuery(projectsService.queryOptions(filters)),
};

export const projectService = {
  queryKey: (id: string) => keys.item(id),

  getCache: (id: string) => queryClient.getQueryData<ProjectDTO>(projectService.queryKey(id)),

  setCache: (project: ProjectDTO) => queryClient.setQueryData(projectService.queryKey(project.id), project),

  removeCache: (id: string) => queryClient.removeQueries({ queryKey: projectService.queryKey(id) }),

  queryOptions: (id: string, enabled = true) => {
    const projectKey = projectService.queryKey(id);
    return queryOptions({
      queryKey: projectKey,
      queryFn: async ({ signal }) => {
        const project = await projectItemGet(id, { signal });
        return project;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => projectService.getCache(id)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(projectKey)?.dataUpdatedAt,
      enabled,
    });
  },

  prefetchQuery: async (id: string) => queryClient.prefetchQuery(projectService.queryOptions(id)),
};

export const useCreateProjectMutation = (): UseMutationResult<ProjectDTO, CommonError, ProjectCreateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.create(),
    mutationFn: projectItemCreate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: accountKeys.list() });
      projectService.setCache(data);
      navigate(pathKeys.project.root({ accountId: data.accountId }));
      addNotification({
        method: 'success',
        type: 'short',
        description: 'Project:ProjectsPage.notification.successfullyCreated.description',
        btn: getLinkButton(
          pathKeys.position.root({ accountId: data.accountId, projectId: data.id }),
          'Project:ProjectsPage.notification.successfullyCreated.button.label',
        ),
      });
    },
    onError: (error) => {
      if (error.response?.data.code === 'ItemNotFoundException') {
        addNotification({
          type: 'short',
          method: 'error',
          description: 'Project:ProjectCreatePage.notification.notFound.description',
        });
        return;
      }
      if (error.response?.data.code !== 'ItemAlreadyExistsException') {
        handleRequestError(error);
      }
    },
  });
};

export const useUpdateProjectMutation = (
  id: string,
): UseMutationResult<ProjectDTO, CommonError, ProjectUpdateRequest> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  const updateKey = keys.update(id);

  return useMutation({
    mutationKey: updateKey,
    mutationFn: projectItemUpdate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      projectService.setCache(data);
      navigate(pathKeys.project.root({ accountId: data.accountId }));
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Project:ProjectsPage.notification.successfullyUpdated.description',
      });
    },
    onError: (error) => {
      if (error.response?.data.code === 'ItemNotFoundException') {
        addNotification({
          description: 'Project:ProjectUpdatePage.notification.notFound.description',
          type: 'short',
          method: 'error',
        });
        return;
      }
      if (error.response?.data.code !== 'ItemAlreadyExistsException') {
        handleRequestError(error);
      }
    },
  });
};

export const useArchiveProjectMutation = (id: string): UseMutationResult<void, CommonError, string> => {
  const navigate = useNavigate();
  const qClient = useQueryClient();

  const archiveKey = keys.archive(id);

  return useMutation({
    mutationKey: archiveKey,
    mutationFn: projectItemArchive,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: keys.list() });
      qClient.invalidateQueries({ queryKey: accountKeys.list() });
      const accountId = projectService.getCache(id)?.accountId ?? '';
      projectService.removeCache(id);
      navigate(pathKeys.project.root({ accountId }));
      addNotification({
        type: 'short',
        method: 'success',
        description: 'Project:ProjectsPage.notification.successfullyArchived.description',
      });
    },
    onError: (error) => {
      if (error.response?.data.code !== 'PositionHasUnarchivedAssignmentException') {
        handleRequestError(error);
      }
    },
  });
};

export const projectsNamesService = {
  queryKey: (ids: string[]) => keys.namesByIds(ids),

  getCache: (ids: string[]) => queryClient.getQueryData<ProjectsNamesDTO[]>(projectsNamesService.queryKey(ids)),

  removeCache: () => queryClient.removeQueries({ queryKey: keys.names() }),

  queryOptions: (ids: string[]) => {
    const projectNamesKey = projectsNamesService.queryKey(ids);
    return queryOptions({
      queryKey: projectNamesKey,
      queryFn: async ({ signal }) => {
        const names = await projectNamesGetByIds(ids, { signal });
        return names;
      },
      enabled: ids.length > 0,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => projectsNamesService.getCache(ids)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(projectNamesKey)?.dataUpdatedAt,
    });
  },
};
