import React from 'react';
import { Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { RcFile, UploadFile, UploadProps } from 'antd/lib/upload/interface';

import { IUploaderProps } from './Uploader.types';

// Because the standard behavior provides uploading data to the server,
// We must include the request stub and change the download status manually
const Uploader: React.FC<IUploaderProps> = (props) => {
  const {
    onChange,
    onLoading,
    onInvalidFile,
    accept,
    sizeLimit,
    children,
    showUploadList = false,
    ...defaultProps
  } = props;

  const beforeUpload: UploadProps['beforeUpload'] = (file) => {
    const isFormatAccepted = accept ? accept.includes(file.type) : true;
    const isSizeAccepted = sizeLimit ? file.size <= sizeLimit : true;

    if (typeof onInvalidFile !== 'undefined') {
      if (!isFormatAccepted) {
        onInvalidFile('format-error');
      }
      if (!isSizeAccepted) {
        onInvalidFile('size-limit');
      }
    }

    return isFormatAccepted && isSizeAccepted;
  };

  const handleChange = async (info: UploadChangeParam<UploadFile>): Promise<void> => {
    if (info.file.status === 'uploading') {
      // eslint-disable-next-line no-param-reassign
      info.file.status = 'done'; // manually set upload status
    }

    if (info.file.status === 'done') {
      const reader = new FileReader();
      reader.onloadstart = () => {
        if (typeof onLoading !== 'undefined') {
          onLoading({ name: info.file.name, status: 'loading' });
        }
      };
      reader.onload = () => {
        if (!reader.result) {
          throw new Error('Unexpected error');
        }

        if (onChange) {
          onChange({
            uid: info.file.uid,
            file: reader.result as string,
            name: info.file.name,
            status: 'success',
            error: null,
          });
        }
      };
      reader.onerror = () => {
        if (!reader.error) {
          throw new Error('Unexpected error');
        }
        if (onChange) {
          onChange({
            uid: info.file.uid,
            file: null,
            name: info.file.name,
            status: 'error',
            error: reader.error,
          });
        }
      };
      reader.readAsDataURL(info.file.originFileObj as RcFile);
    }

    if (info.file.status === 'removed') {
      if (onChange) {
        onChange({
          uid: info.file.uid,
          file: null,
          name: info.file.name,
          status: 'removed',
          error: null,
        });
      }
    }

    return new Promise((resolve) => {
      resolve();
    });
  };

  // disable default behavior
  const customRequest: UploadProps['customRequest'] = ({ onSuccess }) => {
    if (typeof onSuccess !== 'undefined') {
      onSuccess('');
    }
  };

  return (
    <Upload
      customRequest={customRequest}
      accept={Array.isArray(accept) ? accept.join(',') : undefined}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      showUploadList={showUploadList}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...defaultProps}
    >
      {children}
    </Upload>
  );
};

export default Uploader;
