import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';
import { demandParentInfoService } from 'entities/demand';
import { positionService } from 'entities/position';

import { pathKeys } from 'shared/lib/react-router';

import DemandCreatePage from './DemandCreatePage';

export const demandCreatePageRoute: RouteObject = {
  path: ':positionId/demands/create/',
  element: createElement(DemandCreatePage),
  loader: async (args) => {
    const requiredPermissions = replacePermissions(pathPermissions.demand.create, args.params);
    if (!isHasAccess(requiredPermissions)) {
      return redirect(pathKeys.dashboard());
    }

    if (!args.params.positionId) {
      return redirect(pathKeys.page404());
    }

    Promise.all([
      positionService.prefetchQuery(args.params.positionId),
      demandParentInfoService.prefetchQuery(args.params.positionId),
    ]);

    return args;
  },
};
