import React, { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';

import { Skeleton, Table, TableProps } from 'antd';
import { SorterResult } from 'antd/lib/table/interface';

import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import { useTranslation } from 'react-i18next';
import { areEqual, VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

import { EmptyGroup } from 'shared/ui/EmptyGroup';

import { ICustomColumnType, IVirtualTableProps } from './VirtualTable.types';
import styles from './VirtualTable.module.scss';

/**
 * @param { width } columns - should be passed in pixels number or percent string with %
 * @param { title } columns - should be passed i18n code
 * @param { key } columns - sorting key
 * @param onSort - should be passed function that handles sorting changes
 */
function VirtualTable<T extends object = never>({
  columns,
  scroll,
  dataSource,
  rowHeight,
  loadNextPage,
  hasNextPage,
  isNextPageLoading,
  threshold = 10,
  onSort,
  loaderRef,
}: IVirtualTableProps<T>): React.ReactElement {
  const { t } = useTranslation();

  const [currentHeight, setCurrentHeight] = useState(0);
  const [tableWidth, setTableWidth] = useState(0);
  const getRemainingWidthForColumnsWithoutWidth = useCallback((): number => {
    return columns.reduce((sum, column) => {
      switch (typeof column.width) {
        case 'string':
          return sum - (tableWidth * parseFloat(column.width)) / 100;
        case 'number':
          return sum - column.width;
        default:
          return sum;
      }
    }, tableWidth);
  }, [columns, tableWidth]);

  const extendedColumns = useMemo((): ICustomColumnType<T>[] => {
    const remainingWidth = getRemainingWidthForColumnsWithoutWidth();
    const columnsWithoutWidthCount = columns.filter(({ width }) => !width).length;

    return columns.map((column) => {
      if (column.width) {
        return {
          ...column,
          title: t(column.title),
          width: typeof column.width === 'number' ? column.width : (tableWidth * parseFloat(column.width)) / 100,
          sorter: true,
        };
      }

      return {
        ...column,
        title: t(column.title),
        width: remainingWidth / columnsWithoutWidthCount,
        sorter: true,
      };
    });
  }, [columns, getRemainingWidthForColumnsWithoutWidth, t, tableWidth]);

  const itemCount = hasNextPage ? dataSource.length + 1 : dataSource.length;
  const loadMoreData = useMemo(
    () => (isNextPageLoading ? () => null : loadNextPage),
    [isNextPageLoading, loadNextPage],
  );
  const isItemLoaded = useCallback(
    (index: number): boolean => !hasNextPage || index < dataSource?.length,
    [dataSource, hasNextPage],
  );

  const renderCell = useCallback((column: ICustomColumnType<T>, data: never): ReactNode => {
    return column.customRenderCellRenderer ? (
      column.customRenderCellRenderer(data)
    ) : (
      <div>
        <div className={styles.overflow}>{data}</div>
      </div>
    );
  }, []);

  const renderRow = useCallback(
    (data: Record<string, never>, rowIndex: number): ReactElement[] => {
      return extendedColumns.map((column, columnIndex) => {
        const currentIndex = column.dataIndex as string;
        return (
          <div
            key={currentIndex}
            style={{
              maxWidth: columnIndex !== extendedColumns.length - 1 ? (column.width as number) : undefined,
              height: rowHeight,
            }}
            className={classNames(styles.tableCell, 'ant-table-cell')}
            data-testid="table-cell"
          >
            {isItemLoaded(rowIndex) ? (
              renderCell(column, data[column.dataIndex as string])
            ) : (
              <Skeleton active paragraph={{ rows: 0 }} title={{ width: '60%' }} />
            )}
          </div>
        );
      });
    },
    [extendedColumns, isItemLoaded, renderCell, rowHeight],
  );

  const renderVirtualList = (rawData: readonly T[]): React.ReactNode => {
    return (
      <InfiniteLoader
        ref={loaderRef}
        isItemLoaded={isItemLoaded}
        itemCount={itemCount}
        loadMoreItems={loadMoreData}
        threshold={threshold}
        minimumBatchSize={5}
      >
        {({ onItemsRendered, ref: listRef }) => (
          <List
            ref={listRef}
            className="ant-table-tbody"
            height={currentHeight - rowHeight}
            itemSize={() => rowHeight}
            itemCount={itemCount}
            onItemsRendered={onItemsRendered}
            width={tableWidth}
          >
            {({ style, index }) => (
              <div
                style={{ ...style }}
                className={classNames('ant-table-row', 'ant-table-row-level-0', styles.tableRow)}
                data-testid="table-row"
              >
                {renderRow(rawData[index] as Record<string, never>, index)}
              </div>
            )}
          </List>
        )}
      </InfiniteLoader>
    );
  };

  const renderEmptyList = (): React.ReactNode => {
    return (
      <div style={{ height: currentHeight - rowHeight }} className={styles.emptyList}>
        <EmptyGroup />
      </div>
    );
  };

  const onChange: TableProps<T>['onChange'] = (_, __, sorter, extra) => {
    if (extra.action === 'sort') {
      const currentSorter = sorter as SorterResult<T>;
      onSort(currentSorter?.columnKey, currentSorter.order);
    }
  };

  return (
    <ResizeObserver
      onResize={({ width, height }) => {
        setTableWidth(width);
        setCurrentHeight(height);
      }}
    >
      <Table
        className={styles.virtualTable}
        columns={extendedColumns}
        pagination={false}
        components={{
          body: itemCount === 0 && !isNextPageLoading ? renderEmptyList : renderVirtualList,
        }}
        bordered
        scroll={{ x: scroll.x, y: currentHeight }}
        dataSource={dataSource}
        showSorterTooltip={false}
        onChange={onChange}
      />
    </ResizeObserver>
  );
}

export default React.memo(VirtualTable, areEqual);
