import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';
import { accountService } from 'entities/account';
import { pathKeys } from 'shared/lib/react-router';

import AccountUpdatePage from './AccountUpdatePage';

export const accountUpdatePageRoute: RouteObject = {
  path: ':accountId/edit/',
  element: createElement(AccountUpdatePage),
  loader: async (args) => {
    const requiredPermissions = replacePermissions(pathPermissions.account.update, args.params);
    if (!isHasAccess(requiredPermissions)) {
      return redirect(pathKeys.dashboard());
    }

    if (!args.params.accountId) {
      return redirect(pathKeys.page404());
    }

    Promise.resolve(accountService.prefetchQuery(args.params.accountId));

    return args;
  },
};
