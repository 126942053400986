import React from 'react';
import { useParams } from 'react-router-dom';

import PositionCreateForm from 'components/PositionAndAssignment/PositionCreateForm/PositionCreateForm';

const PositionCreatePage: React.FC = () => {
  const { accountId = '', projectId = '' } = useParams();

  return <PositionCreateForm accountId={accountId} projectId={projectId} data-testid="position-create-form" />;
};

export default PositionCreatePage;
