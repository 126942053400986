import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';
import { pathKeys } from 'shared/lib/react-router';

import PositionCreatePage from './PositionCreatePage';

export const positionCreatePageRoute: RouteObject = {
  path: 'create/',
  element: createElement(PositionCreatePage),
  loader: async (args) => {
    const requiredPermissions = replacePermissions(pathPermissions.position.create, args.params);
    if (!isHasAccess(requiredPermissions)) {
      return redirect(pathKeys.dashboard());
    }

    if (!args.params.projectId || !args.params.accountId) {
      return redirect(pathKeys.page404());
    }

    return args;
  },
};
