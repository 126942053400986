import React, { useMemo } from 'react';
import { Form, Input, UploadFile } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { addNotification } from 'shared/model/notification';
import { ImageUploader } from 'shared/ui/ImageUploader';
import { IUploaderProps } from 'shared/ui/Uploader';

import {
  LOGO_ACCEPT_FORMATS,
  DEFAULT_ACCOUNT_DESCRIPTION_MAX_LENGTH,
  DEFAULT_ACCOUNT_NAME_MAX_LENGTH,
  DEFAULT_ACCOUNT_NAME_MIN_LENGTH,
  LOGO_SIZE_LIMIT,
} from '../Account.const';

import styles from './AccountCreateUpdateFormItems.module.scss';

type AccountCreateUpdateFormItemsType = {
  isLoading: boolean;
  initialLogo?: string;
};

const AccountCreateUpdateFormItems: React.FC<AccountCreateUpdateFormItemsType> = (props) => {
  const { isLoading, initialLogo } = props;
  const { t } = useTranslation();

  const inputSpaceValidator = (_: RuleObject, value: StoreValue): Promise<void> => {
    return value.trim().length ? Promise.resolve() : Promise.reject();
  };

  const initLogo = useMemo((): undefined | UploadFile[] => {
    if (!initialLogo) {
      return undefined;
    }

    return [{ url: initialLogo, name: 'logo', uid: uuidv4(), status: 'done' }];
  }, [initialLogo]);

  const handlerInvalidAccountLogo: IUploaderProps['onInvalidFile'] = (code): void => {
    if (code === 'format-error') {
      addNotification({
        type: 'short',
        description: 'Account:AccountCreateUpdateFormItems.handlerInvalidAccountLogo.notification.formatError.text',
        method: 'error',
      });
    }
    if (code === 'size-limit') {
      addNotification({
        type: 'short',
        description: 'Account:AccountCreateUpdateFormItems.handlerInvalidAccountLogo.notification.sizeError.text',
        method: 'error',
      });
    }
  };

  return (
    <div className={styles.formItemsWrapper}>
      <div className={styles.mainBlock}>
        <Form.Item name="logo" valuePropName="url" className={styles.logoWrapper}>
          <ImageUploader
            initialImages={initLogo}
            listType="picture-circle"
            accept={LOGO_ACCEPT_FORMATS}
            sizeLimit={LOGO_SIZE_LIMIT}
            disabled={isLoading}
            onInvalidFile={handlerInvalidAccountLogo}
          />
        </Form.Item>
        <Form.Item
          label={t('Account:AccountCreateUpdateFormItems.name.item.label')}
          rules={[
            {
              validator: inputSpaceValidator,
              message: t('Account:AccountCreateUpdateFormItems.name.item.validationMessage.required'),
            },
            {
              min: DEFAULT_ACCOUNT_NAME_MIN_LENGTH,
              max: DEFAULT_ACCOUNT_NAME_MAX_LENGTH,
              message: t('Account:AccountCreateUpdateFormItems.name.item.validationMessage.nameLength'),
            },
          ]}
          name="name"
          data-testid="form-name-item"
          className={styles.nameInput}
          normalize={(value: string) => value.trimStart()}
        >
          <Input
            disabled={isLoading}
            placeholder={t('Account:AccountCreateUpdateFormItems.name.item.placeholder.text')}
            data-testid="name-input"
          />
        </Form.Item>
      </div>
      <Form.Item
        label={t('Account:AccountCreateUpdateFormItems.description.item.label')}
        rules={[
          {
            required: true,
            message: t('Account:AccountCreateUpdateFormItems.description.item.validationMessage.required'),
          },
          {
            max: DEFAULT_ACCOUNT_DESCRIPTION_MAX_LENGTH,
            message: t('Account:AccountCreateUpdateFormItems.description.item.validationMessage.descriptionLength'),
          },
        ]}
        name="description"
        data-testid="form-description-item"
        className={styles.descriptionInput}
      >
        <Input.TextArea
          disabled={isLoading}
          placeholder={t('Account:AccountCreateUpdateFormItems.description.item.placeholder.text')}
          autoSize={{ maxRows: 5, minRows: 5 }}
          data-testid="description-input"
        />
      </Form.Item>
    </div>
  );
};

export default AccountCreateUpdateFormItems;
