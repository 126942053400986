import { InfiniteData, infiniteQueryOptions, queryOptions as tsqQueryOptions } from '@tanstack/react-query';

import { queryClient } from 'shared/lib/react-query';

import { userOverviewFilterListGet, userOverviewListGet } from './requests';
import { PageUserDTO, UserOverviewRequest } from './types';

export type FilterList = {
  specialization: Array<string>;
  seniority: Array<string>;
  workProfile: Array<string>;
};

export const keys = {
  root: () => ['userOverview'] as const,
  list: () => [...keys.root(), 'list'] as const,
  listWithFilters: (filters: Omit<UserOverviewRequest, 'page'>) => [...keys.root(), 'list', filters] as const,
  filters: () => [...keys.root(), 'filters'] as const,
};

export const userOverviewService = {
  queryKey: (filters: Omit<UserOverviewRequest, 'page'>) => keys.listWithFilters(filters),

  getCache: (filters: Omit<UserOverviewRequest, 'page'>) =>
    queryClient.getQueryData<InfiniteData<PageUserDTO>>(userOverviewService.queryKey(filters)),

  queryOptions: (filters: Omit<UserOverviewRequest, 'page'>) => {
    const filtrationKey = userOverviewService.queryKey(filters);
    return infiniteQueryOptions({
      queryKey: filtrationKey,
      queryFn: async ({ pageParam, signal }) => {
        const data = await userOverviewListGet({ ...filters, page: pageParam.page }, { signal });
        return data;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      initialPageParam: { page: 0 } as any,
      getNextPageParam: (lastPage) => {
        if (lastPage.totalPages <= lastPage.pageable.pageNumber + 1) {
          return null;
        }
        return {
          page: lastPage.pageable.pageNumber + 1,
        };
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => userOverviewService.getCache(filters)!,
      initialDataUpdatedAt: () => queryClient.getQueryState(filtrationKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async (filtration: Omit<UserOverviewRequest, 'page'>) => {
    queryClient.prefetchInfiniteQuery(userOverviewService.queryOptions(filtration));
  },
};

export const userOverviewFiltersService = {
  queryKey: () => keys.filters(),

  getCache: () => queryClient.getQueryData<FilterList>(userOverviewFiltersService.queryKey()),

  queryOptions: () => {
    const filtersKey = userOverviewFiltersService.queryKey();
    return tsqQueryOptions({
      queryKey: filtersKey,
      queryFn: async () => {
        const data = await userOverviewFilterListGet();
        const filters = {
          seniority: data.seniority,
          specialization: data.specializations,
          workProfile: data.workProfiles,
        };

        return filters;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => userOverviewFiltersService.getCache()!,
      initialDataUpdatedAt: () => queryClient.getQueryState(filtersKey)?.dataUpdatedAt,
      staleTime: 10 * 60 * 1000,
    });
  },

  prefetchQuery: async () => {
    queryClient.prefetchQuery(userOverviewFiltersService.queryOptions());
  },
};
