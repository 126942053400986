import { useSuspenseQuery } from '@tanstack/react-query';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserName } from 'features/UserCard';
import { localMappedUsersService } from 'entities/user';

import { withSuspense } from 'shared/lib/react';
import { Avatar } from 'shared/ui/Avatar';
import { EmptyGroup } from 'shared/ui/EmptyGroup';
import { Loader } from 'shared/ui/Loader';

import styles from './userListWithGroup.module.scss';

type ListProps = {
  entityType: string;
  entityId: string;
};

const UserListWithGroup: React.FC<ListProps> = ({ entityType, entityId }) => {
  const { t } = useTranslation('ReusableComponents');

  const { data: users } = useSuspenseQuery(localMappedUsersService.queryOptions({ entityType, entityId }));

  return users.length > 0 ? (
    <div className={styles.list}>
      {users.map((user) => (
        <div key={user.id} className={styles.listItem}>
          <div className={styles.leftBlock}>
            <Avatar
              className={styles.avatar}
              size="small"
              picture={user.picture}
              text={`${user.firstName} ${user.lastName}`}
            />
            <div className={styles.name}>
              <UserName name={`${user.firstName} ${user.lastName}`} id={user.id} />
            </div>
          </div>
          <span className={styles.tags}>
            {user.accessGroups.map((group) => (
              <Tag key={group.id} className={styles.tag}>
                {group.name}
              </Tag>
            ))}
          </span>
        </div>
      ))}
    </div>
  ) : (
    <EmptyGroup title={t('LocalPermissionMapper.noUsers')} />
  );
};

export default withSuspense(UserListWithGroup, { fallback: <Loader /> });
