import React, { useEffect, useMemo, useState } from 'react';
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import { Button, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { ArchiveEntityModal } from 'features/archiveEntity';
import { LocalPermissionMapper } from 'features/LocalPermissionMapper';
import { isHasAccess } from 'features/validateAccess';

import {
  useAddLocalMappingAGMutation,
  useRemoveLocalMappingAGMutation,
  type AccessGroupMappingRequest,
} from 'entities/accessGroup';
import { accountService, useArchiveAccountMutation, useUpdateAccountMutation } from 'entities/account';
import { localMappedUsersService, type MappedUserDTO } from 'entities/user';

import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Loader } from 'shared/ui/Loader';

import AccountCreateUpdateFormItems from 'components/Account/AccountCreateUpdateFormItems/AccountCreateUpdateFormItems';

import CreateUpdateFormHolder from 'components/CreateUpdateFormHolder/CreateUpdateFormHolder';
import UserListWithGroup from 'components/UserListWithGroup/userListWithGroup';

import { getLocalMappingAccountPermissions, getArchiveAccountPermission } from '../Account.permissions';

import styles from './AccountUpdateForm.module.scss';

type FormType = {
  name: string;
  description: string;
  logo: {
    url: string;
  }[];
};

const AccountUpdateForm: React.FC = () => {
  const { t } = useTranslation();
  const [form] = useForm<FormType>();

  const [visibleArchiveDialog, setVisibleArchiveDialog] = useState(false);
  const [preventNavigation, setPreventNavigation] = useState(false);
  const [localPermissionsModal, setLocalPermissionsModal] = useState(false);

  const accountId = useParams().accountId as string;
  const navigate = useNavigate();

  const { data: account } = useSuspenseQuery(accountService.queryOptions(accountId));

  const {
    mutate: updateMutation,
    isPending: isUpdating,
    isError: hasUpdatingErrors,
    error: updatingErrors,
    reset: resetUpdatingMutation,
  } = useUpdateAccountMutation(accountId);

  const {
    mutate: archiveMutation,
    isPending: isArchiving,
    isError: hasArchivingErrors,
    error: archivingErrors,
    reset: resetArchiveMutation,
  } = useArchiveAccountMutation(accountId);

  const { mutate: addLocalMappingMutation, isPending: isLocalMappingAdding } = useAddLocalMappingAGMutation();
  const { mutate: removeLocalMappingMutation, isPending: isLocalMappingRemoving } = useRemoveLocalMappingAGMutation();

  const canLocalMapAccount = isHasAccess(getLocalMappingAccountPermissions(accountId));

  const { data: localMappedUsers, refetch: refetchLocalMappedUsers } = useQuery(
    localMappedUsersService.queryOptions({ entityType: 'ACCOUNT', entityId: accountId }, canLocalMapAccount),
  );

  const initData = useMemo((): FormType => {
    return {
      name: account.name,
      description: account.description,
      logo: [{ url: account.logoUrl }],
    };
  }, [account]);

  useEffect(() => {
    if (hasUpdatingErrors && updatingErrors?.response?.data?.code === 'ItemAlreadyExistsException') {
      form.setFields([
        { name: 'name', errors: [t('Account:AccountCreateUpdateFormItems.name.item.validationMessage.nonUniqueName')] },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUpdatingErrors]);

  const archiveAccount = (): void => {
    setPreventNavigation(false);
    archiveMutation(accountId);
  };

  const closeModal = (): void => {
    setVisibleArchiveDialog(false);
    resetArchiveMutation();
  };

  const onFormChange = (): void => {
    const values = form.getFieldsValue();
    resetUpdatingMutation();
    resetArchiveMutation();

    if (values.name !== account?.name || values.description !== account?.description) {
      setPreventNavigation(true);
      return;
    }

    setPreventNavigation(false);
  };

  const onFormSubmit = (values: FormType): void => {
    setPreventNavigation(false);
    const { logo, name, description } = values;

    const logoUrl = logo?.[0]?.url;
    const uploadLogo = Boolean(!logoUrl || (logoUrl && logoUrl.startsWith('data:image')));
    updateMutation({
      id: accountId,
      name: name.trim(),
      description,
      logo: uploadLogo ? logoUrl : undefined,
      uploadLogo,
      version: account.version,
    });
  };

  const failedText = useMemo(
    () => (
      <div>
        <div className={styles.errorText}>
          {t('Account:AccountUpdateForm.ArchiveEntityModal.withAssignmentIn.body.text')}
        </div>
        <div className={styles.projectsList}>
          {hasArchivingErrors && archivingErrors?.response?.data.code === 'ProjectsHasUnarchivedAssignmentsException'
            ? archivingErrors.response?.data.payload?.projectNames.map((name: string) => <pre key={name}>{name}</pre>)
            : null}
        </div>
      </div>
    ),
    [t, hasArchivingErrors, archivingErrors],
  );

  const onUpdateLocalPermissions = async (mappedUsers: MappedUserDTO[]): Promise<void> => {
    const usersToRemove: AccessGroupMappingRequest = {
      entityId: accountId,
      entityType: 'ACCOUNT',
      mapping: [],
    };
    const usersToAdd: AccessGroupMappingRequest = {
      entityId: accountId,
      entityType: 'ACCOUNT',
      mapping: [],
    };

    mappedUsers.forEach(({ id: userId, accessGroups }) => {
      const userInOldMapping = localMappedUsers.find((localMappedUser) => localMappedUser.id === userId);
      if (!userInOldMapping) {
        usersToAdd.mapping.push({ userId, accessGroupIds: accessGroups.map(({ id }) => id) });
      } else {
        accessGroups.forEach(({ id: accessGroupId }) => {
          if (!userInOldMapping.accessGroups.some(({ id }) => id === accessGroupId)) {
            const addedUser = usersToAdd.mapping.find(({ userId: addedUserId }) => addedUserId === userId);
            if (addedUser) {
              addedUser.accessGroupIds.push(accessGroupId);
            } else {
              usersToAdd.mapping.push({ userId, accessGroupIds: [accessGroupId] });
            }
          }
        });
      }
    });

    localMappedUsers.forEach(({ id: userId, accessGroups }) => {
      const userInNewMapping = mappedUsers.find((user) => user.id === userId);

      if (!userInNewMapping) {
        usersToRemove.mapping.push({ userId, accessGroupIds: accessGroups.map(({ id }) => id) });
      } else {
        accessGroups.forEach(({ id: accessGroupId }) => {
          if (!userInNewMapping.accessGroups.some(({ id }) => id === accessGroupId)) {
            const removedUser = usersToRemove.mapping.find(({ userId: addedUserId }) => addedUserId === userId);
            if (removedUser) {
              removedUser.accessGroupIds.push(accessGroupId);
            } else {
              usersToRemove.mapping.push({ userId, accessGroupIds: [accessGroupId] });
            }
          }
        });
      }
    });

    if (usersToAdd.mapping.length) {
      addLocalMappingMutation(usersToAdd, {
        onSuccess: () => {
          if (!usersToRemove.mapping.length) {
            refetchLocalMappedUsers();
            setLocalPermissionsModal(false);
          }
        },
      });
    }

    if (usersToRemove.mapping.length) {
      removeLocalMappingMutation(usersToRemove, {
        onSuccess: () => {
          refetchLocalMappedUsers();
          setLocalPermissionsModal(false);
        },
      });
    }
  };

  const canArchiveAccount = isHasAccess(getArchiveAccountPermission(accountId));

  return (
    <CreateUpdateFormHolder
      title={t('Account:AccountUpdateForm.heading')}
      onFormSubmit={onFormSubmit}
      onCancel={() => navigate(pathKeys.account.root())}
      isLoading={isUpdating}
      editMode
      onClickArchiveButton={() => setVisibleArchiveDialog(true)}
      initialData={initData}
      customFormControl={[form]}
      onFormChange={onFormChange}
      preventNavigation={preventNavigation}
      hideDeleteBtn={!canArchiveAccount}
    >
      <AccountCreateUpdateFormItems initialLogo={account?.logoUrl} isLoading={isUpdating} />
      {canLocalMapAccount && (
        <Space direction="vertical">
          <Space>
            <h4>{t('Account:AccountUpdateForm.localMapping.title')} </h4>
            <Button type="primary" onClick={() => setLocalPermissionsModal(true)}>
              {t('Account:AccountUpdateForm.localMapping.triggerButton.label')}
            </Button>
          </Space>
          <div className={styles.localPermissions}>
            <UserListWithGroup entityId={accountId} entityType="ACCOUNT" />
          </div>
        </Space>
      )}
      <ArchiveEntityModal
        open={visibleArchiveDialog}
        isLoading={isArchiving}
        type={
          hasArchivingErrors && archivingErrors?.response?.data.code === 'ProjectsHasUnarchivedAssignmentsException'
            ? 'failed'
            : 'normal'
        }
        closeModal={closeModal}
        archiveEntity={archiveAccount}
        normalText={t('Account:AccountUpdateForm.ArchiveEntityModal.normal.body.text')}
        failedText={failedText}
      />
      {canLocalMapAccount && (
        <LocalPermissionMapper
          open={localPermissionsModal}
          close={() => setLocalPermissionsModal(false)}
          entityId={accountId}
          entityType="ACCOUNT"
          title={t('Account:AccountUpdateForm.localMapping.modal.title', {
            name: account?.name,
          })}
          isSaving={isLocalMappingAdding || isLocalMappingRemoving}
          onSave={onUpdateLocalPermissions}
        />
      )}
    </CreateUpdateFormHolder>
  );
};

export default withSuspense(AccountUpdateForm, { fallback: <Loader /> });
