import { ICustomColumnType } from 'shared/ui/VirtualTable';

export const PAGE_SIZE = 40;
export const ROW_HEIGHT = 39;

export const THRESHOLD_VALUE = 10;

export const columns: ICustomColumnType<object>[] = [
  {
    title: 'UserOverview:UserTable.header.column.name.title',
    dataIndex: 'name',
    width: `${21.7}%`,
    key: 'firstName',
  },
  {
    title: 'UserOverview:UserTable.header.column.seniority.title',
    dataIndex: 'seniority',
    key: 'seniority',
  },
  {
    title: 'UserOverview:UserTable.header.column.specialization.title',
    dataIndex: 'specialization',
    key: 'specialization',
  },
  {
    title: 'UserOverview:UserTable.header.column.workProfile.title',
    dataIndex: 'workProfile',
    key: 'workProfile',
  },
  {
    title: 'UserOverview:UserTable.header.column.manager.title',
    dataIndex: 'managerBamboo',
    key: 'managerBamboo',
  },
  {
    title: 'UserOverview:UserTable.header.column.email.title',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'UserOverview:UserTable.header.column.location.title',
    dataIndex: 'location',
    key: 'location',
  },
];
