import { AvailableGlobalPermissions } from 'features/validateAccess';
import { AccountsNameDTO } from 'entities/account';
import { ProjectsNamesDTO } from 'entities/project';

export const getLocalName = (
  globalName: string,
  localId: string,
  accountNames?: AccountsNameDTO[],
  projectNames?: ProjectsNamesDTO[],
): string => {
  if (globalName === AvailableGlobalPermissions.ACCOUNT) {
    const accountName = accountNames?.find((account) => account.id === localId);
    if (accountName) {
      return accountName.name;
    }
  }
  if (globalName === AvailableGlobalPermissions.PROJECT) {
    const projectName = projectNames?.find((project) => project.id === localId);
    if (projectName) {
      return projectName.name;
    }
  }

  return localId;
};
