import React, { ReactElement } from 'react';
import { Skeleton } from 'antd';
import { ListChildComponentProps } from 'react-window';

import { IVirtualListProps } from '../../VirtualList.types';

import styles from './ListItem.module.scss';

interface IListItemProps<T> extends ListChildComponentProps<T[]> {
  isItemLoaded: (index: number) => boolean;
  renderItem: IVirtualListProps<T>['renderItem'];
  skeletonItem?: IVirtualListProps<T>['skeletonItem'];
}

function ListItem<T>({ index, style, data, isItemLoaded, renderItem, skeletonItem }: IListItemProps<T>): ReactElement {
  let itemContent: React.ReactNode;
  if (!isItemLoaded(index)) {
    itemContent = skeletonItem || (
      <div className={styles.skeletonWrapper} data-testid="skeleton-item">
        <Skeleton
          className={styles.skeleton}
          active
          loading
          paragraph={{ rows: 0, width: 0 }}
          title={{ width: '100%' }}
        />
      </div>
    );
  } else {
    const itemData = data[index];
    itemContent = renderItem({ data: itemData, index });
  }

  return <div style={style}>{itemContent}</div>;
}

export default ListItem;
