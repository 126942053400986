import { useMemo } from 'react';
import { UserSearchDTO } from 'entities/user';

export const useCheckboxState = (
  usersToRender: UserSearchDTO[],
  selectedUsersIds: Set<string>,
  movedUsersCount: number,
): [boolean, boolean] => {
  const result: [boolean, boolean] = useMemo(() => {
    const countOfCheckedUsersOnPage = usersToRender.filter((user) => selectedUsersIds.has(user.id)).length;

    const isChecked =
      usersToRender.length > 0 &&
      countOfCheckedUsersOnPage === usersToRender.length - movedUsersCount &&
      usersToRender.length - movedUsersCount !== 0;
    const isIndeterminate =
      countOfCheckedUsersOnPage > 0 && countOfCheckedUsersOnPage < usersToRender.length - movedUsersCount;

    return [isChecked, isIndeterminate];
  }, [movedUsersCount, selectedUsersIds, usersToRender]);

  return result;
};
