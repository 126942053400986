import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';
import { positionService } from 'entities/position';

import { pathKeys } from 'shared/lib/react-router';

import PositionUpdatePage from './PositionUpdatePage';

export const positionUpdatePageRoute: RouteObject = {
  path: ':positionId/edit/',
  element: createElement(PositionUpdatePage),
  loader: async (args) => {
    const requiredPermissions = replacePermissions(pathPermissions.position.update, args.params);
    if (!isHasAccess(requiredPermissions)) {
      return redirect(pathKeys.dashboard());
    }

    if (!args.params.projectId || !args.params.accountId || !args.params.positionId) {
      return redirect(pathKeys.page404());
    }

    Promise.resolve(positionService.prefetchQuery(args.params.positionId));

    return args;
  },
};
