import React from 'react';
import { Button, DatePicker, DatePickerProps, Form, Input, Select, Tooltip } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { RateDTO } from 'entities/position';

import { ReactComponent as CrossIcon } from 'shared/assets/icons/cross.svg';
import { ReactComponent as ExclamationCircle } from 'shared/assets/icons/exclamation-circle.svg';
import { ReactComponent as PlusIcon } from 'shared/assets/icons/plus.svg';
import { CURRENCY_LIST, DEFAULT_DATE_FORMAT_UI } from 'shared/config';
import calendarStyles from 'shared/styles/ant-custom-components/calendar.module.scss';
import { Maybe } from 'shared/types';

import { PositionFormType } from 'components/PositionAndAssignment/PositionCreateForm/PositionCreateForm';

import styles from './RateItem.module.scss';

type RateItem = Omit<RateDTO, 'startDate' | 'endDate'> & { startDate: Maybe<Dayjs>; endDate: Maybe<Dayjs> };

export interface IRateItemPropsType {
  field: FormListFieldData;
  index: number;
  maxIndex: number;
  shouldValidateStartDateField: { index: number } | null;
  setShouldValidateStartDateField: (data: { index: number }) => void;
  shouldValidateEndDateField: { index: number } | null;
  setShouldValidateEndDateField: (data: { index: number }) => void;
  add: (
    defaultValue?: {
      value: string;
      currency: typeof CURRENCY_LIST[number];
      endDate: Maybe<Dayjs>;
      startDate: Maybe<Dayjs>;
    },
    index?: number,
  ) => void;
  removeFn: (index: number) => void;
}

const RateItem: React.FC<IRateItemPropsType> = ({
  field,
  index,
  maxIndex,
  setShouldValidateStartDateField,
  shouldValidateStartDateField,
  setShouldValidateEndDateField,
  shouldValidateEndDateField,
  add,
  removeFn,
}) => {
  const { t } = useTranslation();
  const form = Form.useFormInstance<PositionFormType>();

  const buildOptions = (): { value: string; label: string }[] => {
    return CURRENCY_LIST.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  };

  const isRateDatesInPositionDates = (date: Dayjs): boolean => {
    const positionStartDate: Maybe<Dayjs> = form.getFieldValue('startDate');
    const positionEndDate: Maybe<Dayjs> = form.getFieldValue('endDate');

    return (
      (!positionStartDate || !date.isBefore(positionStartDate, 'dates')) &&
      (!positionEndDate || !date.isAfter(positionEndDate, 'dates'))
    );
  };

  const disabledStartDate = (current: Dayjs): boolean => {
    const rateEndDate = form.getFieldValue(['rates', field.name, 'endDate']);

    const isDateBetweenPositionDates = isRateDatesInPositionDates(current);
    const isDateAfterEndDate = rateEndDate && current.isAfter(rateEndDate, 'dates');

    return !isDateBetweenPositionDates || isDateAfterEndDate;
  };

  const validateStartDate = (_: RuleObject, value: Dayjs): Promise<void> => {
    if (index === 0) return Promise.resolve();
    if (!value) return Promise.reject();

    const endDate: Maybe<Dayjs> = form.getFieldValue(['rates', index, 'endDate']);
    if (endDate?.isBefore(value, 'dates')) return Promise.reject();

    const previousRateEndDate = form.getFieldValue(['rates', index - 1, 'endDate']);
    if (!previousRateEndDate) return Promise.resolve();

    const dayBeforeValue = value.clone().add(-1, 'day');
    const isStartAndEndDateCompare = dayBeforeValue.isSame(previousRateEndDate, 'dates');

    const previousRateEndDateError = form.getFieldError(['rates', index - 1, 'endDate']);
    if (!isStartAndEndDateCompare) {
      if (previousRateEndDateError.length === 0 && !shouldValidateStartDateField) {
        setShouldValidateEndDateField({ index: index - 1 });
      }

      return Promise.reject();
    }

    if (previousRateEndDateError.length > 0 && !shouldValidateStartDateField) {
      setShouldValidateEndDateField({ index: index - 1 });
    }

    return Promise.resolve();
  };

  const validateEndDate = (_: RuleObject, value: Dayjs): Promise<void> => {
    if (index === maxIndex) return Promise.resolve();
    if (!value) return Promise.reject();

    const startDate: Maybe<Dayjs> = form.getFieldValue(['rates', index, 'startDate']);
    if (startDate?.isAfter(value, 'dates')) return Promise.reject();

    const nextRateStartDateValue = form.getFieldValue(['rates', index + 1, 'startDate']);
    if (!nextRateStartDateValue) return Promise.resolve();

    const dayAfterValue = value.clone().add(1, 'day');
    const isStartAndEndDateCompare = dayAfterValue.isSame(nextRateStartDateValue, 'dates');

    const nextRateStartDateError = form.getFieldError(['rates', index + 1, 'startDate']);
    if (!isStartAndEndDateCompare) {
      if (nextRateStartDateError.length === 0 && !shouldValidateEndDateField) {
        setShouldValidateStartDateField({ index: index + 1 });
      }

      return Promise.reject();
    }

    if (nextRateStartDateError.length > 0 && !shouldValidateEndDateField) {
      setShouldValidateStartDateField({ index: index + 1 });
    }

    return Promise.resolve();
  };

  const addRateItemAfter = (): void => {
    const currentRate: RateItem = form.getFieldValue(['rates', index]);
    const { endDate } = currentRate;
    currentRate.endDate = null;
    add({ value: '0', currency: CURRENCY_LIST[0], endDate, startDate: null }, index + 1);
  };

  const addRateItemBefore = (): void => {
    let startDate: Maybe<Dayjs> = null;
    if (index === 0) {
      const currentRate: RateItem = form.getFieldValue(['rates', index]);
      startDate = currentRate.startDate;
      currentRate.startDate = null;
    }
    add({ value: '0', currency: CURRENCY_LIST[0], endDate: null, startDate }, index);
  };

  const removeCurrentRateItem = (): void => {
    if (index === 0) {
      const nextRate: RateItem = form.getFieldValue(['rates', index + 1]);
      const currentRate: RateItem = form.getFieldValue(['rates', index]);
      nextRate.startDate = currentRate.startDate;
    } else {
      const previousRate: RateItem = form.getFieldValue(['rates', index - 1]);
      const currentRate: RateItem = form.getFieldValue(['rates', index]);
      previousRate.endDate = currentRate.endDate;
    }
    removeFn(field.name);
  };

  const renderErrorAlert = (tooltipText: string): React.ReactElement => {
    return (
      <div className={styles.errorTooltip}>
        <div>{t('Position:RateList.Item.dates.validationMessage.message.text')}</div>
        <Tooltip title={tooltipText}>
          <ExclamationCircle height={18} width={18} fill="#ff0000" />
        </Tooltip>
      </div>
    );
  };

  const renderCustomCell: DatePickerProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div title="" className={calendarStyles.innerCellWrapper}>
        <div className="ant-picker-cell-inner">{current.date()}</div>
      </div>
    );
  };

  return (
    <div className={styles.rootWrapper}>
      <div className={styles.iconWrapper}>
        {index === 0 ? (
          <button
            type="button"
            onClick={addRateItemBefore}
            className={styles.addButton}
            data-testid="rate-top-add-button"
          >
            <Tooltip title={t('Position:RateList.addButton.tooltip.text')} placement="right">
              <PlusIcon />
            </Tooltip>
          </button>
        ) : null}
        <div className={styles.icon} />
        <button
          type="button"
          onClick={addRateItemAfter}
          className={styles.addButton}
          data-testid="rate-bottom-add-button"
        >
          <Tooltip title={t('Position:RateList.addButton.tooltip.text')} placement="right">
            <PlusIcon />
          </Tooltip>
        </button>
      </div>
      <div className={classNames(styles.itemWrapper, index === 0 ? styles.firstItem : null)}>
        <Form.Item
          name={[field.name, 'value']}
          rules={[
            {
              required: true,
              message: t('Position:RateList.Item.value.validationMessage.required.text'),
            },
            {
              pattern: /^[0-9]+([.][0-9]+)?$/,
              message: t('Position:RateList.Item.value.validationMessage.invalidInput.text'),
            },
          ]}
          className={styles.valueInput}
        >
          <Input data-testid="rate-value-input" />
        </Form.Item>
        <Form.Item name={[field.name, 'currency']} className={styles.currencyInput}>
          <Select data-testid="rate-currency-selector" options={buildOptions()} />
        </Form.Item>
        <Form.Item
          name={[field.name, 'startDate']}
          className={styles.dateInput}
          rules={[
            {
              validator: validateStartDate,
              message: renderErrorAlert(
                t('Position:RateList.Item.dates.validationMessage.tooltip.gapOrOverlapsBetweenDates.text'),
              ),
            },
          ]}
        >
          <DatePicker
            data-testid="rate-start-date-input"
            placeholder={t('Position:RateList.Item.startDate.placeholder')}
            disabledDate={disabledStartDate}
            format={DEFAULT_DATE_FORMAT_UI}
            disabled={index === 0}
            mode="date"
            cellRender={renderCustomCell}
            onChange={(date: Maybe<Dayjs>) => {
              if (index !== 0) {
                const previousRate: RateItem = form.getFieldValue(['rates', index - 1]);
                previousRate.endDate = date !== null ? date.clone().add(-1, 'd') : null;
                form.setFieldsValue({});
              }
            }}
          />
        </Form.Item>
        <Form.Item
          name={[field.name, 'endDate']}
          rules={[
            {
              validator: validateEndDate,
              message: renderErrorAlert(
                t('Position:RateList.Item.dates.validationMessage.tooltip.gapOrOverlapsBetweenDates.text'),
              ),
            },
          ]}
          className={styles.dateInput}
        >
          <DatePicker
            data-testid="rate-end-date-input"
            placeholder={t('Position:RateList.Item.endDate.placeholder')}
            format={DEFAULT_DATE_FORMAT_UI}
            mode="date"
            cellRender={renderCustomCell}
            disabled
          />
        </Form.Item>
        <Form.Item>
          {maxIndex !== 0 ? (
            <Button icon={<CrossIcon />} type="text" onClick={removeCurrentRateItem} data-testid="remove-rate-button" />
          ) : null}
        </Form.Item>
      </div>
    </div>
  );
};

export default RateItem;
