import { pathKeys } from 'shared/lib/react-router';
import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from './types';

type PathKeysType = {
  [K in keyof typeof pathKeys]: typeof pathKeys[K] extends object
    ? // eslint-disable-next-line @typescript-eslint/ban-types
      typeof pathKeys[K] extends Function
      ? RequiredPermissions
      : { [L in keyof typeof pathKeys[K]]: RequiredPermissions }
    : RequiredPermissions;
};

export const pathPermissions: PathKeysType = {
  root: [],
  login: [],
  logout: [],
  dashboard: [],
  accessGroups: [
    [{ type: 'global', global: AvailableGlobalPermissions.ACCESS_GROUP, value: AvailablePermissionsValue.READ }],
  ],
  userOverview: [
    [
      {
        type: 'global',
        global: AvailableGlobalPermissions.ASSIGNMENT,
        value: AvailablePermissionsValue.READ,
      },
    ],
  ],
  account: {
    root: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.ACCOUNT,
          value: AvailablePermissionsValue.READ,
        },
      ],
    ],
    item: [],
    create: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.ACCOUNT,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.ACCOUNT,
          value: AvailablePermissionsValue.CREATE,
        },
      ],
    ],
    update: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.ACCOUNT,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'local',
          global: AvailableGlobalPermissions.ACCOUNT,
          local: 'accountId',
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.ACCOUNT,
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'local',
          global: AvailableGlobalPermissions.ACCOUNT,
          local: 'accountId',
          value: AvailablePermissionsValue.UPDATE,
        },
      ],
    ],
  },
  project: {
    root: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.PROJECT,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          global: AvailableGlobalPermissions.ACCOUNT,
          parent: 'accountId',
          subGlobal: AvailableGlobalPermissions.PROJECT,
          value: AvailablePermissionsValue.READ,
        },
      ],
    ],
    item: [],
    create: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.PROJECT,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          global: AvailableGlobalPermissions.ACCOUNT,
          parent: 'accountId',
          subGlobal: AvailableGlobalPermissions.PROJECT,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        { type: 'global', global: AvailableGlobalPermissions.PROJECT, value: AvailablePermissionsValue.CREATE },
        {
          type: 'subGlobal',
          global: AvailableGlobalPermissions.ACCOUNT,
          parent: 'accountId',
          subGlobal: AvailableGlobalPermissions.PROJECT,
          value: AvailablePermissionsValue.CREATE,
        },
      ],
    ],
    update: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.PROJECT,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'local',
          global: AvailableGlobalPermissions.PROJECT,
          local: 'projectId',
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          global: AvailableGlobalPermissions.ACCOUNT,
          parent: 'accountId',
          subGlobal: AvailableGlobalPermissions.PROJECT,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        { type: 'global', global: AvailableGlobalPermissions.PROJECT, value: AvailablePermissionsValue.UPDATE },
        {
          type: 'local',
          global: AvailableGlobalPermissions.PROJECT,
          local: 'projectId',
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'subGlobal',
          global: AvailableGlobalPermissions.ACCOUNT,
          parent: 'accountId',
          subGlobal: AvailableGlobalPermissions.PROJECT,
          value: AvailablePermissionsValue.UPDATE,
        },
      ],
    ],
  },
  position: {
    root: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
      ],
    ],
    item: [],
    create: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.CREATE,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.CREATE,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.CREATE,
        },
      ],
    ],
    update: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'local',
          global: AvailableGlobalPermissions.POSITION,
          local: 'positionId',
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'local',
          global: AvailableGlobalPermissions.POSITION,
          local: 'positionId',
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.UPDATE,
        },
      ],
    ],
  },
  assignment: {
    root: [],
    item: [],
    create: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.ASSIGNMENT,
          value: AvailablePermissionsValue.CREATE,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
          value: AvailablePermissionsValue.CREATE,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
          value: AvailablePermissionsValue.CREATE,
        },
      ],
    ],
    update: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.ASSIGNMENT,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'local',
          global: AvailableGlobalPermissions.ASSIGNMENT,
          local: 'assignmentId',
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.ASSIGNMENT,
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'local',
          global: AvailableGlobalPermissions.ASSIGNMENT,
          local: 'assignmentId',
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
          value: AvailablePermissionsValue.UPDATE,
        },
      ],
    ],
  },
  demand: {
    root: [],
    item: [],
    create: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.DEMAND,
          value: AvailablePermissionsValue.CREATE,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.DEMAND,
          value: AvailablePermissionsValue.CREATE,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.DEMAND,
          value: AvailablePermissionsValue.CREATE,
        },
      ],
    ],
    update: [
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.POSITION,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.DEMAND,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'local',
          global: AvailableGlobalPermissions.DEMAND,
          local: 'demandId',
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.DEMAND,
          value: AvailablePermissionsValue.READ,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.DEMAND,
          value: AvailablePermissionsValue.READ,
        },
      ],
      [
        {
          type: 'global',
          global: AvailableGlobalPermissions.DEMAND,
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'local',
          global: AvailableGlobalPermissions.DEMAND,
          local: 'demandId',
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'subGlobal',
          parent: 'accountId',
          global: AvailableGlobalPermissions.ACCOUNT,
          subGlobal: AvailableGlobalPermissions.DEMAND,
          value: AvailablePermissionsValue.UPDATE,
        },
        {
          type: 'subGlobal',
          parent: 'projectId',
          global: AvailableGlobalPermissions.PROJECT,
          subGlobal: AvailableGlobalPermissions.DEMAND,
          value: AvailablePermissionsValue.UPDATE,
        },
      ],
    ],
  },
  externalWarning: [],
  page404: [],
};
