import { Button } from 'antd';
import { useUserCardStore } from '../../model/store';

import styles from './UserName.module.scss';

interface IUserNameProps {
  name: string;
  id: string;
}
export const UserName = ({ name, id }: IUserNameProps): JSX.Element => {
  const openUserCard = useUserCardStore((state) => state.openCard);

  const handleClick = (): void => openUserCard(id);

  return (
    <Button type="link" className={styles.name} onClick={handleClick} size="small" data-testid="user-name">
      {name}
    </Button>
  );
};
