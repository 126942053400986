import { StateCreator, create } from 'zustand';
import { DevtoolsOptions, devtools } from 'zustand/middleware';
import { Maybe } from 'shared/types';

type Actions = {
  openCard: (userId: string) => void;
  closeCard: () => void;
};

type State = { userId: Maybe<string>; isOpen: boolean };

export type UserCardState = Actions & State;

export const createSlice: StateCreator<UserCardState, [['zustand/devtools', never]], [], UserCardState> = (set) => ({
  userId: null,
  isOpen: false,
  openCard: (userId) => set({ userId, isOpen: true }, false, 'openCard'),
  closeCard: () => set({ userId: null, isOpen: false }, false, 'closeCard'),
});

const devtoolsOptions: DevtoolsOptions = { name: 'userCardStore' };

export const useUserCardStore = create<UserCardState>()(devtools(createSlice, devtoolsOptions));
