import { isAxiosError } from 'axios';
import { CommonError, CommonErrorResponse } from 'shared/api/types';
import { i18n } from 'shared/lib/i18n';
import { addNotification, NotificationType, type TranslatableText } from 'shared/model/notification';

export function handleRequestError(error: Error | CommonError, notificationType: NotificationType = 'critical'): void {
  if (isAxiosError<CommonErrorResponse>(error)) {
    // handle axios error
    const errorResponse = error.response?.data;
    if (errorResponse) {
      const { service, code, message } = errorResponse;
      const translationText: TranslatableText = `Exception:${service}.${code}`;

      const isValid = i18n.exists(translationText);

      let description: TranslatableText = message ?? 'Exception:UNDEFINED_ERROR';

      if (isValid) {
        description = translationText;
      }

      addNotification({
        type: notificationType,
        method: 'error',
        message: 'Exception:REQUEST_ERROR.title',
        description,
      });
      return;
    }
  }

  let errorMessage = '';

  if (error instanceof Error) {
    errorMessage = error.message ?? '';
  } else {
    errorMessage = 'Exception:UNDEFINED_ERROR';
  }

  addNotification({
    type: 'critical',
    method: 'error',
    message: 'Exception:UNEXPECTED_ERROR.title',
    description: errorMessage,
  });
}
