import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ShortUser } from 'entities/user';
import { ReactComponent as ArrowRight } from 'shared/assets/icons/dynamicColor/arrow-right.svg';
import { Avatar } from 'shared/ui/Avatar';

import styles from './ColumnUsers.module.scss';

const VISIBLE_AVATARS_COUNT = 5;

type ColumnUsersProps = {
  onClickUsers?: () => void;
  usersCount: number;
  usersShortList: ShortUser[];
};

const ColumnUsers = ({ onClickUsers, usersCount, usersShortList }: ColumnUsersProps): JSX.Element => {
  const { t } = useTranslation();

  const renderMultiAvatar = (): ReactNode => {
    if (usersShortList?.length === 0) {
      return (
        <p className={classNames(styles.noUsers)} data-testid="no-user-in-group">
          {t('AccessGroup:ColumnUsers.empty')}
        </p>
      );
    }
    if (usersShortList?.length > 0) {
      const avatars = usersShortList.map((user, index) => {
        if (index > VISIBLE_AVATARS_COUNT) {
          return null;
        }
        return <Avatar key={user.id} picture={user.picture} text={`${user.firstName} ${user.lastName}`} />;
      });
      return (
        <Avatar.Group avatars={avatars} maxVisibleCount={VISIBLE_AVATARS_COUNT} size={24} totalCount={usersCount} />
      );
    }
    return <p className={classNames(styles.noUsers)}>{t('AccessGroup:ColumnUsers.empty')}</p>;
  };

  return (
    <div
      tabIndex={-1}
      role="button"
      onKeyDown={() => null}
      className={classNames(styles.topContentWrapper, { [styles.clickable]: onClickUsers })}
      onClick={onClickUsers}
      data-testid="table-column-top-content-widget"
    >
      <div className={styles.tableWrapper}>{renderMultiAvatar()}</div>
      <ArrowRight height="7" />
    </div>
  );
};

export default React.memo(ColumnUsers);
