import React, { ReactElement, memo } from 'react';
import classNames from 'classnames';
import { FixedSizeList as List, areEqual } from 'react-window';

import { COLUMN_WIDTH } from '../../FoldingTable';

import styles from '../../FoldingTable.module.scss';
import { Column } from '../../FoldingTable.types';
import FoldingTableColumn, { IFoldingTableColumnProps } from '../Column/Column';

interface IVirtualListProps<T, U>
  extends Pick<
    IFoldingTableColumnProps<T, U>,
    | 'renderGroupWidget'
    | 'renderHeadingMenu'
    | 'renderWidget'
    | 'onColumnPinChange'
    | 'sidebarGroupsKeys'
    | 'isPinnedMaxColumnCount'
  > {
  unpinnedColumns: Column<T, U>[];
  width: number;
}

function VirtualList<T, U>(props: IVirtualListProps<T, U>): ReactElement {
  const {
    unpinnedColumns,
    sidebarGroupsKeys,
    isPinnedMaxColumnCount,
    onColumnPinChange,
    renderGroupWidget,
    renderWidget,
    renderHeadingMenu,
    width,
  } = props;

  return (
    <div key="default-container" className={classNames(styles.tableContainer, styles.unpinnedTypeContainer)}>
      <List
        itemData={unpinnedColumns}
        itemSize={COLUMN_WIDTH}
        itemCount={unpinnedColumns.length}
        height="100%"
        width={width}
        itemKey={(index) => unpinnedColumns[index].id}
        direction="horizontal"
        layout="horizontal"
        style={{ overflowY: 'hidden', flex: 1 }}
      >
        {memo(({ index, style, data }) => {
          const column = data[index];
          return (
            <div style={style}>
              <FoldingTableColumn
                key={column.id}
                sidebarGroupsKeys={sidebarGroupsKeys}
                column={column}
                onColumnPinChange={onColumnPinChange}
                isPinned={false}
                isPinnedMaxColumnCount={isPinnedMaxColumnCount}
                renderGroupWidget={renderGroupWidget}
                renderWidget={renderWidget}
                renderHeadingMenu={renderHeadingMenu}
              />
            </div>
          );
        }, areEqual)}
      </List>
    </div>
  );
}

export default React.memo(VirtualList) as typeof VirtualList;
