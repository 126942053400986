import React, { ReactNode, useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

import { SettingService } from 'features/userSettings';
import { isHasAccess } from 'features/validateAccess';

import { positionsService } from 'entities/position';
import { withSuspense } from 'shared/lib/react';
import { Loader } from 'shared/ui/Loader';

import ListWithPagination from 'components/ListWithPagination/ListWithPagination';
import { DEFAULT_IS_HIDDEN_EXPIRED_POSITIONS, PAGE_SIZE } from 'components/PositionAndAssignment/Position.consts';

import {
  getAssignmentCreatePermissions,
  getAssignmentListPermissions,
  getDemandCreatePermissions,
  getDemandListPermissions,
  getEitAllPositions,
} from '../Position.permissions';
import PositionListItem from './components/PositionListItem/PositionListItem';

import styles from './PositionList.module.scss';

const PositionList: React.FC<{ projectId: string; accountId: string }> = ({ projectId, accountId }) => {
  const { t } = useTranslation('Position');

  const [page, setPage] = useState(1);

  const [expiredIncluded, setExpiredIncluded] = useState(
    SettingService.getLocalSetting('includeExpiredPositions') ?? DEFAULT_IS_HIDDEN_EXPIRED_POSITIONS,
  );

  const { data: listData } = useSuspenseQuery(
    positionsService.queryOptions({
      projectId,
      page: page - 1,
      size: PAGE_SIZE,
      showArchived: false,
      expiredIncluded,
    }),
  );

  const onChangeHiddenExpiredPositions = (event: CheckboxChangeEvent): void => {
    SettingService.saveLocalSetting('includeExpiredPositions', event.target.checked);
    setExpiredIncluded(event.target.checked);
    if (page !== 1) {
      setPage(1);
    }
  };

  const renderPositionsList = (): ReactNode => {
    const canEditAllPositions = isHasAccess(getEitAllPositions(accountId, projectId));
    const canSeeAssignments = isHasAccess(getAssignmentListPermissions(accountId, projectId));
    const canSeeDemands = isHasAccess(getDemandListPermissions(accountId, projectId));

    const canCreateAssignment = isHasAccess(getAssignmentCreatePermissions(accountId, projectId));
    const canCreateDemand = isHasAccess(getDemandCreatePermissions(accountId, projectId));

    return listData.content.map((item) => {
      return (
        <li key={item.id} className={styles.accordionItem}>
          <PositionListItem
            accountId={accountId}
            position={item}
            showEditButton={canEditAllPositions}
            showCreateAssignmentButton={canCreateAssignment}
            showCreateDemandButton={canCreateDemand}
            showAssignments={canSeeAssignments}
            showDemands={canSeeDemands}
          />
        </li>
      );
    });
  };

  return (
    <div className={styles.rootWrapper} data-testid="positions-and-assignments-list">
      <div className={styles.filtersWrapper}>
        <Checkbox onChange={onChangeHiddenExpiredPositions} checked={expiredIncluded}>
          {t('PositionsAndAssignmentsList.filters.hideExpired')}
        </Checkbox>
      </div>

      <ListWithPagination
        items={renderPositionsList()}
        totalItems={listData.totalElements}
        pageSize={PAGE_SIZE}
        page={page}
        setPage={setPage}
      />
    </div>
  );
};

export default withSuspense(PositionList, { fallback: <Loader /> });
