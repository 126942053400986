import { App, Button } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Maybe } from 'shared/types';
import { DEFAULT_LOGOUT_NOTIFICATION_TIMEOUT, NOTIFICATION_KEY } from '../const';
import { getLogoutTime } from './checkLogoutTimeExpired';
import { getFormattedTime } from './getFormattedTime';

type UseLogoutWarningNotificationReturnValue = {
  showNotification: (onClickButton: () => void, onEnd: () => void) => Maybe<NodeJS.Timer>;
  destroyNotification: () => void;
};

export const useLogoutWarningNotification = (): UseLogoutWarningNotificationReturnValue => {
  const { t } = useTranslation();

  const { notification } = App.useApp();

  const onClickWrapper = (callback: () => void): void => {
    callback();
    notification.destroy(NOTIFICATION_KEY);
  };

  const startNotificationTimer = (onClickButton: () => void, onEnd: () => void): Maybe<NodeJS.Timer> => {
    return setInterval(() => {
      const logoutTime = getLogoutTime();
      if (!logoutTime) {
        onEnd();
        return;
      }
      const currentTime = dayjs();
      const difference = Math.floor(logoutTime.diff(currentTime) / 1000);

      if (difference > DEFAULT_LOGOUT_NOTIFICATION_TIMEOUT / 1000) {
        onClickWrapper(onClickButton);
        return;
      }

      if (difference <= 0) {
        onEnd();
        return;
      }
      notification.warning({
        key: NOTIFICATION_KEY,
        message: t('Auth:LogoutNotification.content.message', { remainingTime: getFormattedTime(difference) }),
        duration: 0,
        btn: (
          <Button
            type="primary"
            size="small"
            onClick={() => onClickWrapper(onClickButton)}
            data-testid="logout-stay-in-button"
            style={{ marginRight: '10px' }}
          >
            {t('Auth:LogoutNotification.content.button.text')}
          </Button>
        ),
        closable: false,
      });
    }, 1000);
  };

  const destroyNotification = (): void => notification.destroy(NOTIFICATION_KEY);

  return { showNotification: startNotificationTimer, destroyNotification };
};
