import React, { ReactNode } from 'react';
import { Button } from 'antd';
import { TFunction, withTranslation } from 'react-i18next';

import { ReactComponent as KajunIcon } from 'shared/assets/icons/login-logo.svg';

import { getErrorText } from './ErrorBoundary.lib';

import styles from './ErrorBoundary.module.scss';

interface IProps {
  children: React.ReactNode;
  t: TFunction;
  onRestore: () => void;
}

interface IState {
  hasError: boolean;
  error: unknown;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  // eslint-disable-next-line react/state-in-constructor
  public state: IState = {
    hasError: false,
    error: {
      name: '',
      message: '',
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): IState {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error: {
        name: '',
        message: '',
      },
    };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({ error });
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public render(): ReactNode {
    const { hasError, error } = this.state;
    const { children, t, onRestore } = this.props;

    if (hasError) {
      return (
        <div className={styles.rootWrapper}>
          <div className={styles.logoHolder}>
            <KajunIcon />
          </div>
          <div className={styles.errorHeading}>
            <b>An error has occurred. Please, contact administrator.</b>
          </div>
          <div className={styles.errorMessage}>
            <b>Error: </b>
            <span>{getErrorText(error, t)}</span>
          </div>
          <Button type="primary" onClick={onRestore} className={styles.customButton} data-testid="restore-app-button">
            Go to log in
          </Button>
        </div>
      );
    }

    return children;
  }
}

export default withTranslation()(ErrorBoundary);
