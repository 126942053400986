import { ReactElement } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Dropdown, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { authUserService } from 'entities/user';
import { withSuspense } from 'shared/lib/react';
import { pathKeys } from 'shared/lib/react-router';
import { Avatar } from 'shared/ui/Avatar';

// TODO: just fix it....
// eslint-disable-next-line no-restricted-imports
import customDropdownStyles from 'shared/ui/CustomDropdown/CustomDropdown.module.scss';
import styles from './UserMenu.module.scss';

function UserMenu(): ReactElement {
  const { t } = useTranslation();

  const { data: authUserInfo } = useSuspenseQuery(authUserService.queryOptions());

  const fullName = `${authUserInfo?.firstName} ${authUserInfo?.lastName}`;

  const items = [
    {
      key: '1',
      label: <Link to={pathKeys.logout()}>{t('UI:UserMenu.logOutButton.label')}</Link>,
    },
  ];

  return (
    // TODO: rework to custom dropdown component
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      arrow
      trigger={['click']}
      overlayClassName={customDropdownStyles.customDropdown}
    >
      <div className={styles.userMenu}>
        <div className={styles.iconWrapper}>
          <div className={styles.userIcon}>
            <Avatar picture={authUserInfo?.picture} text={fullName} size={40} gap={3} />
          </div>
        </div>
      </div>
    </Dropdown>
  );
}

export default withSuspense(UserMenu, { fallback: <Skeleton.Avatar active size={40} /> });
