import React from 'react';
import { useParams } from 'react-router-dom';

import ProjectCreateForm from 'components/Project/ProjectCreateForm/ProjectCreateForm';

const ProjectCreatePage: React.FC = () => {
  const { accountId = '' } = useParams();

  return <ProjectCreateForm accountId={accountId} data-testid="project-create-page" />;
};

export default ProjectCreatePage;
