import React, { ReactNode, useEffect, useState } from 'react';
import { AvatarProps, Avatar as AntdAvatar } from 'antd';

import { getAcronym } from './lib/getAcronym';
import Group from './ui/Group/Group';
import styles from './Avatar.module.scss';

type AvatarExtensions = {
  Group: typeof Group;
};

interface IAvatarProps extends Omit<AvatarProps, 'src' | 'srcSet' | 'children'> {
  picture: ReactNode | null;
  text: string;
}
const Avatar: React.FC<IAvatarProps> & AvatarExtensions = (props) => {
  const { picture, text, ...otherAvatarsProps } = props;
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (picture) {
      const img = new Image();
      img.onload = () => {
        setImageLoaded(true);
      };
      img.src = picture as string;
    }
  }, [picture]);

  return (
    <AntdAvatar
      icon={<div className={styles.acronym}>{getAcronym(text)}</div>}
      src={imageLoaded ? picture : null}
      style={{
        backgroundColor: styles.clrAvatar,
        color: '#FFFFFF',
        ...otherAvatarsProps.style,
      }}
      data-test-id="avatar-item"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherAvatarsProps}
    />
  );
};

Avatar.Group = Group;

export default Avatar;
