import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import AccessGroupEN from 'shared/assets/locales/AccessGroupEN.json';
import AccessGroupRU from 'shared/assets/locales/AccessGroupRU.json';
import AccountEN from 'shared/assets/locales/AccountEN.json';
import AccountRU from 'shared/assets/locales/AccountRU.json';
import AssignmentEN from 'shared/assets/locales/AssignmentEN.json';
import AssignmentRU from 'shared/assets/locales/AssignmentRU.json';
import AuthEN from 'shared/assets/locales/AuthEN.json';
import AuthRU from 'shared/assets/locales/AuthRU.json';
import DemandEN from 'shared/assets/locales/DemandEN.json';
import DemandRU from 'shared/assets/locales/DemandRU.json';
import ExceptionEN from 'shared/assets/locales/ExceptionEN.json';
import ExceptionRU from 'shared/assets/locales/ExceptionRU.json';
import PositionEN from 'shared/assets/locales/PositionEN.json';
import PositionRU from 'shared/assets/locales/PositionRU.json';
import ProjectEN from 'shared/assets/locales/ProjectEN.json';
import ProjectRU from 'shared/assets/locales/ProjectRU.json';
import ReusableComponentsEN from 'shared/assets/locales/ReusableComponentsEN.json';
import ReusableComponentsRU from 'shared/assets/locales/ReusableComponentsRU.json';
import UIEN from 'shared/assets/locales/UIEN.json';
import UIRU from 'shared/assets/locales/UIRU.json';
import UserOverviewEN from 'shared/assets/locales/UserOverviewEN.json';
import UserOverviewRU from 'shared/assets/locales/UserOverviewRU.json';

import { LocalStorageService } from 'shared/lib/localStorage';

import { ISOLanguageCodes } from './const';

i18n.use(initReactI18next).init({
  resources: {},
  lng: LocalStorageService.getItem<string>('locale') ?? ISOLanguageCodes.ENGLISH,
  keySeparator: false,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
});

const TranslatedISOLanguagesCodes = [ISOLanguageCodes.ENGLISH, ISOLanguageCodes.RUSSIAN] as const;

type LanguageItem = Record<string, string | Record<string, string>>;

type LanguageSet = Record<typeof TranslatedISOLanguagesCodes[number], LanguageItem>;

interface II18ConfigMap {
  Auth: LanguageSet;
  UI: LanguageSet;
  ReusableComponents: LanguageSet;
  AccessGroup: LanguageSet;
  Account: LanguageSet;
  Project: LanguageSet;
  Position: LanguageSet;
  Assignment: LanguageSet;
  Demand: LanguageSet;
  Exception: LanguageSet;
  UserOverview: LanguageSet;
}

const i18ConfigMap: II18ConfigMap = {
  Auth: { en: AuthEN, ru: AuthRU },
  UI: { en: UIEN, ru: UIRU },
  ReusableComponents: { en: ReusableComponentsEN, ru: ReusableComponentsRU },
  AccessGroup: { en: AccessGroupEN, ru: AccessGroupRU },
  Account: { en: AccountEN, ru: AccountRU },
  Project: { en: ProjectEN, ru: ProjectRU },
  Position: { en: PositionEN, ru: PositionRU },
  Assignment: { en: AssignmentEN, ru: AssignmentRU },
  Demand: { en: DemandEN, ru: DemandRU },
  Exception: { en: ExceptionEN, ru: ExceptionRU },
  UserOverview: { en: UserOverviewEN, ru: UserOverviewRU },
};

Object.keys(i18ConfigMap).forEach((page: string) => {
  const list = i18ConfigMap[page as keyof II18ConfigMap];

  Object.keys(list).forEach((lang: string) => {
    i18n.addResourceBundle(lang, page, list[lang as keyof LanguageSet], true, true);
  });
});

export default i18n;
