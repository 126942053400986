import { RequiredPermissions, pathPermissions } from 'features/validateAccess';
import { TFunctionArgument, UIType } from 'shared/declarations/i18n';

import { pathKeys } from 'shared/lib/react-router';

export interface INavItem {
  path: string;
  captionID: TFunctionArgument<UIType, 'UI:'>;
  id: string;
  requiredPermissions: RequiredPermissions;
}

export const navItems: INavItem[] = [
  {
    path: pathKeys.dashboard(),
    captionID: 'UI:MainNav.menuItem.dashboard',
    id: '1-1',
    requiredPermissions: pathPermissions.dashboard,
  },
  {
    path: pathKeys.accessGroups(),
    captionID: 'UI:MainNav.menuItem.accessGroups',
    id: '1-2',
    requiredPermissions: pathPermissions.accessGroups,
  },
  {
    path: pathKeys.account.root(),
    captionID: 'UI:MainNav.menuItem.accounts',
    id: '1-3',
    requiredPermissions: pathPermissions.account.root,
  },
  {
    path: pathKeys.userOverview(),
    captionID: 'UI:MainNav.menuItem.userOverview',
    id: '1-4',
    requiredPermissions: pathPermissions.userOverview,
  },
  // {
  //   path: `/${ROUTES.TEST.path}`,
  //   captionID: 'UI:MainNav.menuItem.test',
  //   id: '1-5',
  //   requiredPermissions: ROUTES.TEST.requiredPermissions,
  // },
];
