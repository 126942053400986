import i18n from 'i18next';
import { ISOLanguageCodes } from 'shared/lib/i18n';
import { LocalStorageService } from 'shared/lib/localStorage';

const LanguageService = {
  setDocumentLangAttribute(langCode: ISOLanguageCodes) {
    document.documentElement.lang = langCode;
  },
  changeLanguage(locale: ISOLanguageCodes) {
    LocalStorageService.setItem('locale', locale);
    i18n.changeLanguage(locale);
    this.setDocumentLangAttribute(locale);
  },
  getCurrentLanguage() {
    return i18n.language;
  },
  isCurrentLanguageIsEnglish() {
    return this.getCurrentLanguage() === ISOLanguageCodes.ENGLISH;
  },
  isCurrentLanguageIsRussian() {
    return this.getCurrentLanguage() === ISOLanguageCodes.RUSSIAN;
  },
};

export default LanguageService;
