import dayjs from 'dayjs';
import { LocalStorageService } from 'shared/lib/localStorage';
import { Maybe } from 'shared/types';
import { LOGOUT_TIME_KEY } from '../const';

export const getLogoutTime = (): Maybe<dayjs.Dayjs> => {
  const logoutTime = LocalStorageService.getItem<string>(LOGOUT_TIME_KEY);
  if (!logoutTime) return null;

  const paresedLogoutTime = dayjs(logoutTime);

  return paresedLogoutTime;
};
