import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { assignmentCreatePageRoute } from 'pages/AssignmentCreatePage';
import { assignmentUpdatePageRoute } from 'pages/AssignmentUpdatePage';
import { demandCreatePageRoute } from 'pages/DemandCreatePage';
import { demandUpdatePageRoute } from 'pages/DemandUpdatePage';
import { positionCreatePageRoute } from 'pages/PositionCreatePage';
import { positionUpdatePageRoute } from 'pages/PositionUpdatePage';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';

import { positionsService } from 'entities/position';

import { pathKeys } from 'shared/lib/react-router';

import { DEFAULT_IS_HIDDEN_EXPIRED_POSITIONS, PAGE_SIZE } from 'components/PositionAndAssignment/Position.consts';

import PositionsPage from './PositionsPage';

export const positionsPageRoute: RouteObject = {
  path: '/accounts/:accountId/projects/:projectId/positions/',
  children: [
    {
      index: true,
      element: createElement(PositionsPage),
      loader: async (args) => {
        const requiredPermissions = replacePermissions(pathPermissions.position.root, args.params);
        if (!isHasAccess(requiredPermissions)) {
          return redirect(pathKeys.dashboard());
        }

        if (!args.params.projectId || !args.params.accountId) {
          return redirect(pathKeys.page404());
        }

        Promise.resolve(
          positionsService.prefetchQuery({
            projectId: args.params.projectId,
            page: 0,
            size: PAGE_SIZE,
            showArchived: false,
            expiredIncluded: DEFAULT_IS_HIDDEN_EXPIRED_POSITIONS,
          }),
        );

        return args;
      },
    },
    positionCreatePageRoute,
    positionUpdatePageRoute,
    assignmentCreatePageRoute,
    assignmentUpdatePageRoute,
    demandCreatePageRoute,
    demandUpdatePageRoute,
  ],
};
