import { Params } from 'react-router-dom';
import { RequiredPermissions } from '../types';

export const replacePermissions = (
  requiredPermissions: RequiredPermissions,
  params: Readonly<Params<string>>,
): RequiredPermissions =>
  requiredPermissions.map((blockPermission) =>
    blockPermission.map((permission) => {
      if (permission.type === 'local') {
        const local = params[permission.local];

        return { ...permission, local: local ?? permission.local };
      }
      if (permission.type === 'subGlobal') {
        const parent = params[permission.parent];
        return { ...permission, parent: parent ?? permission.parent };
      }

      return permission;
    }),
  );
