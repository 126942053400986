import { LanguageService } from 'features/changeLanguage';

import { DEFAULT_LANGUAGE } from 'shared/config';
import { ISOLanguageCodes } from 'shared/lib/i18n';
import { LocalStorageService } from 'shared/lib/localStorage';

export function initApp(): void {
  const storedLocale = (LocalStorageService.getItem<string>('locale') ?? DEFAULT_LANGUAGE) as ISOLanguageCodes;
  LanguageService.setDocumentLangAttribute(storedLocale);
}
