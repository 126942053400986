import { useState } from 'react';
import { Dropdown } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Delete } from 'shared/assets/icons/dynamicColor/delete.svg';
import { ReactComponent as Duplicate } from 'shared/assets/icons/dynamicColor/duplicate.svg';
import { ReactComponent as Edit } from 'shared/assets/icons/dynamicColor/edit.svg';
import { ReactComponent as Rename } from 'shared/assets/icons/dynamicColor/upload.svg';
import { ReactComponent as MenuKebab } from 'shared/assets/icons/menu-kebab.svg';

import styles from './ColumnMenu.module.scss';

function callbackWrapper<T extends (paramId: string) => void>(
  callback: T | undefined,
  paramId: string,
): (() => void) | undefined {
  if (callback) {
    return () => callback(paramId);
  }

  return callback;
}

const ColumnMenu = ({
  id,
  disabled,
  onEdit,
  onRename,
  onDuplicate,
  onDelete,
}: {
  id: string;
  disabled?: boolean;
  onEdit?: (id: string) => void;
  onRename?: (id: string) => void;
  onDuplicate?: (id: string) => void;
  onDelete?: (id: string) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const items: ItemType[] = [
    {
      key: 1,
      disabled: !onEdit,
      className: styles.optionMenu,
      icon: <Edit fill="black" />,
      label: <span className={styles.options}>{t('AccessGroup:ColumnMenu.edit.text')}</span>,
      onClick: callbackWrapper(onEdit, id),
    },
    {
      key: 2,
      disabled: !onRename,
      className: styles.optionMenu,
      icon: <Rename fill="black" />,
      label: <span className={styles.options}>{t('AccessGroup:ColumnMenu.rename.text')}</span>,
      onClick: callbackWrapper(onRename, id),
    },
    {
      key: 3,
      disabled: !onDuplicate,
      className: styles.optionMenu,
      icon: <Duplicate fill="black" />,
      label: <span className={styles.options}>{t('AccessGroup:ColumnMenu.duplicate.text')}</span>,
      onClick: callbackWrapper(onDuplicate, id),
    },
    {
      key: 4,
      disabled: !onDelete,
      className: styles.optionMenu,
      icon: <Delete fill="red" />,
      label: (
        <span className={classNames(styles.options, styles.optionDelete)}>
          {t('AccessGroup:ColumnMenu.delete.text')}
        </span>
      ),
      onClick: callbackWrapper(onDelete, id),
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      onOpenChange={setOpen}
      trigger={['click']}
      placement="bottomRight"
      open={open}
      arrow
      disabled={disabled}
    >
      <MenuKebab
        height={42}
        width={14}
        className={classNames(styles.menuKebab, {
          [styles.openMenu]: open,
          [styles.closeMenu]: !open,
          [styles.disabledMenu]: disabled,
        })}
        data-testid="menu-kebab"
      />
    </Dropdown>
  );
};
export default ColumnMenu;
