import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';
import { positionService } from 'entities/position';

import { pathKeys } from 'shared/lib/react-router';

import AssignmentCreatePage from './AssignmentCreatePage';

export const assignmentCreatePageRoute: RouteObject = {
  path: ':positionId/assignments/create/',
  element: createElement(AssignmentCreatePage),
  loader: async (args) => {
    const requiredPermissions = replacePermissions(pathPermissions.assignment.create, args.params);
    if (!isHasAccess(requiredPermissions)) {
      return redirect(pathKeys.dashboard());
    }

    if (!args.params.positionId) {
      return redirect(pathKeys.page404());
    }

    Promise.resolve(positionService.prefetchQuery(args.params.positionId));

    return args;
  },
};
