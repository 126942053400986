import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const getAssignmentGlobalEditPermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.UPDATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.UPDATE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const getAssignmentLocalEditPermissions = (assignmentId: string): RequiredPermissions => [
  [
    {
      type: 'local',
      local: assignmentId,
      global: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.UPDATE,
    },
  ],
];

export const getAssignmentArchivePermissions = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.ARCHIVE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.ARCHIVE,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.ARCHIVE,
    },
  ],
];
