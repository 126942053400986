export enum AvailableGlobalPermissions {
  ACCOUNT = 'Account',
  PROJECT = 'Project',
  POSITION = 'Position',
  ASSIGNMENT = 'Assignment',
  USER = 'User',
  AUTH_USER = 'AuthUser',
  ACCESS_GROUP = 'AG',
  PERMISSION = 'Permission',
  DEMAND = 'Demand',
  SENSITIVE_USER_DATA = 'SensitiveUserData',
}

export enum AvailablePermissionsValue {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  ARCHIVE = 'ARCHIVE',
  DELETE = 'DELETE',
  MAP = 'MAPPING',
}

interface IGlobalPermission {
  type: 'global';
  global: AvailableGlobalPermissions;
  value: AvailablePermissionsValue;
}

interface ILocalPermission extends Omit<IGlobalPermission, 'type'> {
  type: 'local';
  local: string;
}

interface IParentPermission extends Omit<IGlobalPermission, 'type'> {
  type: 'subGlobal';
  parent: string;
  subGlobal: AvailableGlobalPermissions;
}

type RequiredPermission = IGlobalPermission | ILocalPermission | IParentPermission;
// [[ { global: first, value: READ }, { global: other, value: READ } ]] - means THE FIRST OR SECOND
// [[{ global: first, value: READ } ], [ { global: other, value: READ } ]] - means THE FIRST AND SECOND
export type RequiredPermissions = RequiredPermission[][];
