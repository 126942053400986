import { ReactElement } from 'react';
import { App } from 'antd';
import { Outlet } from 'react-router-dom';
import { SessionTimerProvider } from 'features/sessionTimer';
import { UserCard } from 'features/UserCard';
import { LONG_NOTIFICATION_DURATION } from 'shared/config';
import { NotificationProvider } from 'shared/ui/NotificationProvider';

export function RootPage(): ReactElement {
  return (
    <App component={false} notification={{ top: 84, duration: LONG_NOTIFICATION_DURATION, placement: 'topRight' }}>
      <Outlet />
      <UserCard />
      <NotificationProvider />
      <SessionTimerProvider />
    </App>
  );
}
