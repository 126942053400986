/* eslint-disable react/jsx-props-no-spreading */
import { SuspenseProps, Suspense } from 'react';

const withSuspense =
  <P extends object>(Component: React.ComponentType<P>, suspenseProps: SuspenseProps) =>
  (props: P) => {
    return (
      <Suspense {...suspenseProps}>
        <Component {...props} />
      </Suspense>
    );
  };

export { withSuspense };
