import React from 'react';

import { UserName } from 'features/UserCard';

import { Maybe } from 'shared/types';
import { Avatar } from 'shared/ui/Avatar';

import styles from './UserOverviewWidgetItem.module.scss';

interface IUserOverviewWidgetItemProps {
  picture: Maybe<string>;
  firstName: Maybe<string>;
  lastName: Maybe<string>;
  workProfile: Maybe<string>;
  specialization: Maybe<string>;
  seniority: Maybe<string>;
  id: string;
}

const UserOverviewWidgetItem: React.FC<IUserOverviewWidgetItemProps> = (props) => {
  const { picture, firstName, lastName, workProfile, specialization, seniority, id } = props;
  return (
    <div className={styles.itemWrapper} data-testid="user-overview-item">
      <div className={styles.nameWrapper}>
        <div className={styles.avatar}>
          <Avatar size={24} picture={picture} text={`${firstName} ${lastName}`} />
        </div>
        <UserName name={`${firstName} ${lastName}`} id={id} />
      </div>
      <div>{seniority}</div>
      <div>{specialization}</div>
      <div>{workProfile}</div>
    </div>
  );
};

export default UserOverviewWidgetItem;
