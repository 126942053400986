import { ReactElement } from 'react';
import { Spin } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './Loader.module.scss';

function Loader({
  isVisible = true,
  isTranslucent = true,
}: {
  isVisible?: boolean;
  isTranslucent?: boolean;
}): ReactElement {
  const { t } = useTranslation();

  return (
    <div
      data-testid="loader"
      className={classNames(styles.loaderOverlay, {
        [styles.hidden]: !isVisible,
        [styles.translucent]: isTranslucent,
      })}
    >
      <Spin size="large" className={styles.loader} />
      <div className={styles.loaderFallbackText}>{t('UI:Loader.loaderFallbackText')}</div>
    </div>
  );
}

export default Loader;
