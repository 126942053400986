import { RequiredPermissions, AvailableGlobalPermissions, AvailablePermissionsValue } from 'features/validateAccess';

export const PERMISSION_TAB_PERMISSIONS: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.PERMISSION,
      value: AvailablePermissionsValue.READ,
    },
  ],
];
export const ASSIGNMENTS_TAB_PERMISSIONS: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ASSIGNMENT,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const PRIVATE_TAB_PERMISSIONS: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.SENSITIVE_USER_DATA,
      value: AvailablePermissionsValue.READ,
    },
  ],
];

export const LINK_POSITION_GLOBAL: RequiredPermissions = [
  [{ type: 'global', global: AvailableGlobalPermissions.POSITION, value: AvailablePermissionsValue.READ }],
];

export const getLinkPositionLocal = (accountId: string, projectId: string): RequiredPermissions => [
  [
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.PROJECT,
      parent: projectId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.READ,
    },
    {
      type: 'subGlobal',
      global: AvailableGlobalPermissions.ACCOUNT,
      parent: accountId,
      subGlobal: AvailableGlobalPermissions.POSITION,
      value: AvailablePermissionsValue.READ,
    },
  ],
];
