import React, { ReactNode, useRef } from 'react';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { SortOrder } from 'antd/lib/table/interface';
import InfiniteLoader from 'react-window-infinite-loader';

import { useStore } from 'zustand';
import { UserName } from 'features/UserCard';
import {
  DEFAULT_USER_OVERVIEW_SORTING,
  userOverviewService,
  userOverviewStore,
  type OrderDirection,
  type UserOverviewDTO,
} from 'entities/userOverview';

import { withSuspense } from 'shared/lib/react';
import { Maybe } from 'shared/types';
import { Avatar } from 'shared/ui/Avatar';
import { Loader } from 'shared/ui/Loader';
import { VirtualTable, virtualTableStyles } from 'shared/ui/VirtualTable';

import { columns, PAGE_SIZE, ROW_HEIGHT, THRESHOLD_VALUE } from './UserTable.const';

import userTableStyles from './UserTable.module.scss';

const UserTable: React.FC = () => {
  const loaderRef = useRef<InfiniteLoader>(null);

  const { filters, searchQuery, setOrder } = useStore(userOverviewStore);

  const {
    data: usersData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useSuspenseInfiniteQuery(userOverviewService.queryOptions({ filtration: filters, size: PAGE_SIZE, searchQuery }));

  columns[0].customRenderCellRenderer = (name: {
    id: string;
    firstName: string;
    lastName: string;
    picture: Maybe<string>;
  }): ReactNode => {
    const fullName = `${name.firstName} ${name.lastName}`;

    return (
      <div className={userTableStyles.nameCellRootWrapper}>
        <div className={virtualTableStyles.overflow}>
          <Avatar picture={name.picture} text={fullName} size={22} />
          <UserName name={fullName} id={name.id} />
        </div>
      </div>
    );
  };

  const parseSortOrder = (sortOrder: SortOrder): OrderDirection => {
    return sortOrder === 'ascend' ? 'ASC' : 'DESC';
  };

  const handleSortingChange = (fieldName?: React.Key | readonly React.Key[], order?: SortOrder): void => {
    if (!fieldName || !order) {
      setOrder(DEFAULT_USER_OVERVIEW_SORTING);
      return;
    }
    const parsedOrder = parseSortOrder(order);
    const orders = [{ fieldName: fieldName as string, orderDirection: parsedOrder }];
    if (fieldName === 'firstName') {
      orders.push({ fieldName: 'lastName', orderDirection: parsedOrder });
    }
    setOrder(orders);
  };

  const tableData = usersData.pages.reduce<UserOverviewDTO[]>((acc, page) => [...acc, ...page.content], []);

  const tableDataMapped = tableData?.map(({ id, firstName, lastName, picture, ...user }) => ({
    ...user,
    name: { id, firstName, lastName, picture },
  }));

  return (
    <VirtualTable
      columns={columns}
      dataSource={tableDataMapped}
      scroll={{ x: undefined }}
      rowHeight={ROW_HEIGHT}
      loadNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isNextPageLoading={isLoading}
      onSort={handleSortingChange}
      loaderRef={loaderRef}
      threshold={THRESHOLD_VALUE}
    />
  );
};

export default withSuspense(UserTable, { fallback: <Loader /> });
