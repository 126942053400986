import { ReactNode } from 'react';

export enum ColumnStatus {
  NONE = 'none',
  ERROR = 'error',
  EDIT = 'edit',
}

export type ColumnStatusMap = Record<string, ColumnStatus>;

export type SidebarItem = {
  id: string;
  name: string;
};

export type SidebarGroup = {
  id: string;
  name: string;
  items: SidebarItem[];
};

export type ColumnGroupItem<U> = {
  widget: U;
  id: string;
};

export type ColumnGroup<T, U> = {
  id: string;
  name: string;
  widget: T;
  items: ColumnGroupItem<U>[];
};

export type Column<T, U> = {
  id: string;
  name: string;
  content: ColumnGroup<T, U>[];
  headingWidget?: ReactNode;
};

export type Sidebar = {
  title: ReactNode;
  groups: SidebarGroup[];
};

interface IFoldingTableRawProps<T, U> {
  sidebar: Sidebar;
  columns: Column<T, U>[];
  maxPinnedColumnsCount: number;
  initialPinnedColumnKeys?: string[];
  columnsStatuses?: ColumnStatusMap;
  onChangePin?: (id: string, columnsIds: string[]) => void;
  renderGroupWidget?: (id: string, data: T) => ReactNode;
  renderWidget: (id: string, data: U, disabled: boolean) => ReactNode;
  renderHeadingMenu: (id: string) => ReactNode;
}

type ColumnsDraggableProps =
  | { isColumnDraggable?: false }
  | {
      isColumnDraggable: true;
      onChangeColumnOrder: (columnsIds: string[]) => void;
    };

export type IFoldingTableProps<T = unknown, U = unknown> = ColumnsDraggableProps & IFoldingTableRawProps<T, U>;
