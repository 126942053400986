import { Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as EmptyDataIcon } from 'shared/assets/icons/list-content.svg';

import styles from './EmptyGroup.module.scss';

export const EmptyGroup: React.FC<{ title?: React.ReactNode }> = ({ title }) => {
  const { t } = useTranslation('ReusableComponents');

  return (
    <Space align="center" direction="vertical" className={styles.container}>
      <EmptyDataIcon />
      <div>{title || t('EmptyGroup.noMatches.text')}</div>
    </Space>
  );
};
