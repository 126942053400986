/* eslint-disable react/require-default-props */
import React, { ChangeEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Input, InputRef, Menu, MenuProps, Skeleton } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import classNames from 'classnames';
import { Dayjs } from 'dayjs';

import { useTranslation } from 'react-i18next';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { availableUsersService } from 'entities/user';

import { ReactComponent as SearchIcon } from 'shared/assets/icons/search.svg';
import { DEFAULT_DATE_FORMAT_FOR_REQUESTS } from 'shared/config';
import { convertDayJsToString } from 'shared/lib/dayjs';
import { useDebounceValue } from 'shared/lib/debounce';
import { Avatar } from 'shared/ui/Avatar';
import { EmptyGroup } from 'shared/ui/EmptyGroup';
import { ModalDialog } from 'shared/ui/ModalDialog';
import { RcPagination } from 'shared/ui/RcPagination';

import styles from './SelectUserForAssignmentModal.module.scss';

const PAGE_SIZE = 10;

const SelectUserForAssignmentModal: React.FC<{
  open: boolean;
  dates?: Dayjs[];
  assignmentId?: string | null;
  involvement: number;
  setOpen: (status: boolean) => void;
  onOkButtonClick: (userId: string) => void;
}> = ({ open, setOpen, dates, assignmentId, involvement, onOkButtonClick }) => {
  const { t } = useTranslation();

  const tableRef = useRef<HTMLDivElement>(null);

  const [selectedUserId, setSelectedUserId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState('');

  const debouncedSearchInput = useDebounceValue(searchInput, 500);

  const inputRef = useRef<InputRef>(null);

  const { data: usersData, isFetching: isUsersLoading } = useQuery(
    availableUsersService.queryOptions(
      {
        query: debouncedSearchInput,
        page: currentPage - 1,
        size: PAGE_SIZE,
        sort: 'firstName,lastName',
        periodStart: dates?.[0] ? convertDayJsToString(dates[0], DEFAULT_DATE_FORMAT_FOR_REQUESTS) : '',
        periodEnd: dates?.[1] ? convertDayJsToString(dates[1], DEFAULT_DATE_FORMAT_FOR_REQUESTS) : '',
        excludedAssignmentId: assignmentId,
      },
      Array.isArray(dates) && open,
    ),
  );

  useEffect(() => {
    if (!open) {
      setSearchInput('');
      return;
    }
    setSelectedUserId('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, debouncedSearchInput, open]);

  useEffect(() => {
    if (open && inputRef) {
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [open, inputRef]);

  const onUserSelect: MenuProps['onClick'] = (e) => {
    const userId = e.key;
    setSelectedUserId(userId);
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    setSearchInput(value);
    setCurrentPage(1);
  };

  const onOkButtonClickWrapper = (): void => {
    if (selectedUserId) onOkButtonClick(selectedUserId);
    setOpen(false);
  };

  const onCancelButtonClick = (): void => {
    setOpen(false);
  };

  const renderUserTable = (): ItemType[] => {
    if (!usersData?.content) return [];

    return usersData.content.map((user) => {
      const isUserAvailable = user.availabilityLowest >= involvement;

      const availabilityPercent =
        user.availabilityLowest === user.availabilityUpper
          ? `${user.availabilityUpper}%`
          : `${user.availabilityLowest} - ${user.availabilityUpper}%`;

      return {
        icon: <Avatar text={`${user.firstName} ${user.lastName}`} picture={user.picture} size={32} />,
        label: (
          <div className={styles.rowContentWrapper}>
            <div>{`${user.firstName} ${user.lastName}`}</div>
            <div
              className={classNames(
                styles.availabilityWrapper,
                isUserAvailable ? styles.availableColor : styles.nonAvailableColor,
              )}
            >
              {availabilityPercent}
            </div>
          </div>
        ),
        key: user.id,
        disabled: !isUserAvailable,
      };
    });
  };

  const renderResult = (): ReactNode =>
    usersData?.content?.length !== 0 ? (
      <Menu className={styles.menu} onClick={onUserSelect} theme="light" items={renderUserTable()} />
    ) : (
      <EmptyGroup />
    );

  const renderBody = (): ReactElement => {
    return (
      <div className={styles.rootWrapper}>
        <div className={styles.header}>
          <Input
            value={searchInput}
            onChange={onSearch}
            suffix={<SearchIcon />}
            ref={inputRef}
            data-testid="search-input"
          />
        </div>
        <div className={classNames(styles.tableWrapper)}>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={isUsersLoading ? 'skeleton' : 'content'}
              nodeRef={tableRef}
              classNames={{
                enter: styles.tableEnter,
                enterActive: styles.tableEnterActive,
                exit: styles.tableExit,
                exitActive: styles.tableExitActive,
              }}
              timeout={200}
            >
              <div className={styles.table} ref={tableRef}>
                {isUsersLoading ? (
                  <div className={classNames(styles.listSkeleton, 'ant-menu')}>
                    {Array.from({ length: PAGE_SIZE }, (_, id) => (
                      <Skeleton
                        key={id}
                        className={classNames(styles.skeletonItem, 'ant-menu-item')}
                        loading
                        avatar={{ size: 32 }}
                        active
                        paragraph={{ rows: 0, style: { margin: 0 } }}
                      />
                    ))}
                  </div>
                ) : (
                  renderResult()
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
        <div className={styles.paginationWrapper}>
          <RcPagination
            dataSize={usersData?.totalElements ?? 0}
            pageSize={PAGE_SIZE}
            current={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    );
  };

  return (
    <ModalDialog
      open={open}
      body={renderBody()}
      header={t('ReusableComponents:UserSelectModal.heading')}
      closeFn={onCancelButtonClick}
      actionFn={onOkButtonClickWrapper}
    />
  );
};

export default React.memo(SelectUserForAssignmentModal);
