import { FC, useEffect, useRef } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Form, Input, InputRef } from 'antd';
import { RuleObject } from 'antd/es/form';
import { useTranslation } from 'react-i18next';

import { accessGroupsService } from 'entities/accessGroup';

const SPECIAL_CHARACTERS = '!№;%:?*()_+=\\-\\\\\\/#$^&|\\}\\]\\[\\{';
const regexp = new RegExp(`^[0-9A-Za-z${SPECIAL_CHARACTERS}]+([0-9A-Za-z ${SPECIAL_CHARACTERS}])*$`);

const NameInput: FC = () => {
  const { t } = useTranslation('AccessGroup');

  const { data: accessGroupList } = useSuspenseQuery(accessGroupsService.queryOptions());

  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputRef) {
      setTimeout(() => inputRef.current?.focus(), 0);
    }
  }, [inputRef]);

  const checkInputValidity = (_: RuleObject, inputValue: string): Promise<void | string> => {
    if (inputValue.length < 1 || inputValue.length > 30) {
      return Promise.reject(t('RenameModal.error.nameLength.text'));
    }
    if (!regexp.test(inputValue)) {
      return Promise.reject(t('RenameModal.error.invalidCharacters.text'));
    }
    if (accessGroupList.some((item) => item.name === inputValue)) {
      return Promise.reject(t('RenameModal.error.alreadyExists.text'));
    }
    return Promise.resolve();
  };

  return (
    <Form.Item
      name="name"
      rules={[
        {
          validator: checkInputValidity,
        },
      ]}
    >
      <Input ref={inputRef} allowClear placeholder={t('RenameModal.nameInput.placeholder')} />
    </Form.Item>
  );
};

export default NameInput;
