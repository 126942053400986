import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { App, Checkbox, DatePicker, DatePickerProps, Form, Input, Space, Tooltip } from 'antd';
import { RuleObject } from 'antd/lib/form';
import { StoreValue } from 'antd/lib/form/interface';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { SelectUserForAssignmentModal } from 'features/selectUserForAssignment';
import { SettingService } from 'features/userSettings';

import { AssignmentDTO } from 'entities/assignment';
import { PositionDTO } from 'entities/position';
import { userService } from 'entities/user';

import { ReactComponent as ExclamationCircle } from 'shared/assets/icons/exclamation-circle.svg';
import { DEFAULT_DATE_FORMAT_UI } from 'shared/config';
import { convertToDayJs } from 'shared/lib/dayjs';
import calendarStyles from 'shared/styles/ant-custom-components/calendar.module.scss';

import styles from './AssignmentCreateUpdateFormItems.module.scss';

type AssignmentCreateUpdateFormItemsType = {
  isLoading: boolean;
  editMode?: boolean;
  isInvolvementsEqual?: boolean;
  isDatesIntersected?: boolean;
  onChangeFn?: () => void;
  position: PositionDTO;
  assignment?: AssignmentDTO;
};

const AssignmentCreateUpdateFormItems: React.FC<AssignmentCreateUpdateFormItemsType> = (props) => {
  const {
    isLoading,
    editMode = false,
    isInvolvementsEqual = true,
    isDatesIntersected,
    onChangeFn,
    position,
    assignment,
  } = props;

  const { modal } = App.useApp();

  const { RangePicker } = DatePicker;
  const formControl = Form.useFormInstance();
  const involvementValue = Form.useWatch('involvement', formControl);
  const currentUserId = Form.useWatch('user', formControl);
  const datesValues = Form.useWatch('date', formControl);

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [isInvolvementSynchronized, setIsInvolvementSynchronized] = useState(true);
  const [isInvolvementValid, setIsInvolvementValid] = useState(editMode);
  const [isDatesValid, setIsDatesValid] = useState(editMode);
  const [username, setUsername] = useState<string>(
    t('Assignment:AssignmentCreateUpdateFormItems.item.user.input.defaultValue'),
  );

  const valueOfCheckbox = useRef(false);

  const shouldShowModalOnChangeField = useRef(false);
  const isDatesIntersectedChecked = useRef(true);

  const { data: selectedUser } = useQuery(userService.queryOptions(currentUserId, !!currentUserId));

  //* set username for user field
  useEffect(() => {
    if (!currentUserId || !selectedUser?.firstName) {
      setUsername(t('Assignment:AssignmentCreateUpdateFormItems.item.user.input.defaultValue'));
      return;
    }
    setUsername(`${selectedUser.firstName} ${selectedUser.lastName}`);
  }, [currentUserId, selectedUser, t, formControl]);

  //* if isInvolvementEqual provided set involvements sync
  useEffect(() => {
    setIsInvolvementSynchronized(isInvolvementsEqual);
    if (onChangeFn && formControl.getFieldValue('billedInvolvement')) {
      onChangeFn();
    }
  }, [formControl, isInvolvementsEqual, onChangeFn]);

  //* handle response with intersection error after submit
  useEffect(() => {
    if (isDatesIntersected && formControl.getFieldValue('date')) {
      isDatesIntersectedChecked.current = false;
      formControl.validateFields(['date']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDatesIntersected]);

  //* Get User by userId and set userId to data-value prop on change selectedUserId
  useEffect(() => {
    if (onChangeFn) {
      onChangeFn();
    }

    if (SettingService.getLocalSetting('shouldShowConfirmModalOnChangeAssignment') !== false) {
      shouldShowModalOnChangeField.current = true;
    }
  }, [formControl, onChangeFn]);

  //* synchronize involvement & billedInvolvement if checkbox unchecked
  useEffect(() => {
    if (!isInvolvementSynchronized) return;

    formControl.setFieldsValue({ billedInvolvement: involvementValue });

    if (onChangeFn && typeof formControl.getFieldValue('billedInvolvement') !== 'undefined') {
      onChangeFn();
    }
  }, [formControl, involvementValue, isInvolvementSynchronized, onChangeFn]);

  const disabledDate = (current: Dayjs): boolean => {
    const PositionStartDate = convertToDayJs(position?.startDate);
    const positionEndDate = convertToDayJs(position?.endDate);

    const isDateBeforePositionStartDate = current && current.isBefore(PositionStartDate.startOf('day'));
    const isDateAfterPositionEndDate = current && current.isAfter(positionEndDate.endOf('day'));

    return isDateAfterPositionEndDate !== isDateBeforePositionStartDate;
  };

  const validateInvolvementInput = (_: RuleObject, value: StoreValue): Promise<void> => {
    if (typeof value !== undefined && /^-?\d+$/.test(value) && Number(value) >= 0 && Number(value) <= 100) {
      setIsInvolvementValid(true);
      return Promise.resolve();
    }

    setIsInvolvementValid(false);
    return Promise.reject();
  };

  const isDatesIntersectedValidator = (): Promise<void> => {
    if (isDatesIntersected && !isDatesIntersectedChecked.current) {
      isDatesIntersectedChecked.current = true;
      shouldShowModalOnChangeField.current = false;
      setIsDatesValid(false);
      formControl.setFieldsValue({ user: '' });
      return Promise.reject();
    }

    setIsDatesValid(Array.isArray(datesValues));
    return Promise.resolve();
  };

  const shouldDisableUserInput = isLoading || !(isDatesValid && isInvolvementValid);

  const resetUserField = (): void => {
    shouldShowModalOnChangeField.current = false;
    formControl.setFieldsValue({ user: '' });
  };

  const renderCustomCell: DatePickerProps<Dayjs>['cellRender'] = (current, info) => {
    if (info.type !== 'date') {
      return info.originNode;
    }
    if (typeof current === 'number' || typeof current === 'string') {
      return <div className="ant-picker-cell-inner">{current}</div>;
    }
    return (
      <div title="" className={calendarStyles.innerCellWrapper}>
        <div className="ant-picker-cell-inner">{current.date()}</div>
      </div>
    );
  };

  const renderErrorTooltip = (tooltipText: string): React.ReactElement => {
    return (
      <div className={styles.errorTooltip}>
        <div>{t('Assignment:AssignmentCreateUpdateFormItems.items.involvements.error.text')}</div>
        <Tooltip title={tooltipText}>
          <ExclamationCircle height={18} width={18} fill="#ff0000" />
        </Tooltip>
      </div>
    );
  };

  const renderPanel = (panel: ReactNode): ReactNode => {
    if (shouldShowModalOnChangeField.current) {
      return <div />;
    }

    return panel;
  };

  const onFocusInvolvementAndDates = (): void => {
    if (shouldShowModalOnChangeField.current) {
      modal.warning({
        content: (
          <div className={styles.clearUserModal}>
            <div>{t('Assignment:AssignmentCreateUpdateFormItems.clearUserModal.text')}</div>
            <Checkbox
              onChange={(e) => {
                valueOfCheckbox.current = e.target.checked;
              }}
            >
              {t('Assignment:AssignmentCreateUpdateFormItems.clearUserModal.doNotShow.checkbox.text')}
            </Checkbox>
          </div>
        ),
        centered: true,
        onOk: () => {
          if (valueOfCheckbox.current) {
            SettingService.saveLocalSetting('shouldShowConfirmModalOnChangeAssignment', !valueOfCheckbox.current);
          }
          shouldShowModalOnChangeField.current = false;
        },
        onCancel: () => {
          shouldShowModalOnChangeField.current = false;
        },
      });
    }
  };

  return (
    <div className={styles.formItemsWrapper}>
      <Space direction="vertical">
        <Form.Item
          name="date"
          label={t('Assignment:AssignmentCreateUpdateFormItems.item.dates.label')}
          rules={[
            {
              required: true,
              message: t('Assignment:AssignmentCreateUpdateFormItems.item.dates.validationMessage.required'),
            },
            {
              validator: isDatesIntersectedValidator,
              message: t('Assignment:AssignmentCreateUpdateFormItems.item.dates.validationMessage.dateIntersection'),
            },
          ]}
          data-testid="form-date-item"
        >
          <RangePicker
            placeholder={[
              t('Assignment:AssignmentCreateUpdateFormItems.item.dates.startDate.placeholder'),
              t('Assignment:AssignmentCreateUpdateFormItems.item.dates.endDate.placeholder'),
            ]}
            disabledDate={disabledDate}
            disabled={isLoading}
            format={DEFAULT_DATE_FORMAT_UI}
            data-testid="date-input"
            className={styles.datePicker}
            cellRender={renderCustomCell}
            onChange={resetUserField}
            onFocus={onFocusInvolvementAndDates}
            panelRender={renderPanel}
          />
        </Form.Item>
        <div className={styles.involvements}>
          <Form.Item
            name="involvement"
            label={t('Assignment:AssignmentCreateUpdateFormItems.item.involvement.label')}
            rules={[
              {
                validator: validateInvolvementInput,
                message: renderErrorTooltip(
                  t('Assignment:AssignmentCreateUpdateFormItems.item.involvement.validationMessage.range'),
                ),
              },
            ]}
          >
            <Input
              data-testid="involvement"
              addonAfter="%"
              onChange={resetUserField}
              onFocus={onFocusInvolvementAndDates}
            />
          </Form.Item>
          <div className={styles.billedInvolvementWrapper}>
            <Form.Item
              name="billedInvolvement"
              label={t('Assignment:AssignmentCreateUpdateFormItems.item.billedInvolvement.label')}
              rules={[
                {
                  validator: validateInvolvementInput,
                  message: renderErrorTooltip(
                    t('Assignment:AssignmentCreateUpdateFormItems.item.billedInvolvement.validationMessage.range'),
                  ),
                },
              ]}
            >
              <Input disabled={isInvolvementSynchronized} data-testid="billed-involvement" addonAfter="%" />
            </Form.Item>
            <Checkbox
              checked={!isInvolvementSynchronized}
              onChange={() => setIsInvolvementSynchronized(!isInvolvementSynchronized)}
            >
              {t('Assignment:AssignmentCreateUpdateFormItems.item.billedInvolvement.synchronizationCheckbox.label')}
            </Checkbox>
          </div>
        </div>
        <Form.Item
          label={t('Assignment:AssignmentCreateUpdateFormItems.item.user.input.label')}
          name="user"
          data-testid="user-name-item"
          rules={[
            {
              required: true,
              message: t('Assignment:AssignmentCreateUpdateFormItems.item.user.validationMessage.required'),
            },
          ]}
          valuePropName="data-value"
        >
          <Input
            readOnly
            disabled={shouldDisableUserInput}
            value={username}
            data-testid="name-input"
            className={styles.nameInput}
            onClick={() => setVisible(true)}
          />
        </Form.Item>
      </Space>
      <SelectUserForAssignmentModal
        open={visible}
        setOpen={setVisible}
        onOkButtonClick={(id: string) => formControl.setFieldsValue({ user: id })}
        involvement={involvementValue}
        dates={datesValues}
        assignmentId={editMode ? assignment?.id : null}
      />
    </div>
  );
};

export default React.memo(AssignmentCreateUpdateFormItems);
