import { authStore } from 'entities/session';
import { RequiredPermissions } from '../types';

const comparePermissions = (requiredPermissions: RequiredPermissions, availablePermission: Set<string>): boolean => {
  // we use permission.global and permission.grantType as key for access
  const requiredAccesses: boolean[] = Array.from(requiredPermissions, () => false);

  // check permissions
  requiredPermissions.forEach((blockPermission, index) => {
    for (let i = 0; i < blockPermission.length; i += 1) {
      const permission = blockPermission[i];

      switch (permission.type) {
        case 'global':
          requiredAccesses[index] = availablePermission.has(`${permission.global}:${permission.value}`);
          break;
        case 'local':
          requiredAccesses[index] = availablePermission.has(
            `${permission.global}:${permission.local}:${permission.value}`,
          );
          break;
        case 'subGlobal':
          requiredAccesses[index] = availablePermission.has(
            `${permission.global}:${permission.parent}:${permission.subGlobal}:${permission.value}`,
          );
          break;
        default:
          break;
      }

      if (requiredAccesses[index]) {
        break;
      }
    }
  });

  const hasAccess = !requiredAccesses.includes(false);

  return hasAccess;
};

export const isHasAccess = (requiredPermissions: RequiredPermissions): boolean => {
  const currentUserPermissions = authStore.getState().authUser?.permissions || new Set();

  const hasAccess = comparePermissions(requiredPermissions, currentUserPermissions);

  return hasAccess;
};
