import { queryOptions as tsqQueryOptions, useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { keys as permissionKeys } from 'entities/permission';
import { keys as userKeys } from 'entities/user';

import { CommonError } from 'shared/api/types';
import { handleRequestError } from 'shared/lib/axios';
import { queryClient } from 'shared/lib/react-query';
import { addNotification } from 'shared/model/notification';

import {
  accessGroupAddUsers,
  accessGroupCreate,
  accessGroupDuplicate,
  accessGroupListGet,
  accessGroupRemove,
  accessGroupRemoveUsers,
  accessGroupUpdate,
  accessGroupUpdatePermissions,
  addLocalMapping,
  getGrantTypes,
  removeLocalMapping,
} from './requests';

import {
  AccessGroupDTO,
  AccessGroupDuplicateRequest,
  AccessGroupMappingRequest,
  AccessGroupUpdatePermissionRequest,
  AccessGroupUpdateRequest,
  AccessGroupUsersAddRequest,
  AccessGroupUsersDeleteRequest,
  GrantTypeDTO,
} from './types';

export const keys = {
  root: () => ['accessGroup'] as const,
  accessGroupsList: () => [...keys.root(), 'accessGroupsList'] as const,
  create: () => [...keys.root(), 'create'] as const,
  duplicate: () => [...keys.root(), 'duplicate'] as const,
  rename: () => [...keys.root(), 'rename'] as const,
  remove: () => [...keys.root(), 'remove'] as const,
  updatePermissions: () => [...keys.root(), 'updatePermissions'] as const,
  addUsersToGroup: () => [...keys.root(), 'addUsersToGroup'] as const,
  removeUsersFromGroup: () => [...keys.root(), 'removeUsersFromGroup'] as const,
  addLocalMapping: () => [...keys.root(), 'addLocalMapping'] as const,
  removeLocalMapping: () => [...keys.root(), 'removeLocalMapping'] as const,
  grantTypes: () => [...keys.root(), 'grantTypes'] as const,
};

export const accessGroupsService = {
  queryKey: () => keys.accessGroupsList(),

  getCache: () => queryClient.getQueryData<AccessGroupDTO[]>(accessGroupsService.queryKey()),

  setCache: (data: AccessGroupDTO[]) => queryClient.setQueryData(accessGroupsService.queryKey(), data),

  queryOptions: () => {
    const accessKey = accessGroupsService.queryKey();
    return tsqQueryOptions({
      queryKey: accessKey,
      queryFn: async () => {
        const accessGroupList = await accessGroupListGet();
        return accessGroupList;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => accessGroupsService.getCache()!,
      initialDataUpdatedAt: () => queryClient.getQueryState(accessKey)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async () => {
    queryClient.prefetchQuery(accessGroupsService.queryOptions());
  },
};

export const grantTypesService = {
  queryKey: () => keys.grantTypes(),

  getCache: () => queryClient.getQueryData<GrantTypeDTO[]>(grantTypesService.queryKey()),

  queryOptions: () => {
    const grantTypes = grantTypesService.queryKey();
    return tsqQueryOptions({
      queryKey: grantTypes,
      queryFn: async () => {
        const grantTypesList = await getGrantTypes();
        return grantTypesList;
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      initialData: () => grantTypesService.getCache()!,
      initialDataUpdatedAt: () => queryClient.getQueryState(grantTypes)?.dataUpdatedAt,
    });
  },

  prefetchQuery: async () => {
    queryClient.prefetchQuery(grantTypesService.queryOptions());
  },
};

export const useCreateAGMutation = (): UseMutationResult<AccessGroupDTO, CommonError, string> => {
  return useMutation({
    mutationKey: keys.create(),
    mutationFn: accessGroupCreate,
    onSuccess: (data) => {
      accessGroupsService.setCache([data, ...(accessGroupsService.getCache() ?? [])]);
    },
    onError: (error) => handleRequestError(error),
  });
};

export const useRenameAGMutation = (): UseMutationResult<AccessGroupDTO, CommonError, AccessGroupUpdateRequest> => {
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.rename(),
    mutationFn: accessGroupUpdate,
    onSuccess: (data) => {
      qClient.invalidateQueries({ queryKey: keys.accessGroupsList() });
      const accessGroupsCache = accessGroupsService.getCache() ?? [];
      accessGroupsService.setCache([data, ...accessGroupsCache.filter((group) => group.id !== data.id)]);
    },
    onError: (error) => handleRequestError(error),
  });
};

export const useDuplicateAGMutation = (): UseMutationResult<
  AccessGroupDTO,
  CommonError,
  AccessGroupDuplicateRequest
> => {
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.duplicate(),
    mutationFn: accessGroupDuplicate,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: keys.accessGroupsList() });
    },
    onError: (error) => handleRequestError(error),
  });
};

export const useRemoveAGMutation = (): UseMutationResult<void, CommonError, string> => {
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.remove(),
    mutationFn: accessGroupRemove,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: keys.accessGroupsList() });
    },
    onError: (error) => handleRequestError(error),
  });
};

export const useUpdatePermissionsAGMutation = (): UseMutationResult<
  AccessGroupDTO,
  CommonError,
  AccessGroupUpdatePermissionRequest
> => {
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.updatePermissions(),
    mutationFn: accessGroupUpdatePermissions,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: keys.accessGroupsList() });
      qClient.invalidateQueries({ queryKey: permissionKeys.list() });
    },
    onError: (error) => handleRequestError(error),
  });
};

export const useAddUsersAGMutation = (): UseMutationResult<void, CommonError, AccessGroupUsersAddRequest> => {
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.addUsersToGroup(),
    mutationFn: accessGroupAddUsers,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: userKeys.accessGroupUsers() });
      qClient.invalidateQueries({ queryKey: permissionKeys.list() });
    },
    onError: (error) => handleRequestError(error),
  });
};

export const useRemoveUsersAGMutation = (): UseMutationResult<void, CommonError, AccessGroupUsersDeleteRequest> => {
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.removeUsersFromGroup(),
    mutationFn: accessGroupRemoveUsers,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: userKeys.accessGroupUsers() });
      qClient.invalidateQueries({ queryKey: permissionKeys.list() });
    },
    onError: (error) => handleRequestError(error),
  });
};

export const useAddLocalMappingAGMutation = (): UseMutationResult<void, CommonError, AccessGroupMappingRequest> => {
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.addLocalMapping(),
    mutationFn: addLocalMapping,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: permissionKeys.list() });
    },
    onError: (error) => {
      if (error.response?.data.code === 'NonePermissionInAGException') {
        const entityType = error.response?.data?.payload?.entityType;
        const nameAG = error.response?.data?.payload?.accessGroupName;

        return addNotification({
          type: 'critical',
          method: 'error',
          description: ['Exception:AUTH.NonePermissionInAGException', { name: nameAG, entityType }],
        });
      }

      return handleRequestError(error);
    },
  });
};

export const useRemoveLocalMappingAGMutation = (): UseMutationResult<void, CommonError, AccessGroupMappingRequest> => {
  const qClient = useQueryClient();

  return useMutation({
    mutationKey: keys.removeLocalMapping(),
    mutationFn: removeLocalMapping,
    onSuccess: () => {
      qClient.invalidateQueries({ queryKey: permissionKeys.list() });
    },
    onError: (error) => handleRequestError(error),
  });
};
