import { Button, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import { UserName } from 'features/UserCard';
import { MappedUserDTO } from 'entities/user';

import { ReactComponent as DeleteIcon } from 'shared/assets/icons/dynamicColor/delete_permission.svg';
import { Avatar } from 'shared/ui/Avatar';
import { EmptyGroup } from 'shared/ui/EmptyGroup';

import styles from './userList.module.scss';

type ListProps = {
  users: MappedUserDTO[];
  onRemoveUser: (id: string) => void;
  onRemoveGroup: (userId: string, groupId: string) => void;
};

export const UserList: React.FC<ListProps> = ({ users, onRemoveUser, onRemoveGroup }) => {
  const { t } = useTranslation('ReusableComponents');

  return users.length > 0 ? (
    <div className={styles.list}>
      {users.map((user) => (
        <div key={user.id} className={styles.listItem}>
          <div className={styles.leftBlock}>
            <Avatar
              className={styles.avatar}
              size="small"
              picture={user.picture}
              text={`${user.firstName} ${user.lastName}`}
            />
            <div className={styles.name}>
              <UserName name={`${user.firstName} ${user.lastName}`} id={user.id} />
            </div>
          </div>
          <span className={styles.tags}>
            {user.accessGroups.map((group) => (
              <Tag
                key={group.id}
                closable={user.accessGroups.length > 1}
                onClose={() => onRemoveGroup(user.id, group.id)}
                className={styles.tag}
              >
                {group.name}
              </Tag>
            ))}
          </span>
          <Button
            type="text"
            className={styles.deleteButton}
            icon={<DeleteIcon className={styles.deleteIcon} />}
            onClick={() => onRemoveUser(user.id)}
          />
        </div>
      ))}
    </div>
  ) : (
    <EmptyGroup title={t('LocalPermissionMapper.noUsers')} />
  );
};
