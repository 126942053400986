import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { UserName } from 'features/UserCard';
import { ReactComponent as PencilIcon } from 'shared/assets/icons/pencil.svg';
import { DEFAULT_DATE_FORMAT_UI } from 'shared/config';
import { convertDayJsToString, convertToDayJs } from 'shared/lib/dayjs';

import styles from './AssignmentListItem.module.scss';

export interface ICollapsibleFieldProps {
  username: string;
  startDate: string;
  endDate: string;
  involvement: number | string;
  editLinkHref: string;
  userId: string;
  hideEditButton?: boolean;
}

const AssignmentsListItem: FC<ICollapsibleFieldProps> = ({
  username,
  startDate,
  endDate,
  involvement,
  editLinkHref,
  userId,
  hideEditButton,
}) => {
  const startDateDayJs = convertDayJsToString(convertToDayJs(startDate), DEFAULT_DATE_FORMAT_UI);
  const endDateDayJs = convertDayJsToString(convertToDayJs(endDate), DEFAULT_DATE_FORMAT_UI);
  const dates = [startDateDayJs, endDateDayJs].join(' \u2013 ');

  return (
    <div className={styles.rowContainer} data-testid="inner-list-row">
      <div className={styles.rowContent}>
        <div>
          <UserName name={username} id={userId} />
        </div>
        <div className={styles.rightBlock}>
          <p data-testid="inner-list-item-dates">{dates}</p>
          <p data-testid="inner-list-item-involvement">{`${involvement}%`}</p>
        </div>
      </div>
      {!hideEditButton ? (
        <Link to={editLinkHref}>
          <button type="button" className={styles.pencilButton} data-testid="inner-list-item-edit-button">
            <PencilIcon className={styles.pencilIcon} />
          </button>
        </Link>
      ) : (
        <div style={{ width: 36 }} />
      )}
    </div>
  );
};

export default AssignmentsListItem;
