import { ReactElement } from 'react';

import { LogInForm } from 'widgets/LogInForm';

import { LanguageSelector } from 'features/changeLanguage';

import { useAuthUserMutation, useGoogleLoginUserMutation, useLoginUserMutation } from 'entities/session';

import { Loader } from 'shared/ui/Loader';

import styles from './LogInPage.module.scss';

function LogInPage(): ReactElement {
  const { isPending: authenticatedUserIsLoading } = useAuthUserMutation();
  const { isPending: googleLogInIsLoading } = useGoogleLoginUserMutation();
  const { isPending: logInIsLoading } = useLoginUserMutation();

  return (
    <div className={styles.formHolder} data-testid="login-page">
      <LogInForm />
      <Loader isVisible={logInIsLoading || googleLogInIsLoading || authenticatedUserIsLoading} isTranslucent />
      <div className={styles.languageSelectorHolder}>
        <LanguageSelector />
      </div>
    </div>
  );
}

export default LogInPage;
