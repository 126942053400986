import React, { FC } from 'react';
import { Dropdown } from 'antd';
import { MenuItemType } from 'antd/es/menu/interface';
import classNames from 'classnames';

import { TriggerButtonColorMode } from './CustomDropdown.types';
import styles from './CustomDropdown.module.scss';

interface IDropdownItem {
  key: string;
  label: string;
  onClick: MenuItemType['onClick'];
  disabled: boolean;
  isSelected: boolean;
}

interface ICustomDropdownProps {
  triggerButtonLabel: string;
  triggerButtonColorMode?: TriggerButtonColorMode;
  items: IDropdownItem[];
}

// TODO: refactor this dropdown
const CustomDropdown: FC<ICustomDropdownProps> = ({
  triggerButtonColorMode = TriggerButtonColorMode.Dark,
  items,
  triggerButtonLabel,
}) => {
  const menuItems = items.map((item) => ({
    key: item.key,
    label: item.label,
    onClick: item.onClick,
    disabled: item.disabled,
    className: item.isSelected ? styles.selected : '',
  }));

  return (
    <Dropdown menu={{ items: menuItems }} trigger={['click']} overlayClassName={styles.customDropdown}>
      <button
        type="button"
        onClick={(e) => e.preventDefault()}
        className={classNames(styles.triggerButton, {
          [styles.triggerButtonLight]: triggerButtonColorMode === TriggerButtonColorMode.Light,
          [styles.triggerButtonDark]: triggerButtonColorMode === TriggerButtonColorMode.Dark,
        })}
      >
        {triggerButtonLabel}
      </button>
    </Dropdown>
  );
};

export default CustomDropdown;
