import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { isHasAccess } from 'features/validateAccess';

import { assignmentsService } from 'entities/assignment';

import { pathKeys } from 'shared/lib/react-router';

import { getAssignmentGlobalEditPermissions, getAssignmentLocalEditPermissions } from '../Assignment.permissions';

import AssignmentsListItem from './components/AssignmentListItem/AssignmentListItem';
import styles from './AssignmentsList.module.scss';

interface IAssignmentListProps {
  positionId: string;
  accountId: string;
  projectId: string;
}

const AssignmentList: React.FC<IAssignmentListProps> = ({ positionId, accountId, projectId }) => {
  const canEditAllAssignments = isHasAccess(getAssignmentGlobalEditPermissions(accountId, projectId));

  const { data: assignmentListData } = useQuery(
    assignmentsService.queryOptions({ positionId, archived: false, size: 100, page: 0 }),
  );

  const renderAssignmentList = (): React.ReactNode => {
    const positionAssignments = assignmentListData?.content;

    if (!positionAssignments) return null;

    return positionAssignments?.map((assignment) => {
      const canEditAssignment = canEditAllAssignments || isHasAccess(getAssignmentLocalEditPermissions(assignment.id));
      return (
        <div key={assignment.id} className={styles.assignmentItemWrapper}>
          <AssignmentsListItem
            userId={assignment.userId}
            username={`${assignment.firstName} ${assignment.lastName}`}
            startDate={assignment.startDate}
            endDate={assignment.endDate}
            involvement={assignment.involvement}
            editLinkHref={pathKeys.assignment.update({ accountId, projectId, positionId, assignmentId: assignment.id })}
            hideEditButton={!canEditAssignment}
          />
        </div>
      );
    });
  };

  return (
    <div id={positionId} data-testid="assignment-list" className={styles.rootWrapper}>
      {renderAssignmentList()}
    </div>
  );
};

export default React.memo(AssignmentList);
