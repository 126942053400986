import { axiosInstance } from 'shared/lib/axios';
import {
  AccessGroupDTO,
  AccessGroupDuplicateRequest,
  AccessGroupUpdateRequest,
  AccessGroupUpdatePermissionRequest,
  AccessGroupUsersAddRequest,
  AccessGroupUsersDeleteRequest,
  AccessGroupMappingRequest,
  GrantTypeDTO,
} from './types';

export const accessGroupListGet = (): Promise<AccessGroupDTO[]> => {
  return axiosInstance.get('/api/auth/access-groups/all');
};

export const accessGroupCreate = (name: string): Promise<AccessGroupDTO> => {
  return axiosInstance.post(`/api/auth/access-groups`, { name });
};

export const accessGroupDuplicate = (payload: AccessGroupDuplicateRequest): Promise<AccessGroupDTO> => {
  return axiosInstance.post(`/api/auth/access-groups/duplicate`, payload);
};

export const accessGroupUpdate = (payload: AccessGroupUpdateRequest): Promise<AccessGroupDTO> => {
  const { name, id: accessGroupId } = payload;
  return axiosInstance.put(`/api/auth/access-groups/${accessGroupId}`, {
    name,
  });
};

export const accessGroupUpdatePermissions = (payload: AccessGroupUpdatePermissionRequest): Promise<AccessGroupDTO> => {
  const { id, ...requestedPayload } = payload;
  return axiosInstance.put(`/api/auth/access-groups/${id}/permissions`, {
    ...requestedPayload,
  });
};

export const accessGroupRemove = (id: string): Promise<void> => {
  return axiosInstance.delete(`/api/auth/access-groups/${id}`);
};

export const accessGroupAddUsers = (payload: AccessGroupUsersAddRequest): Promise<void> => {
  const { id, ...requestedPayload } = payload;

  return axiosInstance.post(`/api/auth/access-groups/${id}/users`, requestedPayload);
};

export const accessGroupRemoveUsers = (payload: AccessGroupUsersDeleteRequest): Promise<void> => {
  const { id, ...requestedPayload } = payload;
  return axiosInstance.delete(`/api/auth/access-groups/${id}/users`, {
    data: { ...requestedPayload },
  });
};

export const addLocalMapping = (payload: AccessGroupMappingRequest): Promise<void> => {
  return axiosInstance.post('/api/auth/access-groups/map', payload);
};

export const removeLocalMapping = (payload: AccessGroupMappingRequest): Promise<void> => {
  return axiosInstance.delete('/api/auth/access-groups/map', {
    data: payload,
  });
};

export const getGrantTypes = (): Promise<GrantTypeDTO[]> => {
  return axiosInstance.get('/api/auth/access-groups/grant-types');
};
