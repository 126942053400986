import React, { useCallback, useState } from 'react';

import { type SearchDTO } from 'entities/user';

import { useTransferModalStore } from '../../model/store';

import LeftGroup from '../LeftGroup/LeftGroup';
import MoveButton from '../MoveButton/MoveButton';
import RightGroup from '../RightGroup/RightGroup';

import styles from './Transfer.module.scss';

type TransferProps = {
  disableUserSelection: boolean;
};

const Transfer: React.FC<TransferProps> = ({ disableUserSelection }) => {
  const [selectedFromGroup, setSelectedFromGroup] = useState<SearchDTO['foundUsers']>([]);
  const [selectedFromAll, setSelectedFromAll] = useState<SearchDTO['foundUsers']>([]);

  const { groupName, usersMovedToGroup, usersMovedFromGroup, moveUsers } = useTransferModalStore();

  const handleTransferToGroup = (): void => {
    const newSelectedToMoveToGroup = selectedFromAll.filter(
      (user) => !usersMovedFromGroup.find((u) => u.id === user.id),
    );
    if (newSelectedToMoveToGroup.length) {
      moveUsers([...usersMovedToGroup, ...newSelectedToMoveToGroup], 'toGroup');
    }

    const selectedToMoveBack = selectedFromAll.filter((user) => usersMovedFromGroup.find((u) => u.id === user.id));
    if (selectedToMoveBack.length) {
      moveUsers(
        usersMovedFromGroup.filter((user) => !selectedToMoveBack.find((u) => u.id === user.id)),
        'fromGroup',
      );
    }

    setSelectedFromAll([]);
  };

  const handleTransferFromGroup = (): void => {
    const newSelectedToMoveFromGroup = selectedFromGroup.filter(
      (user) => !usersMovedToGroup.find((u) => u.id === user.id),
    );
    if (newSelectedToMoveFromGroup.length) {
      moveUsers([...usersMovedFromGroup, ...newSelectedToMoveFromGroup], 'fromGroup');
    }

    const selectedToMoveBack = selectedFromGroup.filter((user) => usersMovedToGroup.find((u) => u.id === user.id));
    if (selectedToMoveBack.length) {
      moveUsers(
        usersMovedToGroup.filter((user) => !selectedToMoveBack.find((u) => u.id === user.id)),
        'toGroup',
      );
    }

    setSelectedFromGroup([]);
  };

  const onChangeSelectedUsersInGroup = useCallback((users: SearchDTO['foundUsers']): void => {
    setSelectedFromGroup(users);
  }, []);

  const onChangeSelectedUsersFromAll = useCallback((users: SearchDTO['foundUsers']): void => {
    setSelectedFromAll(users);
  }, []);

  return (
    <article className={styles.modalContent}>
      <header className={styles.modalHeader}>
        <h2 className={styles.heading}>{groupName}</h2>
      </header>
      <div className={styles.container}>
        <LeftGroup
          disableUserSelection={disableUserSelection}
          selectedUsers={selectedFromGroup}
          onChangeSelectedUsers={onChangeSelectedUsersInGroup}
        />
        <div className={styles.moveButtons}>
          <MoveButton onClick={handleTransferFromGroup} isActive={selectedFromGroup.length > 0} direction="right" />
          <MoveButton onClick={handleTransferToGroup} isActive={selectedFromAll.length > 0} direction="left" />
        </div>
        <RightGroup
          disableUserSelection={disableUserSelection}
          selectedUsers={selectedFromAll}
          onChangeSelectedUsers={onChangeSelectedUsersFromAll}
        />
      </div>
    </article>
  );
};

export default Transfer;
