import { AvailableGlobalPermissions, AvailablePermissionsValue, RequiredPermissions } from 'features/validateAccess';

export const CREATE_ACCOUNT: RequiredPermissions = [
  [
    {
      type: 'global',
      global: AvailableGlobalPermissions.ACCOUNT,
      value: AvailablePermissionsValue.CREATE,
    },
  ],
];
