import { createElement } from 'react';
import { RouteObject, redirect } from 'react-router-dom';

import { projectCreatePageRoute } from 'pages/ProjectCreatePage';
import { projectUpdatePageRoute } from 'pages/ProjectUpdatePage';

import { isHasAccess, pathPermissions, replacePermissions } from 'features/validateAccess';

import { projectsService } from 'entities/project';

import { pathKeys } from 'shared/lib/react-router';

import {
  DEFAULT_IS_HIDDEN_ARCHIVED_PROJECTS,
  DEFAULT_IS_HIDDEN_EXPIRED_PROJECTS,
  PAGE_SIZE,
} from 'components/Project/Project.consts';

import ProjectsPage from './ProjectsPage';

export const projectsPageRoute: RouteObject = {
  path: '/accounts/:accountId/projects/',
  children: [
    {
      index: true,
      element: createElement(ProjectsPage),
      loader: async (args) => {
        const requiredPermissions = replacePermissions(pathPermissions.project.root, args.params);
        if (!isHasAccess(requiredPermissions)) {
          return redirect(pathKeys.dashboard());
        }

        if (!args.params.accountId) {
          return redirect(pathKeys.page404());
        }

        Promise.resolve(
          projectsService.prefetchQuery({
            accountId: args.params.accountId,
            page: 0,
            showArchived: DEFAULT_IS_HIDDEN_ARCHIVED_PROJECTS,
            hideNonActive: DEFAULT_IS_HIDDEN_EXPIRED_PROJECTS,
            size: PAGE_SIZE,
          }),
        );

        return args;
      },
    },
    projectCreatePageRoute,
    projectUpdatePageRoute,
  ],
};
