import { ENDLESS_NOTIFICATION_DURATION, LONG_NOTIFICATION_DURATION, SHORT_NOTIFICATION_DURATION } from 'shared/config';
import { NotificationType } from 'shared/model/notification';

export const getNotificationDuration = (type: NotificationType): number => {
  switch (type) {
    case 'long':
      return LONG_NOTIFICATION_DURATION;
    case 'critical':
      return ENDLESS_NOTIFICATION_DURATION;
    default:
      return SHORT_NOTIFICATION_DURATION;
  }
};
