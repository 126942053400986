import { ReactNode } from 'react';
import { NotificationInstance } from 'antd/es/notification/interface';
import { ArgsProps } from 'antd/lib/notification';
import { TOptions } from 'i18next';
import { TFuncKey } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { StateCreator, createStore } from 'zustand';
import { DevtoolsOptions, devtools } from 'zustand/middleware';

export type TranslatableText = [TFuncKey | TFuncKey[], TOptions] | string;

export type NotificationType = 'short' | 'long' | 'critical';

export interface TNotification extends Omit<ArgsProps, 'key' | 'description' | 'message' | 'type' | 'btn'> {
  description?: TranslatableText;
  message?: TranslatableText;
  type: NotificationType;
  method: keyof Omit<NotificationInstance, 'destroy'>;
  key: string;
  btn?: ((notificationInstance: NotificationInstance) => ReactNode) | ArgsProps['btn'];
}

export type NotificationPayload = Omit<TNotification, 'key'> & Partial<Pick<TNotification, 'key'>>;

type NotificationItem = Record<string, TNotification>;

type State = {
  notifications: Record<NotificationType, NotificationItem>;
};

type Actions = {
  addNotification: (notification: NotificationPayload) => void;
  closeNotifications: (type: NotificationType, key?: string) => void;
};

type NotificationState = State & Actions;

const createNotificationSlice: StateCreator<NotificationState, [['zustand/devtools', never]], [], NotificationState> = (
  set,
  get,
) => ({
  notifications: {
    short: {},
    long: {},
    critical: {},
  },
  addNotification: (notification) =>
    // ? Delay notifications to avoid closing them before they are shown
    setTimeout(() => {
      const key = notification.key || uuidv4();
      set(
        {
          notifications: {
            ...get().notifications,
            [notification.type]: {
              ...get().notifications[notification.type],
              [key]: { ...notification, key },
            },
          },
        },
        false,
        'addNotification',
      );
    }, 1),
  closeNotifications: (type, key) => {
    const oldNotifications = { ...get().notifications[type] };

    if (key) {
      delete oldNotifications[key];
    }

    set(
      {
        notifications: {
          ...get().notifications,
          [type]: key ? oldNotifications : {},
        },
      },
      false,
      'closeNotifications',
    );
  },
});

const devtoolsOptions: DevtoolsOptions = { name: 'Notifications' };

export const notificationStore = createStore<NotificationState>()(devtools(createNotificationSlice, devtoolsOptions));

export const { addNotification } = notificationStore.getState();
