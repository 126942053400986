import { AxiosRequestConfig } from 'axios';

import { axiosInstance } from 'shared/lib/axios';

import {
  AccountDTO,
  AccountList,
  AccountsListRequest,
  AccountCreateRequest,
  AccountUpdateRequest,
  AccountsNameDTO,
} from './types';

export const accountListGet = (payload: AccountsListRequest, config?: AxiosRequestConfig): Promise<AccountList> => {
  const { showArchived, size, page, hideNonActive } = payload;
  return axiosInstance.get(`/api/account/all`, {
    ...config,
    params: { show_archived: showArchived, size, page, sort: 'name', hide_non_active: hideNonActive },
  });
};

export const accountItemGet = (accountId: string, config?: AxiosRequestConfig): Promise<AccountDTO> => {
  return axiosInstance.get(`/api/account/${accountId}`, config);
};

export const accountItemCreate = (payload: AccountCreateRequest): Promise<AccountDTO> => {
  return axiosInstance.post('/api/account', payload);
};

export const accountItemUpdate = (payload: AccountUpdateRequest): Promise<AccountDTO> => {
  return axiosInstance.put('/api/account', payload);
};

export const accountItemArchive = (id: string): Promise<void> => {
  return axiosInstance.put(`/api/archivation/archive/account/${id}`);
};

export const accountItemRestore = (id: string): Promise<void> => {
  return axiosInstance.put(`/api/archivation/unarchive/account/${id}`);
};

export const accountNamesGetByIds = (ids: string[], config?: AxiosRequestConfig): Promise<AccountsNameDTO[]> => {
  return axiosInstance.post('/api/account/names', { ids }, config);
};
