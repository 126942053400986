/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, ButtonProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

interface ILinkButtonProps {
  linkProps: LinkProps;
  buttonProps?: Omit<ButtonProps, 'children'>;
  children: React.ReactNode;
}

const LinkButton: React.FC<ILinkButtonProps> = ({ linkProps, buttonProps, children }) => {
  const { t, i18n } = useTranslation();

  const isTranslationCode = typeof children === 'string' && i18n.exists(children);
  return (
    <Link {...linkProps}>
      <Button {...buttonProps}>{isTranslationCode ? t(children) : children}</Button>
    </Link>
  );
};

export default LinkButton;
