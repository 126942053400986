import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './DiscardChangesOverlay.module.scss';

export const DiscardChangesOverlay: React.FC<{ onConfirm: () => void; onCancel: () => void; visible: boolean }> = ({
  onCancel,
  onConfirm,
  visible,
}) => {
  const { t } = useTranslation('ReusableComponents');

  return (
    <div className={classNames(styles.overlay, { [styles.visible]: visible })}>
      <div className={styles.content}>
        <div>{t('DiscardChangesOverlay.text')}</div>
        <div className={styles.buttons}>
          <Button type="primary" onClick={onConfirm}>
            {t('DiscardChangesOverlay.confirmButton.label')}
          </Button>
          <Button onClick={onCancel}>{t('DiscardChangesOverlay.cancelButton.label')}</Button>
        </div>
      </div>
      <div className={styles.background} />
    </div>
  );
};
